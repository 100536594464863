import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';

i18n
    // .use(Backend) using inline, required translation instead of async backend
    .use(reactI18nextModule)
    .init({
        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',

        debug: false,
        returnObjects: true,
        joinArrays: '\n',

        interpolation: {
            escapeValue: false, // not needed for react!!
        },

        react: {
            wait: true,
        },
    });

i18n.services.pluralResolver.addRule('pl', {
    numbers: [1, 2, 5, 0.5],
    plurals: n => {
        if (n % 1 !== 0) {
            return 3;
        }
        if (n === 1) {
            return 0;
        }
        if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
            return 1;
        }
        return 2;
    },
});

export default i18n;
