import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '../../../../../Apollo/Mutation';
import registerViaFacebookMutation from './RegisterViaFacebookMutation';

class RegisterViaFacebookConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    callRegisterViaFacebook = apiRegisterViaFacebook => async token => {
        const response = await apiRegisterViaFacebook({
            variables: {
                token,
            },
        });

        return response;
    };

    render() {
        const { children } = this.props;
        return (
            <Mutation mutation={registerViaFacebookMutation}>
                {(registerViaFacebookMutationResponse, result) => {
                    const registerViaFacebookResult = {
                        registerViaFacebook: this.callRegisterViaFacebook(
                            registerViaFacebookMutationResponse
                        ),
                        loading: result.loading,
                    };

                    return children(registerViaFacebookResult);
                }}
            </Mutation>
        );
    }
}

export default RegisterViaFacebookConnectorApollo;
