import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'reactstrap';
import Form from 'Layout/Form/Form';
import { getUnitForType } from 'utils/unit';
import InputNumber from 'components/FormElements/InputNumberControlled';
import InputDate from 'components/FormElements/InputDateControlled';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import { ApiDateType, SystemOfMeasuresType } from 'types/CommonTypes';
import { UNIT_TYPES } from 'consts';
import { callNativeAppRemoveLastRoute } from 'components/Helpers/callNativeAppRemoveLastRoute';
import { withLocale } from '../../../TranslatorContext';

class MeasurementByDateForm extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        date: ApiDateType,
        weight: PropTypes.number,
        waist: PropTypes.number,
        thigh: PropTypes.number,
        rightthigh: PropTypes.number,
        chest: PropTypes.number,
        arm: PropTypes.number,
        rightarm: PropTypes.number,
        systemOfMeasures: SystemOfMeasuresType,
        onSave: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        onDateChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        date: undefined,
        weight: undefined,
        waist: undefined,
        thigh: undefined,
        rightthigh: undefined,
        chest: undefined,
        arm: undefined,
        rightarm: undefined,
        systemOfMeasures: undefined,
    };

    validationRules = {
        date: [new RequiredRule({ translator: this.props.t })],
        weight: [new RequiredRule({ translator: this.props.t })],
    };

    handleDelete = event => {
        event.preventDefault();
        this.props.onDelete(this.props.date);
    };

    render() {
        const {
            t,
            systemOfMeasures,
            date,
            weight,
            waist,
            thigh,
            rightthigh,
            chest,
            arm,
            rightarm,
            onSave,
            onDateChange,
        } = this.props;

        return (
            <Container className="pt-4">
                <Form
                    validationRules={this.validationRules}
                    initialValues={{
                        date,
                        weight,
                        waist,
                        thigh,
                        rightthigh,
                        chest,
                        arm,
                        rightarm,
                    }}
                    onSubmit={onSave}
                >
                    {({
                        values,
                        validationErrors,
                        validationRules,
                        onChange,
                    }) => (
                        <React.Fragment>
                            <InputDate
                                label={t('measurments/date')}
                                name="date"
                                handleChange={event =>
                                    onDateChange(event.target.value)
                                }
                                value={values.date}
                                errors={validationErrors}
                                validationRules={validationRules.date}
                            />
                            <hr />
                            <InputNumber
                                label={t('measurments/param/body-weight')}
                                name="weight"
                                handleChange={onChange}
                                value={Number(values.weight)}
                                unit={getUnitForType(
                                    UNIT_TYPES.BODY_WEIGHT,
                                    systemOfMeasures
                                )}
                                errors={validationErrors}
                                validationRules={validationRules.weight}
                            />
                            <InputNumber
                                label={t('measurments/param/chest')}
                                name="chest"
                                handleChange={onChange}
                                value={Number(values.chest)}
                                unit={getUnitForType(
                                    UNIT_TYPES.LENGTH_BODY_MEASUREMENT,
                                    systemOfMeasures
                                )}
                                errors={validationErrors}
                                validationRules={validationRules.chest}
                            />

                            <Row>
                                <Col className="pr-2">
                                    <InputNumber
                                        label={t('measurments/param/arm')}
                                        name="arm"
                                        handleChange={onChange}
                                        value={Number(values.arm)}
                                        unit={getUnitForType(
                                            UNIT_TYPES.LENGTH_BODY_MEASUREMENT,
                                            systemOfMeasures
                                        )}
                                        errors={validationErrors}
                                        validationRules={validationRules.arm}
                                    />
                                </Col>
                                <Col className="pl-2">
                                    <InputNumber
                                        label={t('measurments/param/rightarm')}
                                        name="rightarm"
                                        handleChange={onChange}
                                        value={Number(values.rightarm)}
                                        unit={getUnitForType(
                                            UNIT_TYPES.LENGTH_BODY_MEASUREMENT,
                                            systemOfMeasures
                                        )}
                                        errors={validationErrors}
                                        validationRules={
                                            validationRules.rightarm
                                        }
                                    />
                                </Col>
                            </Row>

                            <InputNumber
                                label={t('measurments/param/waist')}
                                name="waist"
                                handleChange={onChange}
                                value={Number(values.waist)}
                                unit={getUnitForType(
                                    UNIT_TYPES.LENGTH_BODY_MEASUREMENT,
                                    systemOfMeasures
                                )}
                                errors={validationErrors}
                                validationRules={validationRules.waist}
                            />

                            <Row>
                                <Col className="pr-2">
                                    <InputNumber
                                        label={t('measurments/param/thigh')}
                                        name="thigh"
                                        handleChange={onChange}
                                        value={Number(values.thigh)}
                                        unit={getUnitForType(
                                            UNIT_TYPES.LENGTH_BODY_MEASUREMENT,
                                            systemOfMeasures
                                        )}
                                        errors={validationErrors}
                                        validationRules={validationRules.thigh}
                                    />
                                </Col>
                                <Col className="pl-2">
                                    <InputNumber
                                        label={t(
                                            'measurments/param/rightthigh'
                                        )}
                                        name="rightthigh"
                                        handleChange={onChange}
                                        value={Number(values.rightthigh)}
                                        unit={getUnitForType(
                                            UNIT_TYPES.LENGTH_BODY_MEASUREMENT,
                                            systemOfMeasures
                                        )}
                                        errors={validationErrors}
                                        validationRules={
                                            validationRules.rightthigh
                                        }
                                    />
                                </Col>
                            </Row>

                            <div className="text-center pb-4">
                                <Button
                                    color="link"
                                    onClick={this.handleDelete}
                                >
                                    {t('measurments/delete-measurment-button')}
                                </Button>
                            </div>

                            <footer>
                                <Row>
                                    <Col xs="6" className="pr-2">
                                        <Button
                                            color="primary"
                                            outline
                                            className="outline w-100"
                                            onClick={() => {
                                                window.history.back();
                                                callNativeAppRemoveLastRoute(
                                                    'measure'
                                                );
                                            }}
                                        >
                                            {t('cancel')}
                                        </Button>
                                    </Col>
                                    <Col xs="6" className="pl-2">
                                        <Button
                                            color="primary"
                                            className="w-100"
                                            type="submit"
                                        >
                                            {t('save')}
                                        </Button>
                                    </Col>
                                </Row>
                            </footer>
                        </React.Fragment>
                    )}
                </Form>
            </Container>
        );
    }
}

export default withLocale(MeasurementByDateForm);
