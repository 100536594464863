function isQueryCached(client, query, variables) {
    const { complete } = client.cache.diff({
        query: client.cache.transformDocument(query),
        variables,
        returnPartialData: true,
        optimistic: false,
    });
    return complete;
}

export { isQueryCached };
