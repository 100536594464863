import React from 'react';
import PropTypes from 'prop-types';
import withLoading from 'Layout/withLoading';
import Placeholder from 'Layout/GenericPlaceholder';
import { Container, Button, Form } from 'reactstrap';
import InputText from 'components/FormElements/InputTextControlled';
import InputNumber from 'components/FormElements/InputNumberControlled';
import InputDate from 'components/FormElements/InputDateControlled';
import Select from 'components/FormElements/InputSelectControlled';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import DateFormatRule from 'view/Validation/ValidationRules/DateFormatRule';
import ValidationErrors from 'view/Validation/ValidationErrors';
import { validateFields } from 'view/Validation/validateFields';
import { validateField } from 'view/Validation/validateField';
import { Redirect } from 'react-router';
import Layout from 'Layout/Layout';
import { withLocale } from '../../TranslatorContext';
// eslint-disable-next-line import/no-cycle
import { PATH_SETTINGS } from './routes';

class UserSettings extends React.PureComponent {
    static propTypes = {
        t: PropTypes.func.isRequired,
        systemOfMeasures: PropTypes.string.isRequired,
        sex: PropTypes.string.isRequired,
        userName: PropTypes.string.isRequired,
        birthDate: PropTypes.string.isRequired,
        height: PropTypes.shape({
            value: PropTypes.number,
            unit: PropTypes.string,
        }).isRequired,
        saveUserData: PropTypes.func.isRequired,
        userId: PropTypes.number.isRequired,
    };

    lenghtDefaultUnit =
        this.props.systemOfMeasures === 'imperial' ? 'in' : 'cm';

    validationRules = {
        sex: [new RequiredRule({ translator: this.props.t })],
        height: [new RequiredRule({ translator: this.props.t })],
        birthDate: [
            new RequiredRule({ translator: this.props.t }),
            new DateFormatRule({ translator: this.props.t }),
        ],
    };

    state = {
        sex: this.props.sex,
        userName: this.props.userName,
        height: {
            value: this.props.height ? this.props.height.value : '',
            unit: this.props.height
                ? this.props.height.unit
                : this.lenghtDefaultUnit,
        },
        birthDate: this.props.birthDate ? this.props.birthDate : undefined,
        errors: new ValidationErrors(),
        // preload: false,
        redirect: false,
    };

    handleInputChange = event => {
        const fieldName = event.target.name.split('.')[0];
        const valInt = parseInt(event.target.value, 10);
        // const valFloat = Math.round(parseFloat(event.target.value) * 10) / 10;

        if (fieldName === 'height') {
            this.setState(prevState => ({
                [fieldName]: {
                    ...prevState[fieldName],
                    value: valInt,
                },
            }));
        } else {
            this.setState({ [fieldName]: event.target.value });
        }

        if (this.validationRules[event.target.name]) {
            const field = event.target.name;
            const value = event.target.value;

            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    details: validateField(
                        field,
                        value,
                        this.validationRules[field],
                        prevState
                    ),
                },
            }));
        }
    };

    handleSave = async event => {
        event.preventDefault();
        const frontEndErrors = validateFields(
            this.validationRules,
            this.state,
            this.props.t
        );

        if (frontEndErrors.length === 0) {
            const request = {
                name: this.state.userName,
                birthDate: this.state.birthDate,
                isFemale: this.state.sex === 'female',
                height: this.state.height,
            };

            try {
                const response = await this.props.saveUserData(
                    request,
                    this.props.userId
                );
                const { code } = response.data.me.saveUserData;
                if (code === 200) {
                    this.setState({
                        redirect: true,
                        errors: new ValidationErrors(),
                    });
                } else {
                    this.setState({ errors: response.data.me.saveUserData });
                }
            } catch (e) {
                throw new Error(`Failed to save user data, got error: ${e}`);
            }

            return null;
        }

        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                details: frontEndErrors,
            },
        }));

        return null;
    };

    render() {
        const optionalHeightProps = {};
        if (this.state.height) {
            optionalHeightProps.value = Number(this.state.height.value);
        }

        if (this.state.redirect) {
            return <Redirect to={PATH_SETTINGS} />;
        }

        return (
            <Layout page="settings">
                <header>
                    <h1 className="text-center d-none d-md-block">
                        {this.props.t('settings/title')}
                    </h1>
                </header>
                <section className="pt-0">
                    <Container>
                        <Form
                            data-test="bmr-form"
                            onSubmit={event => this.handleSave(event)}
                            noValidate
                        >
                            <InputText
                                label={this.props.t('diet-settings/name')}
                                name="userName"
                                errors={this.state.errors}
                                handleChange={this.handleInputChange}
                                validationRules={this.validationRules.userName}
                                value={this.state.userName}
                            />

                            <Select
                                label={this.props.t('diet-settings/sex/label')}
                                name="sex"
                                handleChange={this.handleInputChange}
                                value={this.state.sex}
                                validationRules={this.validationRules.sex}
                            >
                                <option value="female">
                                    {this.props.t('diet-settings/sex/female')}
                                </option>
                                <option value="male">
                                    {this.props.t('diet-settings/sex/male')}
                                </option>
                            </Select>

                            <InputDate
                                label={this.props.t('diet-settings/birth-date')}
                                name="birthDate"
                                value={this.state.birthDate}
                                errors={this.state.errors}
                                handleChange={this.handleInputChange}
                                validationRules={this.validationRules.birthDate}
                            />

                            <InputNumber
                                label={this.props.t('diet-settings/height')}
                                name="height"
                                unit={this.state.height.unit}
                                errors={this.state.errors}
                                handleChange={this.handleInputChange}
                                validationRules={this.validationRules.height}
                                {...optionalHeightProps}
                            />

                            <div className="push" />

                            <footer>
                                <Button
                                    color="primary"
                                    className="w-100"
                                    type="submit"
                                >
                                    {this.props.t('save')}
                                </Button>
                            </footer>
                        </Form>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default withLoading(withLocale(UserSettings), Placeholder);
