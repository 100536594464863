import gql from 'graphql-tag';

const query = gql`
    query MeasurementHistoryQuery(
        $datePeriod: DatePeriodInputType!
        $weight: Boolean = false
        $waist: Boolean = false
        $thigh: Boolean = false
        $rightthigh: Boolean = false
        $chest: Boolean = false
        $arm: Boolean = false
        $rightarm: Boolean = false
    ) {
        me {
            id
            goalWeight {
                value
                unit
            }
            history: measurement {
                weight(request: { datePeriod: $datePeriod })
                    @include(if: $weight) {
                    ...MeasurementHistoryFragment
                }
                waist(request: { datePeriod: $datePeriod })
                    @include(if: $waist) {
                    ...MeasurementHistoryFragment
                }
                thigh(request: { datePeriod: $datePeriod })
                    @include(if: $thigh) {
                    ...MeasurementHistoryFragment
                }
                rightthigh(request: { datePeriod: $datePeriod })
                    @include(if: $rightthigh) {
                    ...MeasurementHistoryFragment
                }
                chest(request: { datePeriod: $datePeriod })
                    @include(if: $chest) {
                    ...MeasurementHistoryFragment
                }
                arm(request: { datePeriod: $datePeriod }) @include(if: $arm) {
                    ...MeasurementHistoryFragment
                }
                rightarm(request: { datePeriod: $datePeriod })
                    @include(if: $rightarm) {
                    ...MeasurementHistoryFragment
                }
            }
        }
    }

    fragment MeasurementHistoryFragment on UserMeasurementType {
        value
        unit
        date
        change
    }
`;

export default query;
