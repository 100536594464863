import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import Layout from 'Layout/Layout';
import { withLocale } from '../../TranslatorContext';
import ActivateForm from './ActivateForm';

export class CouponActivateContainer extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        activateCoupon: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Layout page="coupon">
                <Container>
                    <header>
                        <h1 className="text-center d-none d-md-block">
                            {this.props.t('coupon/activate/title')}
                        </h1>
                    </header>
                    <section className="pt-0">
                        <ActivateForm
                            activateCoupon={this.props.activateCoupon}
                        />
                    </section>
                </Container>
            </Layout>
        );
    }
}

export default withLocale(CouponActivateContainer);
