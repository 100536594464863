/* eslint-disable import/no-cycle */
import React from 'react';
import { scrollToTop } from 'components/Helpers/scrollToTop';
import SettingsConnectorApollo from './Connector/Apollo/CurrentSettings/CurrentSettingsConnectorApollo';
import SaveUserDataConnectorApollo from './Connector/Apollo/SaveUserData/SaveUserDataConnectorApollo';
import UserDataEditContainer from './UserDataEditContainer';

class UserDataEditPage extends React.PureComponent {
    componentDidMount() {
        scrollToTop();
    }

    render() {
        return (
            <SettingsConnectorApollo>
                {result => {
                    return (
                        <SaveUserDataConnectorApollo>
                            {({ saveUserData }) => (
                                <UserDataEditContainer
                                    systemOfMeasures={result.systemOfMeasures}
                                    sex={result.sex}
                                    userName={result.name}
                                    birthDate={result.birthDate}
                                    height={result.height}
                                    loading={result.loading}
                                    userId={result.userId}
                                    {...{ saveUserData }}
                                />
                            )}
                        </SaveUserDataConnectorApollo>
                    );
                }}
            </SettingsConnectorApollo>
        );
    }
}

export default UserDataEditPage;
