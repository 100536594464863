import firebaseConfig from 'firebaseConfig';
import firebase from 'firebase/app';
import 'firebase/analytics';

let firebaseInitialized = false;

function getFirebaseClient() {
    if (!firebaseInitialized) {
        firebase.initializeApp(firebaseConfig);
        firebaseInitialized = true;
    }

    return firebase;
}

global.window.Firebase = getFirebaseClient();

export default getFirebaseClient();
