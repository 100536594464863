import { connect } from 'react-redux';
import PromoCodeHandlerComponent from './PromoCodeHandlerComponent';
import { promoCodeSetAction } from './Set/PromoCodeSetAction';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    setPromoCode: newValue => dispatch(promoCodeSetAction(newValue)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PromoCodeHandlerComponent);
