import { PROMO_CODE_SET, PROMO_CODE_REMOVE_STALE } from './PromoCodeActionType';
import getInitialState from './PromoCodeInitialState';

const PromoCodeReducer = (state, action) => {
    const result = state ? { ...state } : getInitialState();

    if (action.type === PROMO_CODE_SET) {
        result.value = action.value;
    } else if (action.type === PROMO_CODE_REMOVE_STALE) {
        delete result.value;
    }

    return result;
};

export default PromoCodeReducer;
