import { createDateObject } from 'utils/dateFormatter';

export default class DateFormatRule {
    constructor(options = {}) {
        const t = options.translator;
        if (!t) {
            throw new Error('translator option is required in DateFormatRule');
        }
        this.messages = {
            dateFormat: t('validation/date-format'),
            ...options.messages,
        };
    }

    isValid = value => {
        try {
            createDateObject(value);
            return true;
        } catch (error) {
            return false;
        }
    };

    getErrorMsg() {
        return this.messages.dateFormat;
    }
}
