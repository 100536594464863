import { FIRST, LAST } from './SaleSourcesNames';

const nullifyStaleSources = sources => {
    const now = Date.now();
    const result = { ...sources };
    [FIRST, LAST].forEach(sourceName => {
        const source = result[sourceName] || null;
        if (source == null || source.expire == null || source.expire < now) {
            result[sourceName] = null;
        }
    });
    return result;
};

export { nullifyStaleSources };
