import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { formatIsoDate } from 'utils/dateFormatter';
import { subDays } from 'utils/date';
import { withRouter } from 'react-router';
import ValidationErrors from 'view/Validation/ValidationErrors';
import {
    MEASUREMENT_TYPES,
    PERIOD_END,
    PERIOD_LENGTH,
} from '../../../constants';
import MeasurementGroupDeleteMutation from './MeasurementGroupDeleteMutation';
import CurrentMeasurementsQuery from '../CurrentMeasurements/CurrentMeasurementsQuery';
import MeasurementHistoryQuery from '../MeasurementHistory/MeasurementHistoryQuery';
import MeasurementFetchByDateQuery from './MeasurementFetchByDateQuery';

const MeasurementDeleteByDateConnectorApollo = ({ children, match }) => {
    const [deleteMeasurements, { loading }] = useMutation(
        MeasurementGroupDeleteMutation,
        {
            refetchQueries: [
                {
                    query: CurrentMeasurementsQuery,
                },
                {
                    query: MeasurementHistoryQuery,
                    variables: {
                        datePeriod: {
                            start: formatIsoDate(
                                subDays(PERIOD_END, PERIOD_LENGTH - 1)
                            ),
                            end: formatIsoDate(PERIOD_END),
                        },
                        [MEASUREMENT_TYPES.WEIGHT]: true,
                    },
                },
                {
                    query: MeasurementFetchByDateQuery,
                    variables: {
                        date: match.params.date
                            ? match.params.date
                            : formatIsoDate(new Date()),
                    },
                },
            ],
            awaitRefetchQueries: true,
        }
    );

    const createDeleteMeasurements = async date => {
        const response = await deleteMeasurements({
            variables: {
                request: {
                    date,
                },
            },
        });

        if (
            response.data.me.measurementGroupDelete.__typename ===
            'ValidationException'
        ) {
            throw new ValidationErrors(
                response.data.me.measurementGroupDelete.messages,
                response.data.me.measurementGroupDelete.details
            );
        }
    };

    return children({
        deleteMeasurements: createDeleteMeasurements,
        deleting: loading,
    });
};

MeasurementDeleteByDateConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    date: PropTypes.instanceOf(Date),
};

MeasurementDeleteByDateConnectorApollo.defaultProps = {
    date: undefined,
};

export default withRouter(MeasurementDeleteByDateConnectorApollo);
