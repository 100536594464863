import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import ActiveSubscriptionQuery from './ActiveSubscriptionQuery';

const ActiveSubscriptionConnectorApollo = ({ children }) => {
    const { loading, data } = useQuery(ActiveSubscriptionQuery);

    const result = {
        activeSubscriptions: [],
        email: '',
        loading,
    };

    if (!loading) {
        result.activeSubscriptions = data.me.activeSubscriptions;
        result.email = data.me.email;
    }

    return children(result);
};

ActiveSubscriptionConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default ActiveSubscriptionConnectorApollo;
