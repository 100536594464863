import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

const ScrollContext = React.createContext(() => {
    throw new Error('Used ScrollContext.Consumer without a Provider');
});
const { Provider, Consumer } = ScrollContext;

class ScrollToTop extends Component {
    static propTypes = {
        location: PropTypes.shape({
            hash: PropTypes.string,
            key: PropTypes.string,
            pathname: PropTypes.string,
            search: PropTypes.string,
        }),
        children: PropTypes.node.isRequired,
    };

    static defaultProps = {
        location: undefined,
    };

    state = {
        shouldScroll: true,
    };

    handleShouldScroll = shouldScroll => {
        this.setState({
            shouldScroll,
        });
    };

    componentDidUpdate(prevProps) {
        if (
            this.props.location !== prevProps.location &&
            this.state.shouldScroll
        ) {
            try {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            } catch (e) {
                window.scrollTo(0, 0);
            }
        } else if (
            this.props.location !== prevProps.location &&
            !this.state.shouldScroll
        ) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ shouldScroll: true });
        }
    }

    render() {
        return (
            <Provider
                value={{
                    handleShouldScroll: this.handleShouldScroll,
                }}
            >
                {this.props.children}
            </Provider>
        );
    }
}

const withShouldScroll = ChildComponent => props => {
    return (
        <Consumer>
            {({ handleShouldScroll }) => (
                <ChildComponent
                    {...props}
                    handleShouldScroll={handleShouldScroll}
                />
            )}
        </Consumer>
    );
};

export { withShouldScroll };
export default withRouter(ScrollToTop);
