import pako from 'pako';

class LocalStorageWithCompression {
    storage = null;

    onError = null;

    constructor({ storage, onError }) {
        this.storage = storage;
        this.onError = onError;
    }

    async getItem(key) {
        const storageValue = await this.storage.getItem(key);
        if (!storageValue) {
            return null;
        }

        try {
            return pako.inflate(storageValue, {
                to: 'string',
            });
        } catch (error) {
            return null;
        }
    }

    async setItem(key, data) {
        try {
            const compressed = pako.deflate(data, { to: 'string' });

            return await this.storage.setItem(key, compressed);
        } catch (error) {
            this.removeItem(key);
            this.onError(error);
        }
        return null;
    }

    async removeItem(key) {
        try {
            return await this.storage.removeItem(key);
        } catch (error) {
            this.onError(error);
            throw error;
        }
    }
}

export default LocalStorageWithCompression;
