import React from 'react';
import { string, shape } from 'prop-types';
import { withRouter } from 'react-router';
import { withLocale } from '@dietlabs/components';
import { reportError } from '@dietlabs/utils';
import { PATHS } from 'config/paths';
import Error404Component from 'components/Error/Error404';

class Error404 extends React.Component {
    static propTypes = {
        location: shape({
            pathname: string.isRequired,
            search: string.isRequired,
        }).isRequired,
    };

    componentDidMount() {
        const { pathname, search } = this.props.location;
        if (pathname !== PATHS.ERROR_404) {
            const error = new Error(`Page not found: ${pathname}${search}`);
            error.noDefaultFingerprint = true;
            error.fingerprint = ['404', pathname];
            reportError(error);
        }
    }

    render() {
        return <Error404Component topLevel t={this.props.t} />;
    }
}

export { Error404 };
export default withLocale(withRouter(Error404));
