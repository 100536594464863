import React from 'react';
import { withGTM } from 'react-tag-manager';
import googleTagManagerConfig from 'config/google-tag-manager';
import TRACKING_NAMESPACE from 'view/Tracking/TrackingNamespace';
import * as console from 'utils/console';
import store from '../../store';
import * as eventCategories from './EventCategories';
import Firebase from '../FirebaseAnalytics/Firebase';

const dummyGTM = {
    api: {
        trigger: (...argv) => {
            console.info('Would trigger GTM event:', ...argv);
        },
        setDataLayer: (...argv) => {
            console.info('Would set GTM Data Layer:', ...argv);
        },
        gtm: {
            push: (...argv) => {
                console.log('Would push to GTM:', ...argv);
            },
        },
    },
};

const validateCategory = category => {
    if (!Object.values(eventCategories).includes(category)) {
        console.warn('Unknown event category:', category);
    }
};

/**
 * @param {string} category One see view/GoogleTagManage/EventCategories
 *        for available values. E.g. "shoping cart"
 * @param {string} action E.g. 'added from cross-sell'
 * @param {string} [label] E.g. product name
 * @paeam {Number} [value] E.g. amount added
 *
 * @see https://support.google.com/analytics/answer/1033068?hl=pl#Anatomy
 */
const trackEvent = function trackEvent(
    GTM,
    category,
    action,
    label = undefined,
    value = undefined
) {
    validateCategory(category);

    const event = {
        event: 'customEvent',
        customEvent: {
            category,
            action,
        },
    };

    if (label !== undefined) {
        event.customEvent.label = label;

        if (value !== undefined) {
            event.customEvent.value = value;
        }
    }

    Firebase.analytics().logEvent(category, { action, label, value });

    GTM.api.trigger(event);
};

const filterNonGtmProps = props => {
    // eslint-disable-next-line no-shadow
    const { GTM, trackEvent, filterNonGtmProps, ...otherProps } = props;
    return otherProps;
};

export default ChildComponent => {
    const track = store.getState()[TRACKING_NAMESPACE].track;

    let WithGTM;
    if (!track || !googleTagManagerConfig || !googleTagManagerConfig.id) {
        WithGTM = props => (
            <ChildComponent
                {...props}
                GTM={dummyGTM}
                trackEvent={(...argv) => trackEvent(dummyGTM, ...argv)}
                filterNonGtmProps={filterNonGtmProps}
            />
        );
    } else {
        WithGTM = withGTM(({ GTM, ...props }) => (
            <ChildComponent
                {...props}
                GTM={GTM}
                trackEvent={(...argv) => trackEvent(GTM, ...argv)}
                filterNonGtmProps={filterNonGtmProps}
            />
        ));
    }

    const childName = ChildComponent.displayName || ChildComponent.name;
    WithGTM.displayName = `withGTM(${childName})`;
    return WithGTM;
};
