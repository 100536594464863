const isStale = item => {
    const now = Date.now();

    if (item == null || item.expire == null || item.expire < now) {
        return true;
    }

    return false;
};

export { isStale };
