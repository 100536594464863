import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Layout from 'Layout/Layout';
import Header from 'Layout/Header';
import PropTypes from 'prop-types';
import ProgressPlaceholder from './Progress/HydrationDayProgressPreloader';
import GoalPlaceholder from './Goal/HydrationDayGoalPlaceholder';
import GoalHeaderPlaceholder from './Goal/HydrationDayGoalHeaderPlaceholder';
import AddPlaceholder from './Add/HydrationAddPlaceholder';
import { withLocale } from '../../../TranslatorContext';

class HydrationDayContainerPlaceholder extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Layout page="hydration">
                <Header>{this.props.t('route-title/hydration-day')}</Header>
                <Container>
                    <Row>
                        <Col
                            xs={12}
                            md={{ size: 8, offset: 2 }}
                            lg={{ size: 4, offset: 4 }}
                        >
                            <Row className="align-items-center">
                                <Col xs="5">
                                    <ProgressPlaceholder />
                                </Col>
                                <Col xs="7">
                                    <GoalHeaderPlaceholder />
                                    <AddPlaceholder />
                                </Col>
                            </Row>

                            <GoalPlaceholder />
                        </Col>
                    </Row>
                </Container>
            </Layout>
        );
    }
}

export default withLocale(HydrationDayContainerPlaceholder);
