import { useLazyQuery } from '@apollo/client';
import ArticleListFetchQuery from '../FetchList/ArticleListFetchQuery';

const ArticleFetchListOnDemandConnectorApollo = ({ children }) => {
    const [loadArticleList] = useLazyQuery(ArticleListFetchQuery);

    return children(loadArticleList);
};

export default ArticleFetchListOnDemandConnectorApollo;
