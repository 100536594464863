export default class ValidationErrors {
    constructor(messages, details) {
        this.messages = messages || [];
        this.details = details || [];
    }

    static createFromObject(object) {
        const messages = object && object.messages ? object.messages : [];
        const details = object && object.details ? object.details : [];

        return new ValidationErrors(messages, details);
    }

    get occured() {
        return Boolean(
            this.messages.length || Object.keys(this.details).length
        );
    }
}
