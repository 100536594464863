import { FIRST, LAST } from './SaleSourcesNames';
import { getLocalStorageItem } from './SaleSourcesLocalStorage';

const getEmptyState = () => ({
    [FIRST]: null,
    [LAST]: null,
});

const getStateFromLocalStorage = () => {
    const state = {};
    const firstSource = getLocalStorageItem(FIRST);
    const lastSource = getLocalStorageItem(LAST);
    if (firstSource != null) {
        state[FIRST] = firstSource;
    }
    if (lastSource != null) {
        state[LAST] = lastSource;
    }
    return state;
};

const getInitialState = () => ({
    ...getEmptyState(),
    ...getStateFromLocalStorage(),
});

export default getInitialState;
