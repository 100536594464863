import React from 'react';
import { Button } from 'reactstrap';

class ExternalProviderPlaceholder extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Button color="placeholder">&nbsp;</Button>
                <Button color="placeholder" className="mt-3">
                    &nbsp;
                </Button>
                <Button color="placeholder" className="mt-3">
                    &nbsp;
                </Button>
            </React.Fragment>
        );
    }
}

export default ExternalProviderPlaceholder;
