import gql from 'graphql-tag';

const mutation = gql`
    mutation UserAgreementToNotifyAboutArticles($agreement: Boolean!) {
        me {
            agreementToNotifyAboutArticles(agreement: $agreement) {
                code
            }
        }
    }
`;

export default mutation;
