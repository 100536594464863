import React from 'react';

import config from 'config/config';

function getEnvFromUrl(url) {
    const match = url.match(/(?:^|:\/\/|\.)(dev|stage)\./);
    return match ? match[1] : 'prod';
}

function createEnvLabel(type, env) {
    return (
        <span className={`envIndicator__label envIndicator__label--${env}`}>
            <span className="envIndicator__type">{type}:</span>
            {env}
        </span>
    );
}

export default function EnvIndicator() {
    const serverEnv =
        process.env.REACT_APP_ENV || process.env.NODE_ENV || 'prod';
    const apiEnv = getEnvFromUrl(config.apiGateway.url);

    if (serverEnv === 'prod') {
        return null;
    }

    return (
        <div className="envIndicator">
            {createEnvLabel('server', serverEnv)}
            {serverEnv === apiEnv ? null : createEnvLabel('api', apiEnv)}
        </div>
    );
}
