import {
    SALE_SOURCES_SET,
    SALE_SOURCES_REMOVE_STALE,
} from './SaleSourcesActionType';
import { FIRST, LAST } from './SaleSourcesNames';
import getInitialState from './SaleSourcesInitialState';

const SaleSourcesReducer = (state, action) => {
    const result = state ? { ...state } : getInitialState();

    if (action.type === SALE_SOURCES_SET) {
        result[FIRST] = action[FIRST];
        result[LAST] = action[LAST];
    } else if (action.type === SALE_SOURCES_REMOVE_STALE) {
        if (action[FIRST]) {
            result[FIRST] = null;
        }
        if (action[LAST]) {
            result[LAST] = null;
        }
    }

    return result;
};

export default SaleSourcesReducer;
