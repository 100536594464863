import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as BootstrapForm } from 'reactstrap';
import ValidationErrors from 'view/Validation/ValidationErrors';
import { validateFields } from 'view/Validation/validateFields';
import { scrollToFormFirstError } from 'components/Helpers/scrollToFormFirstError';

export class Form extends Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        validationRules: PropTypes.objectOf(PropTypes.array),
        initialValues: PropTypes.object, //eslint-disable-line
    };

    static defaultProps = {
        validationRules: undefined,
    };

    constructor(props) {
        super(props);
        this.form = React.createRef();
    }

    state = {
        values: this.props.initialValues,
        validationErrors: undefined,
    };

    handleChange = event => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            values: {
                ...prevState.values,
                [name]: value,
            },
        }));
    };

    handleSubmit = async event => {
        const { values } = this.state;
        const { validationRules, onSubmit } = this.props;

        event.preventDefault();
        this.setState({ validationErrors: undefined });

        try {
            const frontErrors = validateFields(validationRules, values);
            if (frontErrors.length) {
                throw new ValidationErrors([], frontErrors);
            }
            await onSubmit(values);
        } catch (error) {
            if (error instanceof ValidationErrors) {
                this.setState({ validationErrors: error }, () =>
                    scrollToFormFirstError(this.form.current)
                );
            } else {
                throw error;
            }
        }
    };

    render() {
        const { children, validationRules } = this.props;
        const { values, validationErrors } = this.state;

        return (
            <BootstrapForm
                onSubmit={this.handleSubmit}
                innerRef={this.form}
                noValidate
            >
                {children({
                    values,
                    validationErrors,
                    validationRules,
                    onChange: this.handleChange,
                })}
            </BootstrapForm>
        );
    }
}

export default Form;
