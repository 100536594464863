import React from 'react';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import Loader from 'Layout/Loader';
import { Link } from 'react-router-dom';
import { Container, Card, CardBody, Button } from 'reactstrap';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PATH_KNOWLEDGE_ARTICLE } from '../paths';
import ContentPlaceholder from '../../Content/Index/ContentPlaceholder';
import { withLocale } from '../../../TranslatorContext';

class ArticleList extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        category: PropTypes.string.isRequired,
        articleList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
        articlePagination: PropTypes.shape({
            pageCount: PropTypes.number,
            articlesCount: PropTypes.number,
            __typename: PropTypes.string,
        }).isRequired,
        loadArticle: PropTypes.func.isRequired,
        loadArticleList: PropTypes.func.isRequired,
        articlesPerPage: PropTypes.number.isRequired,
    };

    state = {
        pageNumber: {
            all: 1,
            diet: 1,
            workouts: 1,
            tips: 1,
        },
        articleList: {
            all: [],
            diet: [],
            workouts: [],
            tips: [],
        },
        isLoading: false,
    };

    handleScroll = () => {
        const bottom =
            Math.ceil(window.innerHeight + window.scrollY) >=
            document.documentElement.scrollHeight;

        if (bottom) {
            this.fetchMore();
        }
    };

    componentDidMount() {
        const { category, articleList } = this.props;

        if (this.state.articleList[category].length === 0) {
            this.setState(prevState => ({
                articleList: {
                    ...prevState.articleList,
                    [category]: articleList,
                },
            }));
        }
        this.props.trackFirebaseEvent('ArticleList_pageview');

        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps) {
        const { category, articleList } = this.props;

        if (prevProps.category !== category) {
            if (this.state.articleList[category].length === 0) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState(prevState => ({
                    articleList: {
                        ...prevState.articleList,
                        [category]: articleList,
                    },
                }));
            }
        }
    }

    loadMore = () => {
        const { category, articlesPerPage, loadArticleList } = this.props;

        this.setState(
            () => ({
                isLoading: true,
            }),
            async () => {
                const articleList = await loadArticleList({
                    variables: {
                        categoryIdentifier:
                            category === 'all' ? null : category,
                        pageNumber: this.state.pageNumber[category] + 1,
                        articlesPerPage,
                    },
                });

                this.setState(prevState => ({
                    isLoading: false,
                    articleList: {
                        ...prevState.articleList,
                        [category]: [
                            ...prevState.articleList[category],
                            ...articleList.data.article,
                        ],
                    },
                    pageNumber: {
                        ...prevState.pageNumber,
                        [category]: prevState.pageNumber[category] + 1,
                    },
                }));
            }
        );
    };

    async fetchMore() {
        const { category, articlesPerPage } = this.props;

        await this.props.loadArticleList({
            variables: {
                categoryIdentifier: category === 'all' ? null : category,
                pageNumber: this.state.pageNumber[category] + 1,
                articlesPerPage,
            },
        });
    }

    renderArticleList() {
        const { loadArticle, category } = this.props;

        if (this.state.articleList[category].length > 0) {
            const articlesArray = Object.assign(
                [],
                this.state.articleList[category]
            );
            return (
                <div>
                    {articlesArray.map(article => (
                        <Card
                            key={article.id}
                            tag={Link}
                            to={`${PATH_KNOWLEDGE_ARTICLE.split(':')[0]}${
                                article.id
                            }-${article.slug}`}
                            onMouseEnter={() => {
                                loadArticle({
                                    variables: { articleId: article.id },
                                });
                            }}
                            onTouchStart={() => {
                                loadArticle({
                                    variables: { articleId: article.id },
                                });
                            }}
                        >
                            <div className="thumbImg">
                                <LazyLoadImage
                                    className="img-fluid"
                                    effect="blur"
                                    src={article.thumbUrl}
                                    alt={article.title}
                                />
                            </div>
                            <CardBody>
                                <span className="category">
                                    {article.category.name}
                                </span>
                                <h2>{article.title}</h2>
                            </CardBody>
                        </Card>
                    ))}
                </div>
            );
        }

        return (
            <h2 className="text-center mb-0">
                {this.props.t('knowledge/no-results')}
            </h2>
        );
    }

    render() {
        const { category, articlePagination, t } = this.props;

        return (
            <Layout page="article-list">
                <section className="pt-3">
                    <Container>
                        {this.renderArticleList()}

                        {this.state.pageCount > this.state.pageNumber ? (
                            <section className="text-center py-0">
                                <Button
                                    color="primary"
                                    onClick={() => this.loadMore()}
                                    className="w-100"
                                >
                                    {this.props.t('knowledge/load-more')}
                                </Button>
                            </section>
                        ) : (
                            ''
                        )}

                        {articlePagination.pageCount >
                        this.state.pageNumber[category] ? (
                            <section className="text-center py-0">
                                <Button
                                    outline
                                    color="primary"
                                    onClick={() => this.loadMore()}
                                    onMouseEnter={() => this.fetchMore()}
                                    onTouchStart={() => this.fetchMore()}
                                >
                                    {t('knowledge/load-more')}
                                </Button>
                            </section>
                        ) : (
                            ''
                        )}
                    </Container>
                </section>

                {this.state.isLoading ? <Loader /> : null}
            </Layout>
        );
    }
}

export default withLoading(
    withFirebase(withLocale(ArticleList)),
    ContentPlaceholder
);
