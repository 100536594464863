import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import NewsletterUnsubscribeMutation from './NewsletterUnsubscribeMutation';

const NewsletterUnsubscribeConnectorApollo = ({ children }) => {
    const [mutate] = useMutation(NewsletterUnsubscribeMutation);

    const createUnsubscribe = async request => {
        const variables = request;

        const response = await mutate({
            variables,
        });

        return response;
    };

    return children({
        unsubscribe: createUnsubscribe,
    });
};

NewsletterUnsubscribeConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default NewsletterUnsubscribeConnectorApollo;
