import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UserDataOnDemand from '../UserData/Connector/Apollo/Fetch/UserDataOnDemandConnectorApollo';
import ActivateAccountConnector from './Connector/Apollo/ActivateAccount/ActivateAccountConnectorApollo';
import MeComponent from './MeComponent';

export class MeContainer extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    render() {
        let shouldActivateAccount = false;
        let authenticationTokenIsSet = false;
        let token = null;

        if (global.localStorage) {
            shouldActivateAccount =
                global.localStorage.getItem('authenticatedByQueryString') ===
                'true';
            authenticationTokenIsSet = Boolean(
                global.localStorage.getItem('token')
            );
            token = global.localStorage.getItem('token');
        }

        return (
            <ActivateAccountConnector>
                {({ ...activateAccount }) => (
                    <UserDataOnDemand>
                        {({ ...userDataOnDemand }) => (
                            <MeComponent
                                authenticationTokenIsSet={
                                    authenticationTokenIsSet
                                }
                                shouldActivateAccount={shouldActivateAccount}
                                token={token}
                                {...userDataOnDemand}
                                {...activateAccount}
                            >
                                {this.props.children}
                            </MeComponent>
                        )}
                    </UserDataOnDemand>
                )}
            </ActivateAccountConnector>
        );
    }
}

export default MeContainer;
