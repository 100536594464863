import React from 'react';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import { createDateObject } from 'utils/dateFormatter';
// import { withLocale } from '@dietlabs/components';
import { Container, Button, Alert } from 'reactstrap';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Redirect } from 'react-router';
import { PATHS } from 'config/paths';
import SEO from 'SEO/SEO';
import { callNativeAppRemoveLastRoute } from 'components/Helpers/callNativeAppRemoveLastRoute';
import HpbaMarkdown from '../../Content/utils/HpbaMarkdown';
import ContentPlaceholder from '../../Content/Index/ContentPlaceholder';
import { withLocale } from '../../../TranslatorContext';

class Article extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        article: PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            category: PropTypes.shape({
                name: PropTypes.string,
            }).isRequired,
            lead: PropTypes.shape({
                html: PropTypes.string,
                markdown: PropTypes.string,
            }),
            content: PropTypes.shape({
                html: PropTypes.string,
                markdown: PropTypes.string,
            }),
            photoUrl: PropTypes.string.isRequired,
            summary: PropTypes.shape({
                html: PropTypes.string,
                markdown: PropTypes.string,
            }),
            author: PropTypes.shape({
                name: PropTypes.string,
                isFemale: PropTypes.string,
                description: PropTypes.string,
                avatar: PropTypes.string,
            }).isRequired,
        }).isRequired,
        accessTo: PropTypes.string,
        trackFirebaseEvent: PropTypes.func.isRequired,
        loadSaleProduct: PropTypes.func.isRequired,
        loadContent: PropTypes.func.isRequired,
    };

    static defaultProps = {
        accessTo: undefined,
    };

    componentDidMount() {
        if (this.props.article) {
            this.props.trackFirebaseEvent('Article_pageview', {
                title: this.props.article.title,
            });
        }
    }

    renderLead = () => {
        if (this.props.article.lead.markdown !== '') {
            return (
                <React.Fragment>
                    <div className="article-lead">
                        <HpbaMarkdown
                            content={this.props.article.lead.markdown}
                            loadSaleProduct={this.props.loadSaleProduct}
                        />
                    </div>
                </React.Fragment>
            );
        }

        return '';
    };

    renderContent = hasAccess => {
        if (this.props.article.content.markdown !== '' && hasAccess) {
            return (
                <React.Fragment>
                    <div className="cms-content">
                        <HpbaMarkdown
                            content={this.props.article.content.markdown}
                            loadSaleProduct={this.props.loadSaleProduct}
                            loadContent={this.props.loadContent}
                        />
                        <div className="clearfix" />
                    </div>
                </React.Fragment>
            );
        }

        return '';
    };

    renderSummary = hasAccess => {
        if (this.props.article.summary.markdown !== '' && hasAccess) {
            return (
                <React.Fragment>
                    <div className="article-summary">
                        <HpbaMarkdown
                            content={this.props.article.summary.markdown}
                            loadSaleProduct={this.props.loadSaleProduct}
                        />
                    </div>
                    <hr />
                </React.Fragment>
            );
        }
        return '';
    };

    render() {
        let hasAccess = true;

        if (this.props.accessTo) {
            const now = new Date();
            const today = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate()
            );
            const date = createDateObject(this.props.accessTo);
            if (date >= today) {
                hasAccess = true;
            }
        }

        if (this.props.article) {
            return (
                <React.Fragment>
                    <SEO title={this.props.article.title} />
                    <Layout page="cms-article">
                        <div className="bg-white">
                            <header>
                                <Container>
                                    <span className="category">
                                        {this.props.article.category.name}
                                    </span>
                                    <h1>{this.props.article.title}</h1>
                                    {this.renderLead()}
                                </Container>
                            </header>
                            <section className="pt-0">
                                <Container>
                                    <div className="article-img">
                                        <LazyLoadImage
                                            className="img-fluid"
                                            effect="blur"
                                            src={this.props.article.photoUrl}
                                        />
                                    </div>

                                    {this.renderContent(hasAccess)}
                                    {this.renderSummary(hasAccess)}

                                    {!hasAccess ? (
                                        <Alert color="danger">
                                            {this.props.t(
                                                'knowledge/no-access'
                                            )}
                                        </Alert>
                                    ) : (
                                        ''
                                    )}
                                    <hr />
                                    <Button
                                        color="primary"
                                        outline
                                        className="w-100"
                                        onClick={() => {
                                            window.history.back();
                                            callNativeAppRemoveLastRoute(
                                                'knowledge'
                                            );
                                        }}
                                    >
                                        {this.props.t(
                                            'knowledge/back-to-article-list'
                                        )}
                                    </Button>
                                </Container>
                            </section>
                        </div>
                    </Layout>
                </React.Fragment>
            );
        }
        return <Redirect to={PATHS.ERROR_404} />;
    }
}

export default withLoading(
    withFirebase(withLocale(Article)),
    ContentPlaceholder
);
