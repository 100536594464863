import { connect } from 'react-redux';
import TrackingStopComponent from 'components/Tracking/TrackingStopComponent';

import { trackingStopAction } from './TrackingStopAction';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    stopTracking: () => dispatch(trackingStopAction()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TrackingStopComponent);
