import gql from 'graphql-tag';

export default gql`
    mutation AuthRegisterViaFacebook($token: String!) {
        auth {
            registerViaFacebook(token: $token) {
                ... on OauthToken {
                    token
                }
                ... on OauthException {
                    code
                }
            }
        }
    }
`;
