import Fraction from 'fraction.js';

function calculateFraction(count) {
    let res;
    let number = count;

    const intNumber = Math.floor(number);
    let decimalNumber = number % 1;
    decimalNumber = Math.round(decimalNumber * 100) / 100;

    if (decimalNumber === 0.33) {
        decimalNumber = '.(3)';
    } else if (decimalNumber === 0.66) {
        decimalNumber = '.(6)';
    } else if (decimalNumber === 0.16) {
        decimalNumber = '.1(6)';
    } else if (decimalNumber === 0.83) {
        decimalNumber = '.8(3)';
    }

    number = intNumber + decimalNumber;

    const x = new Fraction(number);
    const availableDenominators = [2, 3, 4, 5, 6, 8];

    if (availableDenominators.indexOf(x.d) > -1) {
        res = x.toFraction(true);
        res = res.split('/');
    } else {
        res = Math.round(count * 100) / 100;
    }

    if (res.length > 1) {
        res = res.map((item, key) => {
            if (key === 0) {
                const itemParts = item.split(' ');
                if (itemParts.length === 1) {
                    return `<span><sup>${item}</sup>/</span>`;
                }

                return `<span>${itemParts[0]}<sup>${itemParts[1]}</sup>/</span>`;
            }
            return `<sub>${item}</sub>`;
        });
        return res.join('');
    }

    return res;
}

export default calculateFraction;
