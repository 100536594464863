import {
    SALE_PRODUCT_LOAD_FAILED_RECEIVE_RESPONSE,
    SALE_PRODUCT_LOAD_SUCCESS_RECEIVE_RESPONSE,
} from './Load/SaleProductLoadActionType';

export default function SaleProductReducer(state, action) {
    let result = state ? { ...state } : {};

    switch (action.type) {
        case SALE_PRODUCT_LOAD_FAILED_RECEIVE_RESPONSE:
            result = {};
            break;
        case SALE_PRODUCT_LOAD_SUCCESS_RECEIVE_RESPONSE:
            result = action.products;
            break;
        default:
            return result;
    }

    return result;
}
