export class FlashMessageSuccess {
    constructor(content, id) {
        this.content = content;
        this.type = 'success';
        this.id = id;
    }
}

export class FlashMessageInfo {
    constructor(content, id) {
        this.content = content;
        this.type = 'info';
        this.id = id;
    }
}

export class FlashMessageWarning {
    constructor(content, id) {
        this.content = content;
        this.type = 'warning';
        this.id = id;
    }
}

export class FlashMessageError {
    constructor(content, id) {
        this.content = content;
        this.type = 'error';
        this.id = id;
    }
}
