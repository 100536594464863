import React from 'react';
import { Col, Container, Form, FormGroup, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import InputRadio from 'components/FormElements/InputRadioControlled2';
import withLoading from 'Layout/withLoading';
import SystemOfMeasurementsFormPlaceholder from './SystemOfMeasurementsFormPlaceholder';
import { withLocale } from '../../../TranslatorContext';

class SystemOfMeasurementsForm extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        className: PropTypes.string,
        setSystemOfMeasures: PropTypes.shape({
            changeSettings: PropTypes.func.isRequired,
        }).isRequired,
        value: PropTypes.oneOf(['si', 'imperial']).isRequired,
    };

    static defaultProps = {
        className: null,
    };

    handleInputChange = event => {
        this.props.setSystemOfMeasures.changeSettings(event.target.value);
    };

    render() {
        return (
            <Container className={this.props.className}>
                <Row>
                    <Col xs="12" md={{ size: 8, offset: 2 }}>
                        <Form>
                            <FormGroup tag="fieldset">
                                <h2 className="heading-3">
                                    {this.props.t('settings/metric-system')}
                                </h2>

                                <InputRadio
                                    id="systemOfMeasures1"
                                    label={this.props.t('settings/si')}
                                    name="systemOfMeasures"
                                    value="si"
                                    handleChange={this.handleInputChange}
                                    checked={this.props.value === 'si'}
                                />
                                <InputRadio
                                    id="systemOfMeasures2"
                                    label={this.props.t('settings/imperial')}
                                    name="systemOfMeasures"
                                    value="imperial"
                                    handleChange={this.handleInputChange}
                                    checked={this.props.value === 'imperial'}
                                />
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withLoading(
    withLocale(SystemOfMeasurementsForm),
    SystemOfMeasurementsFormPlaceholder
);
