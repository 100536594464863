import React, { Component } from 'react';

class TableRowPlaceholder extends Component {
    render() {
        return (
            <tr>
                <td className="placeholder">&nbsp;</td>
            </tr>
        );
    }
}

export default TableRowPlaceholder;
