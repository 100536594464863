import gql from 'graphql-tag';

export default gql`
    mutation AuthResetPassword($token: String!, $password: String!) {
        auth {
            resetPassword(token: $token, password: $password) {
                ... on BasicMutationSuccess {
                    code
                }
                ... on ValidationException {
                    code
                    messages
                    details {
                        fieldName
                        messages
                    }
                }
            }
        }
    }
`;
