import gql from 'graphql-tag';

export default gql`
    mutation saveUserData(
        $name: String
        $birthDate: Date!
        $isFemale: Boolean!
        $height: UserMeasurementHeightInputType
    ) {
        me {
            saveUserData(
                name: $name
                birthDate: $birthDate
                isFemale: $isFemale
                height: $height
            ) {
                __typename
                ... on ValidationException {
                    code
                    messages
                    details {
                        fieldName
                        messages
                    }
                }
                ... on BasicMutationSuccess {
                    code
                }
            }
        }
    }
`;
