import { reportWarning } from '@dietlabs/utils';
import { LANGUAGE } from '../consts';

const FALLBACK_LANGUAGE = 'en';

let warnedAboutUsingFallbackLanguage = false;

function getLanguageFromDocument() {
    const lang =
        document && document.documentElement && document.documentElement.lang;

    if (lang) {
        return lang.replace(/_/g, '-');
    }

    return null;
}

function getLanguage() {
    let lang;

    lang = getLanguageFromDocument();
    if (lang) {
        return lang;
    }

    lang = LANGUAGE;
    if (lang) {
        return lang;
    }

    if (!warnedAboutUsingFallbackLanguage) {
        warnedAboutUsingFallbackLanguage = true;
        reportWarning(
            new Error('Failed to determine language, using fallback value.')
        );
    }

    return FALLBACK_LANGUAGE;
}

export default getLanguage;
