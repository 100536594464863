import callNativeMobileApp from './callNativeMobileApp';

export function callNativeAppRemoveLastRoute(mobileAppTabIdentifier) {
    // console.log('remove last route');
    callNativeMobileApp({
        action: 'remove_last_route',
        payload: {
            mobileAppTabIdentifier,
        },
    });
}
