import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import ArticleFetchQuery from './ArticleFetchQuery';

const ArticleFetchConnectorApollo = ({ children, articleId }) => {
    const { loading, error, data } = useQuery(ArticleFetchQuery, {
        variables: { articleId },
    });

    const result = {
        loading,
        error,
    };

    if (!loading) {
        result.article = data.article[0];
        if (data.me) {
            result.accessTo = data.me.diet.accessTo;
        }
    }
    return children(result);
};

ArticleFetchConnectorApollo.propTypes = {
    articleId: PropTypes.number.isRequired,
    children: PropTypes.func.isRequired,
};

export default ArticleFetchConnectorApollo;
