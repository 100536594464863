import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import SendRequestToRemoveAccountQuery from './SendRequestToRemoveAccountQuery';

const DeleteUserAccountConnectorApollo = ({ children }) => {
    const [sendRequestToRemoveAccount, { loading }] = useMutation(
        SendRequestToRemoveAccountQuery
    );

    const result = {
        sendRequestToRemoveAccount,
        loading,
    };

    return children(result);
};

DeleteUserAccountConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default DeleteUserAccountConnectorApollo;
