import React, { Component } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { withLocale } from '../../../../TranslatorContext';

class HydrationDayGoalPlaceholder extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        return (
            <div className="hydration-goal text-center">
                <Button color="placeholder" className="btn-goal">
                    {this.props.t('hydration/goal-label')}{' '}
                    <span className="your-goal">2000 ml</span>
                </Button>
            </div>
        );
    }
}

export default withLocale(HydrationDayGoalPlaceholder);
