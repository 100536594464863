import gql from 'graphql-tag';

export default gql`
    mutation NewsletterUnsubscribeMutation($email: String!) {
        newsletter {
            unsubscribe(email: $email) {
                ... on BasicMutationSuccess {
                    code
                }
            }
        }
    }
`;
