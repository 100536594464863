import React from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import removeAuthCredentials from '../removeAuthCredentials';

export class AuthLogoutPage extends React.Component {
    static propTypes = {
        client: PropTypes.shape({
            clearStore: PropTypes.func.isRequired,
        }).isRequired,
    };

    componentDidMount() {
        this.props.client.clearStore().then(() => {
            global.localStorage.removeItem('token');
            global.localStorage.removeItem('sex');
            global.localStorage.removeItem('apollo-cache-persist');
            global.localStorage.removeItem('userId');
            removeAuthCredentials();
            window.location.reload();
        });
    }

    render() {
        return null;
    }
}

export default withApollo(AuthLogoutPage);
