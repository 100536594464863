import React from 'react';
import CouponActivateContainer from './CouponActivateContainer';
import ActivateConnector from './Connector/Apollo/Activate/ActivateConnectorApollo';

class CouponActivatePage extends React.Component {
    render() {
        return (
            <ActivateConnector>
                {({ ...activateCoupon }) => (
                    <CouponActivateContainer {...activateCoupon} />
                )}
            </ActivateConnector>
        );
    }
}

export default CouponActivatePage;
