/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import { Container, Row, Col } from 'reactstrap';
import achievementBg from 'assets/images/achievements/achievement-bg.png';
import { formatMediumDate } from 'utils/dateFormatter';
import AchievementsPlaceholder from './AchievementsPlaceholder';
import { withLocale } from '../../TranslatorContext';

class Achievements extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        achievements: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                isAchived: PropTypes.bool,
                name: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
                thumbnailUrl: PropTypes.string.isRequired,
                imageUrl: PropTypes.string.isRequired,
                userAchievement: PropTypes.arrayOf(
                    PropTypes.shape({
                        createdAt: PropTypes.string,
                    })
                ),
            })
        ).isRequired,
    };

    state = {
        featuredAchivemenet: this.lastRevealedAchievement(),
    };

    lastRevealedAchievement() {
        let referenceDate = new Date('2000-01-01');
        let lastRevealedAchievement = this.props.achievements[0].id;

        this.props.achievements.forEach(achievement => {
            if (achievement.isAchieved) {
                const date = new Date(achievement.userAchievement[0].createdAt);

                if (date >= referenceDate) {
                    referenceDate = date;
                    lastRevealedAchievement = achievement.id;
                }
            }
        });

        return lastRevealedAchievement;
    }

    setFeaturedAchivement = featuredAchivemenet => {
        this.setState({ featuredAchivemenet });
    };

    render() {
        const featuredAchivement = this.props.achievements.filter(
            el => el.id === this.state.featuredAchivemenet
        )[0];

        return (
            <Layout page="achievements">
                <section
                    className={
                        featuredAchivement.isAchieved
                            ? 'featured-achievement is-achieved'
                            : 'featured-achievement'
                    }
                >
                    <Container>
                        <img className="bg" src={achievementBg} alt="" />

                        <img
                            src={featuredAchivement.imageUrl}
                            className="img-fluid"
                            alt={featuredAchivement.name}
                        />
                        <span className="achievement-title">
                            {featuredAchivement.name}
                        </span>
                        <span className="achievement-date">
                            {featuredAchivement.isAchieved
                                ? formatMediumDate(
                                      new Date(
                                          featuredAchivement.userAchievement[0].createdAt
                                      )
                                  )
                                : this.props.t('achievements/not-yet-achieved')}
                        </span>
                    </Container>
                </section>
                <Container>
                    <section>
                        <h2>{this.props.t('achievements/all-achievements')}</h2>
                        <Row>
                            {this.props.achievements.map(achievement => {
                                const achievementClasses = ['achievement'];

                                let date = '';

                                if (achievement.isAchieved) {
                                    achievementClasses.push('is-achieved');
                                    date = formatMediumDate(
                                        new Date(
                                            achievement.userAchievement[0].createdAt
                                        )
                                    );
                                }

                                if (
                                    achievement.id ===
                                    this.state.featuredAchivemenet
                                ) {
                                    achievementClasses.push('active');
                                }

                                return (
                                    <Col xs="4" key={achievement.id}>
                                        <div
                                            className={achievementClasses.join(
                                                ' '
                                            )}
                                            onClick={() =>
                                                this.setFeaturedAchivement(
                                                    achievement.id
                                                )
                                            }
                                        >
                                            <img
                                                src={achievement.thumbnailUrl}
                                                className="img-fluid"
                                                alt={achievement.name}
                                            />
                                            <span className="achievement-title">
                                                {achievement.name}
                                            </span>

                                            <span className="achievement-date">
                                                &nbsp;{date}&nbsp;
                                            </span>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    </section>
                </Container>
            </Layout>
        );
    }
}

export default withLoading(withLocale(Achievements), AchievementsPlaceholder);
