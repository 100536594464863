import React from 'react';

const withMobileAppMode = ChildComponent => {
    class HOC extends React.Component {
        render() {
            const mobileApp = JSON.parse(
                global.localStorage.getItem('mobileApp')
            );
            return (
                <ChildComponent
                    {...this.props}
                    mobileAppMode={Boolean(mobileApp.active)}
                    mobileApp={mobileApp}
                />
            );
        }
    }
    return HOC;
};

export default withMobileAppMode;
