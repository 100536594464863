import gql from 'graphql-tag';

export default gql`
    mutation AuthLoginByEmailAndPasswordMutation(
        $email: String
        $password: String
    ) {
        auth {
            login(email: $email, password: $password) {
                __typename
                ... on AuthLoginSuccess {
                    token
                }
                ... on UnauthorizedException {
                    code
                }
            }
        }
    }
`;
