import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button, Form, Alert } from 'reactstrap';
import Loader from 'Layout/Loader';
import { Redirect, withRouter } from 'react-router';
import { PATHS } from 'config/paths';

import InputPassword from 'components/FormElements/InputPasswordControlled';
import ValidationErrors from 'view/Validation/ValidationErrors';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import { validateFields } from 'view/Validation/validateFields';
import { validateField } from 'view/Validation/validateField';
import Layout from 'Layout/Layout';
import { FLASH_MESSAGES } from 'consts';
import { FlashMessageSuccess } from '../../FlashMessage/FlashMessage';
import withFlashMessage from '../../FlashMessage/withFlashMessage';
import { withLocale } from '../../TranslatorContext';

export class AuthSetInitialPasswordComponent extends React.Component {
    static propTypes = {
        setPassword: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
        addMessage: PropTypes.func.isRequired,
        removeMessage: PropTypes.func.isRequired,
    };

    validationRules = {
        password: [new RequiredRule({ translator: this.props.t })],
    };

    state = {
        password: '',
        preload: false,
        errors: new ValidationErrors(),
        error: false,
        success: false,
    };

    componentDidMount() {
        this.props.removeMessage(
            FLASH_MESSAGES.AUTH.SET_INITIAL_PASSWORD.NO_PASSWORD
        );
    }

    handleSubmit = async event => {
        event.preventDefault();

        const frontEndErrors = validateFields(
            this.validationRules,
            this.state,
            this.props.t
        );

        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                details: frontEndErrors,
            },
        }));

        if (frontEndErrors.length === 0) {
            this.setState({ preload: true });

            try {
                const response = await this.props.setPassword({
                    token: localStorage.getItem('token'),
                    password: this.state.password,
                });

                const { code } = response.data.auth.setInitialPassword;

                if (code === 200) {
                    this.props.addMessage(
                        new FlashMessageSuccess(
                            this.props.t('auth/reset-password/success-message'),
                            FLASH_MESSAGES.AUTH.SET_INITIAL_PASSWORD.SUCCESS
                        )
                    );
                    this.setState({
                        password: '',
                        errors: new ValidationErrors(),
                        error: false,
                        success: true,
                        preload: false,
                    });
                } else {
                    this.setState({
                        preload: false,
                        errors: response.data.auth.setInitialPassword,
                        error: true,
                        success: false,
                    });
                }
            } catch (e) {
                this.setState({
                    success: false,
                    preload: false,
                });
                throw new Error(
                    `Failed to set initial password, got error: ${e}`
                );
            }
        }
    };

    handleInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });

        if (this.validationRules[event.target.name]) {
            const field = event.target.name;
            const value = event.target.value;

            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    details: validateField(
                        field,
                        value,
                        this.validationRules[field],
                        prevState
                    ),
                },
            }));
        }
    };

    render() {
        if (this.state.success) {
            return <Redirect to={PATHS.DEFAULT_PAGE_AFTER_LOGGED_IN} />;
        }

        return (
            <Layout page="login">
                <section className="bg-white">
                    <Container>
                        <h1 className="text-center">
                            {this.props.t('auth/set-password/title')}
                        </h1>
                        <Row>
                            <Col xs="12" sm={{ size: 6, offset: 3 }}>
                                {this.state.error ? (
                                    <Alert color="danger">
                                        {this.props.t('error/message/generic')}
                                    </Alert>
                                ) : (
                                    ''
                                )}
                                <Form
                                    onSubmit={this.handleSubmit}
                                    data-test="set-password-form"
                                    noValidate
                                >
                                    <InputPassword
                                        label={this.props.t('password')}
                                        name="password"
                                        autoComplete="new-password"
                                        value={this.state.password}
                                        handleChange={this.handleInputChange}
                                        validationRules={
                                            this.validationRules.password
                                        }
                                        errors={this.state.errors}
                                        data-test="password-input"
                                    />

                                    <div className="text-center">
                                        <Button
                                            color="primary"
                                            data-test="set-password-button"
                                        >
                                            {this.props.t('form/send')}
                                        </Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {this.state.preload ? <Loader /> : ''}
            </Layout>
        );
    }
}

export default withFlashMessage(
    withRouter(withLocale(AuthSetInitialPasswordComponent))
);
