import { shape, string, arrayOf, oneOf, number } from 'prop-types';
import { SYSTEM_OF_MEASURES } from '../consts';

//
// to consider:  https://github.com/peterkhayes/extended-proptypes
//

export const DateType = (
    propValue,
    key,
    componentName,
    location,
    propFullName
) => {
    if (!Date.parse(propValue[key]) && propValue[key] != null) {
        return new Error(
            `Date validation failed for${propFullName} in ${componentName}`
        );
    }

    return null;
};

export const ApiDateType = (props, propName, componentName) => {
    if (
        props[propName] &&
        !/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(props[propName])
    ) {
        return new Error(
            `Invalid prop \`${propName}\` supplied to` +
                ` \`${componentName}\`. Validation failed.`
        );
    }

    return null;
};

export const SystemOfMeasuresType = oneOf([
    SYSTEM_OF_MEASURES.IMPERIAL,
    SYSTEM_OF_MEASURES.SI,
]);

export const InputErrorType = shape({
    details: arrayOf(
        shape({
            messages: arrayOf(string),
        })
    ),
});

export const FullMeasurementType = shape({
    value: number.isRequired,
    unit: string.isRequired,
    change: number,
    date: string.isRequired,
});
