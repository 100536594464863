import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import SaveUserGoalMutation from './SaveUserGoalMutation';

const SaveUserGoalConnectorApollo = ({ children }) => {
    const [mutate] = useMutation(SaveUserGoalMutation);

    const createSaveUserGoal = async (request, userId) => {
        const variables = request;

        const response = await mutate({
            variables,
            update: cache => {
                cache.modify({
                    id: `Me:${userId}`,
                    fields: {
                        goalWeight(data) {
                            return { ...data, value: request.goalWeight.value };
                        },
                        diet(data) {
                            return { ...data, mode: request.dietMode };
                        },
                    },
                });

                cache.modify({
                    id: 'UserMeasurement:{}',
                    fields: {
                        weight(data) {
                            const currentWeight = { ...data[0] };
                            currentWeight.value = request.weight.value;

                            return [currentWeight];
                        },
                    },
                });
            },
        });

        return response;
    };

    return children({
        saveUserGoal: createSaveUserGoal,
    });
};

SaveUserGoalConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default SaveUserGoalConnectorApollo;
