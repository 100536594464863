import gql from 'graphql-tag';

export default gql`
    mutation AuthRegisterViaGoogle(
        $token: String!
        $authorizationCode: OauthReturnUrlType!
    ) {
        auth {
            registerViaGoogle(
                token: $token
                authorizationCode: $authorizationCode
            ) {
                ... on OauthToken {
                    token
                }
                ... on OauthException {
                    code
                }
            }
        }
    }
`;
