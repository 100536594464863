import React from 'react';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import { Container } from 'reactstrap';
import HpbaMarkdown from '../utils/HpbaMarkdown';
import ContentPlaceholder from './ContentPlaceholder';
import { withLocale } from '../../../TranslatorContext';

class DietFaq extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        content: PropTypes.shape({
            content: PropTypes.shape({
                html: PropTypes.string,
                markdown: PropTypes.string,
            }),
        }).isRequired,
    };

    render() {
        return (
            <Layout page="cms-article">
                <Container>
                    <header>
                        <h1>{this.props.t('diet-faq/title')}</h1>
                    </header>
                    <section className="pt-0">
                        <div className="cms-content">
                            <HpbaMarkdown
                                content={this.props.content.content.markdown}
                            />
                        </div>
                    </section>
                </Container>
            </Layout>
        );
    }
}

export default withLoading(withLocale(DietFaq), ContentPlaceholder);
