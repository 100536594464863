import React, { Component } from 'react';
import {
    Col,
    FormGroup,
    Label,
    Input,
    InputGroup,
    FormFeedback,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { DateType, InputErrorType } from 'types/CommonTypes';
import { formatIsoDate } from 'utils/dateFormatter';
import withRequired from './withRequired';

class InputDate extends Component {
    renderInput = errorMgs => (
        <React.Fragment>
            <InputGroup
                className={
                    errorMgs ? 'form-control is-invalid' : 'form-control'
                }
            >
                <Input
                    type="date"
                    name={this.props.name}
                    value={this.props.value}
                    min={this.props.min}
                    max={this.props.max}
                    onChange={this.props.handleChange}
                    onBlur={this.props.onBlur}
                    required={this.props.required}
                    pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                    placeholder={formatIsoDate(new Date())}
                />
            </InputGroup>

            <FormFeedback>
                {errorMgs
                    ? errorMgs.map((message, k) => <li key={k}>{message}</li>)
                    : ''}
            </FormFeedback>
        </React.Fragment>
    );

    render() {
        let errorMgs;

        if (this.props.errors) {
            this.props.errors.details.forEach(error => {
                if (error.fieldName === this.props.name) {
                    errorMgs = error.messages;
                }
            });
        }

        return (
            <FormGroup
                row={this.props.inline}
                className={
                    errorMgs
                        ? 'is-invalid align-items-center'
                        : 'align-items-center'
                }
            >
                {this.props.label ? (
                    <Label
                        for={this.props.name}
                        className={this.props.inline ? 'col-5 mb-0' : ''}
                    >
                        {this.props.label}{' '}
                        {this.props.required ? <sup>*</sup> : ''}
                    </Label>
                ) : (
                    ''
                )}
                {this.props.inline ? (
                    <Col xs={7}>{this.renderInput(errorMgs)}</Col>
                ) : (
                    this.renderInput(errorMgs)
                )}
            </FormGroup>
        );
    }
}

InputDate.propTypes = {
    errors: InputErrorType,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    value: DateType,
    min: DateType,
    max: DateType,
    validationErrors: PropTypes.object, //eslint-disable-line
    onBlur: PropTypes.func,
    inline: PropTypes.bool,
};

InputDate.defaultProps = {
    errors: undefined,
    label: null,
    required: false,
    value: undefined,
    min: undefined,
    max: undefined,
    onBlur: undefined,
    inline: false,
};

export default withRequired(InputDate);
