import DietFaqPage from './Index/DietFaqPage';
import TermsOfServicePage from './Index/TermsOfServicePage';
import ContentPage from './Index/ContentPage';

const PATH_DIET_FAQ = '/content/diet-faq';
const PATH_TERMS_OF_SERVICE = '/terms-of-service';
const PATH_CONTENT =
    '/:content(privacy-policy|payment-info|terms-of-service-android|terms-of-service-ios|terms-of-service-web|terms-of-service-huawei|how-to|faq:dietitian|info)';

const routes = [
    {
        path: PATH_DIET_FAQ,
        component: DietFaqPage,
        private: false,
        exact: true,
        title: 'route-title/content',
    },
    {
        path: PATH_TERMS_OF_SERVICE,
        component: TermsOfServicePage,
        private: false,
        exact: true,
        title: 'route-title/content',
    },
    {
        path: PATH_CONTENT,
        component: ContentPage,
        private: false,
        exact: true,
        title: 'route-title/content',
    },
];

export { routes };
