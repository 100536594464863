import { Component } from 'react';
import PropTypes from 'prop-types';
import { APP_TABS } from 'consts';

class GetActiveMobileAppTabIdentifier extends Component {
    componentDidMount() {
        this.initActiveMobileAppTab();
    }

    initActiveMobileAppTab() {
        window.getActiveMobileAppTabIdentifier = () =>
            this.props.mobileAppTabIdentifier;
    }

    render() {
        return null;
    }
}

GetActiveMobileAppTabIdentifier.propTypes = {
    mobileAppTabIdentifier: PropTypes.oneOf(APP_TABS),
};

export default GetActiveMobileAppTabIdentifier;
