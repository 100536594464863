import React from 'react';

const errorBoundary = React.createContext(() => {
    throw new Error('No errorBoundary handler specified.');
});
const Provider = errorBoundary.Provider;
const Consumer = errorBoundary.Consumer;

export default errorBoundary;
export { Provider, Consumer };
