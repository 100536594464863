import { Component } from 'react';
import { withRouter } from 'react-router';

let gotoQueue = null;
window.goTo = url => {
    gotoQueue = url;
};

class GoTo extends Component {
    componentDidMount() {
        this.init();
    }

    init() {
        window.goTo = url => this.props.history.push(url);
        window.goTo.initialized = true;

        if (gotoQueue) {
            window.goTo(gotoQueue);
            gotoQueue = null;
        }
    }

    render() {
        return null;
    }
}

export default withRouter(GoTo);
