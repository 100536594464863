import gql from 'graphql-tag';

const query = gql`
    query Article($articleId: Int) {
        me {
            diet {
                id
                accessTo
            }
        }
        article(articleId: $articleId) {
            id
            title
            slug
            category {
                name
                identifier
                label {
                    color
                }
            }
            lead {
                markdown
                html
            }
            content {
                markdown
                html
            }
            photoUrl
            summary {
                markdown
                html
            }
            onlyForPayingUsersMessage
            onlyForPayingUsersCtaLabel
            ctaLabel
            ctaUrl
            author {
                name
                isFemale
                description
                avatar
            }
        }
    }
`;

export default query;
