import { Component } from 'react';
import PropTypes from 'prop-types';

class GetMobileAppPageTitle extends Component {
    componentDidMount() {
        this.init();
    }

    init() {
        window.getMobileAppTitle = () => this.props.title;
    }

    render() {
        return null;
    }
}

GetMobileAppPageTitle.propTypes = {
    title: PropTypes.string.isRequired,
};

export default GetMobileAppPageTitle;
