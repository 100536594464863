import React from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { formatMediumDate } from 'utils/dateFormatter';
import { Link } from 'react-router-dom';
import withLoading from 'Layout/withLoading';
import UserDataPlaceholder from './UserDataPlaceholder';
import { withLocale } from '../../../TranslatorContext';
// eslint-disable-next-line import/no-cycle
import { PATH_SETTINGS_USER_DATA } from '../routes';

class UserData extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        sex: PropTypes.string.isRequired,
        dateOfBirth: PropTypes.string.isRequired,
        height: PropTypes.shape({
            value: PropTypes.number,
            unit: PropTypes.string,
        }).isRequired,
    };

    renderGender = () => {
        let gender;

        switch (this.props.sex) {
            case 'male':
                gender = this.props.t('diet-settings/sex/male');
                break;
            case 'female':
                gender = this.props.t('diet-settings/sex/female');
                break;
            default:
                gender = '-';
        }

        return gender;
    };

    render() {
        const { name, dateOfBirth, height, t } = this.props;

        return (
            <Container>
                <Row>
                    <Col xs="12" md={{ size: 8, offset: 2 }}>
                        <h2 className="heading-3">
                            {t('settings/user-data')}{' '}
                            <Button
                                tag={Link}
                                to={PATH_SETTINGS_USER_DATA}
                                color="link"
                                className="float-right px-0 py-0"
                            >
                                {t('edit')}
                            </Button>
                        </h2>
                        <p className="d-flex justify-content-between">
                            {t('settings/name')}: <span>{name || `-`}</span>
                        </p>
                        <p className="d-flex justify-content-between">
                            {t('settings/gender')}:{' '}
                            <span>{this.renderGender()}</span>
                        </p>
                        <p className="d-flex justify-content-between">
                            {t('settings/date-of-birth')}:{' '}
                            <span>{formatMediumDate(dateOfBirth) || '-'}</span>
                        </p>
                        <p className="d-flex justify-content-between">
                            {t('settings/height')}:{' '}
                            <span>
                                {height
                                    ? `${height.value} ${height.unit}`
                                    : '-'}
                            </span>
                        </p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withLoading(withLocale(UserData), UserDataPlaceholder);
