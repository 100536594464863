import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, InputGroup } from 'reactstrap';

class FormGroupPlaceholder extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        required: PropTypes.bool,
    };

    static defaultProps = {
        required: false,
    };

    render() {
        const { label } = this.props;
        return (
            <FormGroup>
                <Label>
                    {this.props.required ? <sup>*</sup> : ''} {label}
                </Label>

                <InputGroup className="form-control">
                    <div className="input-placeholder placeholder">&nbsp;</div>
                </InputGroup>
            </FormGroup>
        );
    }
}

export default FormGroupPlaceholder;
