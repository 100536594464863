import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import { Link } from 'react-router-dom';
import { Container, Button } from 'reactstrap';
import { MEASUREMENT_TYPES, PERIOD_END, PERIOD_LENGTH } from '../constants';
import CurrentMeasurementsConnectorApollo from '../Connector/Apollo/CurrentMeasurements/CurrentMeasurementsConnectorApollo';
import MeasurementHistoryConnectorApollo from '../Connector/Apollo/MeasurementHistory/MeasurementHistoryConnectorApollo';
// eslint-disable-next-line import/no-cycle
import MeasurementHistoryContainer from '../MeasurementHistory/MeasurementHistoryContainer';
// eslint-disable-next-line import/no-cycle
import CurrentMeasurementsContainer from '../CurrentMeasurements/CurrentMeasurementsContainer';
// eslint-disable-next-line import/no-cycle
import { PATH_MEASUREMENT_ADD } from '../routes';
import { withLocale } from '../../../TranslatorContext';

class MeasurementsPage extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Layout page="measurement">
                <CurrentMeasurementsConnectorApollo>
                    {currentMeasurementData => (
                        <CurrentMeasurementsContainer
                            {...currentMeasurementData}
                        />
                    )}
                </CurrentMeasurementsConnectorApollo>

                <MeasurementHistoryConnectorApollo
                    initialMeasurementType={MEASUREMENT_TYPES.WEIGHT}
                    periodLength={PERIOD_LENGTH}
                    periodEnd={PERIOD_END}
                >
                    {measurementHistoryData => (
                        <MeasurementHistoryContainer
                            {...measurementHistoryData}
                        />
                    )}
                </MeasurementHistoryConnectorApollo>

                <footer>
                    <Container className="text-center">
                        <Button
                            color="primary"
                            tag={Link}
                            to={PATH_MEASUREMENT_ADD}
                            className="w-100"
                        >
                            {this.props.t('measurments/add-measurments-button')}
                        </Button>
                    </Container>
                </footer>
            </Layout>
        );
    }
}

export default withLocale(MeasurementsPage);
