import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '../../../../../Apollo/Mutation';
import RemindPasswordMutation from './AuthRemindPasswordMutation';

class RemindPasswordConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    createRemindPassword = mutate => async request => {
        const variables = request;

        const response = await mutate({
            variables,
        });

        return response;
    };

    render() {
        const { children } = this.props;

        return (
            <Mutation mutation={RemindPasswordMutation}>
                {mutate =>
                    children({
                        remindPassword: this.createRemindPassword(mutate),
                    })
                }
            </Mutation>
        );
    }
}

export default RemindPasswordConnectorApollo;
