import { createApolloFetch, constructDefaultOptions } from 'apollo-fetch';
import config from 'config/config';
import { PATHS } from 'config/paths';
import packageJson from '../../package.json';

// Number of miliseconds to wait for error to be handled
const ERROR_HANDLE_TIMEOUT = 4000;

function fallbackErrorHandle(error, path) {
    // Throw an error in hope that it will end up in ErrorBoundary.
    // If error is not handled in some time, redirect to error page.

    setTimeout(() => {
        if (!error.handled) {
            if (
                window.location.href.replace(/^[a-z]+:\/\/[^/]*/i, '') !== path
            ) {
                if (window.goTo && window.goTo.initialized) {
                    window.goTo(path);
                } else {
                    window.location = path;
                }
            }
        }
    }, ERROR_HANDLE_TIMEOUT);
}

const endpointUri = config.apiGateway.url;
const queryNamesRegExp = /query\s+[^\s({]+/gi;
const mutationNamesRegExp = /mutation\s+[^\s({]+/gi;
// These are necessary, because not all browsers support
// lookbehind groups in regular expressions: /(?<=query\s+)[^\s({]+/gi
// and polyfilling these is not practical
const removeQueryNamePrefix = n => n.replace(/^query\s+/i, '');
const removeMutationNamePrefix = n => n.replace(/^mutation\s+/i, '');

const constructOptions = (requestOrRequests, options) => {
    const { query } = requestOrRequests;
    // Extract query and mutation names from request queries.
    // For now assume that requests are not in bulk (arrays)
    const queryNames = (query.match(queryNamesRegExp) || []).map(
        removeQueryNamePrefix
    );
    const mutationNames = (query.match(mutationNamesRegExp) || []).map(
        removeMutationNamePrefix
    );
    // Pass names as (illegal) fetch options for customFetch
    return {
        queryNames,
        mutationNames,
        ...constructDefaultOptions(requestOrRequests, options),
    };
};

const customFetch = async (_, { queryNames, mutationNames, ...options }) => {
    try {
        // Include query and mutation names in URLs for better
        // debuggability, error reporting, logs and general bliss
        return await fetch(
            [
                endpointUri,
                '?q=',
                queryNames.join(','),
                '&m=',
                mutationNames.join(','),
            ].join(''),
            options
        );
    } catch (err) {
        const error = new Error(`GraphQL fetch failed: ${err}`);
        error.isNetworkError = true;
        fallbackErrorHandle(error, PATHS.ERROR_NETWORK);
        throw error;
    }
};

// TODO: apolloFetch should be created once (propably in App)
const apolloFetch = createApolloFetch({ constructOptions, customFetch });

apolloFetch.use(({ options }, next) => {
    /* eslint-disable no-param-reassign */
    if (!options.headers) {
        options.headers = {}; // Create the headers object if needed.
    }
    options.headers['X-Authentication'] = localStorage.getItem('token') || null;
    // npm version patch
    options.headers['X-AppVersion'] = `ReactWebApp/${packageJson.version}`;
    options.headers['X-AppEnvironment'] = process.env.REACT_APP_ENV;
    /* eslint-enable no-param-reassign */
    next();
});

const afterware = ({ response }, next) => {
    if (response.status !== 200) {
        const error = new Error(
            `Got HTTP status ${response.status} from ${response.url}.`
        );
        fallbackErrorHandle(error, PATHS.ERROR_APPLICATION);
        throw error;
    } else {
        next();
    }
};

apolloFetch.useAfter(afterware);

const GraphQLFetch = {
    runQuery: (query, variables = {}) =>
        apolloFetch({
            query: query.loc.source.body,
            variables,
        }),
    runMutation: (query, variables = {}) =>
        apolloFetch({
            query: query.loc.source.body,
            variables,
        }),
};

export default GraphQLFetch;
