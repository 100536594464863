import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SettingsProductsPlaceholder from '../Products/SettingsProductsPlaceholder';
import SettingsSubscriptionsPlaceholder from '../Subscriptions/SettingsSubscriptionsPlaceholder';

class SettingsProductsAndSubscriptionsPlaceholder extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
    };

    static defaultProps = {
        className: null,
    };

    render() {
        return (
            <article aria-live="polite" className={this.props.className}>
                <SettingsProductsPlaceholder />
                <SettingsSubscriptionsPlaceholder />
            </article>
        );
    }
}

export default SettingsProductsAndSubscriptionsPlaceholder;
