import { SALE_SOURCES_NAMESPACE as NAMESPACE } from './SaleSourcesNamespace';
import ExpiringLocalStorage from '../../ExpiringLocalStorage';

const SaleSourcesLocalStorage = new ExpiringLocalStorage(NAMESPACE);

const setLocalStorageItem = (name, { value, expire }) => {
    SaleSourcesLocalStorage.setItem(name, value, expire);
};

const removeLocalStorageItem = name => {
    SaleSourcesLocalStorage.removeItem(name);
};

const getLocalStorageItem = name => SaleSourcesLocalStorage.getItem(name);

export { setLocalStorageItem, removeLocalStorageItem, getLocalStorageItem };
