import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '../../../../../Apollo/Mutation';
import LoginViaAppleMutation from './LoginViaAppleMutation';

class LoginViaAppleConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    loginViaApple = apiLoginViaApple => async token => {
        const response = await apiLoginViaApple({
            variables: {
                token,
            },
        });

        return response;
    };

    render() {
        const { children } = this.props;
        return (
            <Mutation mutation={LoginViaAppleMutation}>
                {(loginViaApple, result) => {
                    const loginViaAppleResult = {
                        loginViaApple: this.loginViaApple(loginViaApple),
                        loading: result.loading,
                    };

                    return children(loginViaAppleResult);
                }}
            </Mutation>
        );
    }
}

export default LoginViaAppleConnectorApollo;
