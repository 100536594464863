import React from 'react';

import { Consumer } from './SaleSourcesContext';

const withSaleSources = ChildComponent => props => (
    <Consumer>
        {({ getFirstSource, getLastSource, setSource }) => (
            <ChildComponent
                {...props}
                getFirstSource={getFirstSource}
                getLastSource={getLastSource}
                setSource={setSource}
            />
        )}
    </Consumer>
);

export default withSaleSources;
