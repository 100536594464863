import { SYSTEM_OF_MEASURES, UNIT_TYPES } from '../consts';

const getUnitForType = (unitType, systemOfMeasures) => {
    if (systemOfMeasures === SYSTEM_OF_MEASURES.SI && unitType.SI) {
        return unitType.SI;
    }
    if (systemOfMeasures === SYSTEM_OF_MEASURES.IMPERIAL && unitType.IMPERIAL) {
        return unitType.IMPERIAL;
    }
    throw new Error(`System of measures ${systemOfMeasures} is not supported`);
};

const convertUnit = (sourceValue, sourceUnit, targetUnit) => {
    if (sourceUnit === targetUnit) {
        return sourceValue;
    }

    const { VOLUME } = UNIT_TYPES;
    // source: https://www.wolframalpha.com/input/?i=1+uk+oz+to+ml
    const OZ_TO_ML_RATIO = 454609 / 16000;
    if (sourceUnit === VOLUME.SI && targetUnit === VOLUME.IMPERIAL) {
        // ml → uk oz
        return sourceValue / OZ_TO_ML_RATIO;
    }

    if (sourceUnit === VOLUME.IMPERIAL && targetUnit === VOLUME.SI) {
        // uk oz → ml
        return sourceValue * OZ_TO_ML_RATIO;
    }

    throw new Error(
        `Don’t know how to convert ${sourceValue} ${sourceUnit} to ${targetUnit}`
    );
};

export { getUnitForType, convertUnit };
