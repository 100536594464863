export default () => {
    global.localStorage.removeItem('token');
    global.localStorage.removeItem('sex');
    global.localStorage.removeItem('apollo-cache-persist');
    global.localStorage.removeItem('userId');

    let domain = document.location.host.replace('app.', '');
    domain = domain.replace('dev.', '');
    domain = domain.replace('stage.', '');

    if (!domain.startsWith('.')) {
        domain = `.${domain}`;
    }

    document.cookie = `Authentication=; domain=${domain}; path=/; max-age=${60 *
        60 *
        24 *
        365 *
        -1}; secure`;
};
