import gql from 'graphql-tag';

const mutation = gql`
    mutation UserAgreementToReceiveNewsletter($agreement: Boolean!) {
        me {
            agreementToReceiveNewsletter(agreement: $agreement) {
                code
            }
        }
    }
`;

export default mutation;
