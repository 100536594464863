import gql from 'graphql-tag';

export default gql`
    mutation AuthRemindPassword($email: String!) {
        auth {
            remindPassword(email: $email) {
                ... on BasicMutationSuccess {
                    code
                }
                ... on ValidationException {
                    code
                    messages
                    details {
                        fieldName
                        messages
                    }
                }
            }
        }
    }
`;
