import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '../../../../../Apollo/Mutation';
import ResetPasswordMutation from './AuthResetPasswordMutation';

class ResetPasswordConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    createResetPassword = mutate => async request => {
        const variables = request;

        const response = await mutate({
            variables,
        });

        return response;
    };

    render() {
        const { children } = this.props;

        return (
            <Mutation mutation={ResetPasswordMutation}>
                {mutate =>
                    children({
                        resetPassword: this.createResetPassword(mutate),
                    })
                }
            </Mutation>
        );
    }
}

export default ResetPasswordConnectorApollo;
