import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Alert } from 'reactstrap';
import Loader from 'Layout/Loader';
import { PATHS } from 'config/paths';
// import { PATH_DAY_PLAN_INDEX } from 'DayPlan/DayPlanPaths';
import withMobileAppMode from 'Layout/withMobileAppMode';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';

import InputEmail from 'components/FormElements/InputEmailControlled';
import InputPassword from 'components/FormElements/InputPasswordControlled';

import { validateField } from 'view/Validation/validateField';
import { validateFields } from 'view/Validation/validateFields';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import EmailFormatRule from 'view/Validation/ValidationRules/EmailFormatRule';
import ValidationErrors from 'view/Validation/ValidationErrors';
import { withLocale } from '../../../TranslatorContext';
import setAuthCredentials from '../../setAuthCredentials';

class AuthLoginByEmailAndPasswordForm extends React.Component {
    static propTypes = {
        login: PropTypes.func.isRequired,
        redirectToPathAfterSuccess: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
        userDataOnDemand: PropTypes.func.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
        comesFromStripe: PropTypes.bool,
    };

    static defaultProps = {
        comesFromStripe: false,
    };

    state = {
        email: '',
        password: '',
        shouldRedirect: false,
        errors: new ValidationErrors(),
        success: false,
        preload: false,
        loginError: false,
    };

    validationRules = {
        email: [
            new RequiredRule({ translator: this.props.t }),
            new EmailFormatRule({ translator: this.props.t }),
        ],
        password: [new RequiredRule({ translator: this.props.t })],
    };

    handleSubmit = async event => {
        event.preventDefault();

        const frontEndErrors = validateFields(
            this.validationRules,
            this.state,
            this.props.t
        );

        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                details: frontEndErrors,
            },
        }));

        if (frontEndErrors.length === 0) {
            this.setState({ preload: true });

            const request = {
                email: this.state.email,
                password: this.state.password,
            };

            try {
                const response = await this.props.login(request);
                const { __typename, token } = response.data.auth.login;

                if (__typename === 'AuthLoginSuccess') {
                    setAuthCredentials(token);

                    await this.props.userDataOnDemand();

                    this.setState({
                        email: '',
                        password: '',
                        errors: new ValidationErrors(),
                        success: true,
                        preload: false,
                        loginError: false,
                        shouldRedirect: true,
                    });
                } else {
                    this.setState({
                        success: false,
                        preload: false,
                        loginError: true,
                    });
                }
            } catch (e) {
                this.setState({ success: false, preload: false });
                throw new Error(`Failed to login, got error: ${e}`);
            }
        }
    };

    handleInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        this.validateInput(event.target.name, event.target.value);
    };

    validateInput = (field, value) => {
        if (this.validationRules[field]) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    details: validateField(
                        field,
                        value,
                        this.validationRules[field],
                        prevState
                    ),
                },
            }));
        }
    };

    render() {
        if (
            this.state.shouldRedirect &&
            this.props.redirectToPathAfterSuccess
        ) {
            if (this.props.comesFromStripe) {
                this.props.trackFirebaseEvent('user_action', {
                    action: 'tap-stripe_login',
                    location: 'stripe_login ',
                    used_account: 'mail',
                });
            }

            return <Redirect to={this.props.redirectToPathAfterSuccess} />;
        }

        return (
            <React.Fragment>
                {this.state.loginError ? (
                    <Alert color="danger" data-test="login-error">
                        {this.props.t('auth/login/error-message')}
                    </Alert>
                ) : (
                    ''
                )}
                <Form onSubmit={this.handleSubmit} data-test="login-form">
                    <InputEmail
                        label={this.props.t('email')}
                        name="email"
                        autoComplete="username"
                        value={this.state.email}
                        errors={this.state.errors}
                        handleChange={this.handleInputChange}
                        validationRules={this.validationRules.email}
                        data-test="email-input"
                    />

                    <InputPassword
                        label={this.props.t('password')}
                        name="password"
                        autoComplete="current-password"
                        value={this.state.password}
                        errors={this.state.errors}
                        handleChange={this.handleInputChange}
                        validationRules={this.validationRules.password}
                        data-test="password-input"
                    />

                    <Button color="primary" data-test="login-button">
                        {this.props.t('auth/login/title')}
                    </Button>
                </Form>
                <br />
                <p className="small">
                    <Link
                        to={PATHS.AUTH.REMIND_PASSWORD}
                        data-test="remind-password-link"
                    >
                        {this.props.t('auth/remind-password/link-text')}
                    </Link>
                </p>
                <p className="small">{this.props.t('auth/login/agreement')}</p>
                {this.state.preload ? <Loader /> : ''}
            </React.Fragment>
        );
    }
}

export default withFirebase(
    withMobileAppMode(withLocale(AuthLoginByEmailAndPasswordForm))
);
