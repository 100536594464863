import { connect } from 'react-redux';

import googleTagManagerConfig from 'config/google-tag-manager';
import TRACKING_NAMESPACE from 'view/Tracking/TrackingNamespace';

import GoogleTagManagerComponent from './GoogleTagManagerComponent';

const mapStateToProps = state => ({
    config: googleTagManagerConfig,
    track: state[TRACKING_NAMESPACE].track,
});

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GoogleTagManagerComponent);
