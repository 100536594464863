import React, { PureComponent } from 'react';
import { func } from 'prop-types';

import { Provider } from './SaleSourcesContext';
import { FIRST, LAST } from './SaleSourcesNames';

const propTypes = {
    getSourcesValues: func.isRequired,
    setSourceValue: func.isRequired,
};

const getSourceFromReferrer = () => {
    try {
        const referrerUrl = new URL(document.referrer);

        if (referrerUrl.hostname === window.location.hostname) {
            return null;
        }

        return referrerUrl.hostname;
    } catch (error) {
        return null;
    }
};

const getSourceFromQueryString = () => {
    try {
        const search = window.location.search;
        const sourceSearchParam = search.match(/(?:^|\?|&)source=([^&]+)/)[1];
        return decodeURIComponent(sourceSearchParam.replace(/\+/g, ' '));
    } catch (error) {
        return null;
    }
};

class SaleSourcesProviderComponent extends PureComponent {
    componentDidMount() {
        let source;

        source = getSourceFromQueryString();
        if (source != null) {
            this.setSource(source);
            return;
        }

        source = getSourceFromReferrer();
        if (source != null) {
            this.setSource(source);
        }
    }

    getFirstSource() {
        return this.props.getSourcesValues()[FIRST];
    }

    getLastSource() {
        return this.props.getSourcesValues()[LAST];
    }

    setSource(newValue) {
        return this.props.setSourceValue(newValue);
    }

    render() {
        const providedValue = {
            getFirstSource: () => this.getFirstSource(),
            getLastSource: () => this.getLastSource(),
            setSource: newValue => this.setSource(newValue),
        };
        return <Provider value={providedValue}>{this.props.children}</Provider>;
    }
}

SaleSourcesProviderComponent.propTypes = propTypes;

export default SaleSourcesProviderComponent;
