import React, { Component } from 'react';
import { Container, Row, Col, Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import trainers from 'assets/images/trainers.jpg';
import Layout from 'Layout/Layout';
import { withLocale } from '@dietlabs/components';
import { withRouter } from 'react-router';

class ResetPasswordResultPage extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                result: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    };

    render() {
        return (
            <Layout page="diet">
                <section>
                    <Container>
                        <Row>
                            <Col
                                md={{ size: 6, offset: 3 }}
                                className="text-center"
                            >
                                {this.props.match.params.result ===
                                'success' ? (
                                    <Alert color="success">
                                        {this.props.t(
                                            'auth/reset-password/success-message'
                                        )}
                                    </Alert>
                                ) : (
                                    <Alert color="danger">
                                        {this.props.t(
                                            'auth/reset-password/token-is-not-valid-error-message'
                                        )}
                                    </Alert>
                                )}

                                <h2 className="my-4">
                                    {this.props.t('continue-in-mobile-app')}
                                </h2>
                                <img
                                    className="img-fluid"
                                    src={trainers}
                                    alt=""
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default withRouter(withLocale(ResetPasswordResultPage));
