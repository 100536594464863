import React from 'react';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';

const withRequired = ChildComponent => {
    class HOC extends React.Component {
        isRequired() {
            if (this.props.validationRules) {
                return this.props.validationRules.some(
                    item => item instanceof RequiredRule
                );
            }
            return false;
        }

        render() {
            return (
                <ChildComponent {...this.props} required={this.isRequired()} />
            );
        }
    }

    return HOC;
};

export default withRequired;
