export const validateField = (field, value, rules, state) => {
    const errorArray = [];

    const messagesArray = [];
    rules.forEach(rule => {
        if (!rule.isValid(value)) {
            messagesArray.push(rule.getErrorMsg());
        }
    });

    if (messagesArray.length > 0) {
        const inputError = {
            fieldName: field,
            messages: messagesArray,
        };
        errorArray.push(inputError);
    }

    let details;
    if (errorArray.length !== 0) {
        details = [...state.errors.details, ...errorArray];
    } else {
        details = state.errors.details.filter(el => el.fieldName !== field);
    }

    return details;
};
