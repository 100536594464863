import flags from './flags';
import FlagManager from './FlagManager';

const flagsManager = new FlagManager({ flags });
flagsManager.restore();

const isFlagEnabled = flag => flagsManager.isEnabled(flag);
const disableFlagsAfterError = () => flagsManager.disableAfterError();

export { isFlagEnabled, disableFlagsAfterError };
