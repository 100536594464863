import React from 'react';
import PropTypes from 'prop-types';
import setAuthCredentials from '../../setAuthCredentials';
import AuthLoginByTokenComponent from './AuthLoginByTokenComponent';

class AuthLoginByTokenContainer extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    getStateFromLocalStorage = () => {
        if (!global.localStorage) {
            return null;
        }

        const token = global.localStorage.getItem('token');
        if (!token) {
            return null;
        }

        global.localStorage.setItem('authenticatedByQueryString', false);

        return token;
    };

    getStateFromCookies = () => {
        const token = document.cookie.match(
            '(^|;) ?Authentication=([^;]*)(;|$)'
        );

        if (token) {
            setAuthCredentials(token[2]);
            return token[2];
        }

        return null;
    };

    getInitialToken = () => {
        let token = this.getStateFromLocalStorage();

        if (this.getStateFromCookies()) {
            token = this.getStateFromCookies();
        }

        return token;
    };

    render() {
        return (
            <AuthLoginByTokenComponent token={this.getInitialToken()}>
                {this.props.children}
            </AuthLoginByTokenComponent>
        );
    }
}

export default AuthLoginByTokenContainer;
