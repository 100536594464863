import React from 'react';
import { reportError } from '@dietlabs/utils';

import ErrorApplication from 'view/Error/ErrorApplication';
import { PATHS } from 'config/paths';

import AccountActivated from 'Auth/Confirm/ConfirmPage';
import AccountActivate from './Activate/AuthActivatePage';

import AuthLoginByEmailAndPasswordContainer from './Login/ByEmailAndPassword/AuthLoginByEmailAndPasswordPage';

import ByAppleTokenContainer from './Login/ByAppleToken/AuthLoginByAppleTokenComponent';
import ByFacebookTokenContainer from './Login/ByFacebookToken/AuthLoginByFacebookTokenComponent';
import ByGoogleTokenContainer from './Login/ByGoogleToken/AuthLoginByGoogleTokenComponent';

import AuthLoginByRoutePage from './Login/ByRoute/AuthLoginByRoutePage';

import AuthRemindPasswordContainer from './RemindPassword/AuthRemindPasswordPage';
import AuthResetPasswordContainer from './ResetPassword/AuthResetPasswordPage';

import Logout from './Logout/AuthLogoutPage';

import AuthSetInitialPasswordContainer from './SetInitialPassword/AuthSetInitialPasswordPage';

import RegisterByAppleTokenContainer from './Register/ByAppleToken/AuthRegisterByAppleTokenComponent';
import RegisterByGoogleTokenContainer from './Register/ByGoogleToken/AuthRegisterByGoogleTokenComponent';
import RegisterByFacebookTokenContainer from './Register/ByFacebookToken/AuthRegisterByFacebookTokenComponent';
import { RETURN_URL_ACTIVATE } from './Login/ByExternalProvider/LoginReturnUrls';

export const routes = [
    {
        path: PATHS.AUTH.LOGIN,
        component: AuthLoginByEmailAndPasswordContainer,
        private: false,
        exact: true,
        title: 'route-title/default',
    },
    {
        path: PATHS.AUTH.LOGIN_BY_TOKEN,
        component: AuthLoginByRoutePage,
        private: false,
        exact: true,
        title: 'route-title/login-by-token',
    },
    {
        path: PATHS.AUTH.LOGOUT,
        component: Logout,
        private: true,
        exact: false,
        title: 'route-title/logout',
    },
    {
        path: PATHS.AUTH.CONFIRMED,
        component: AccountActivated,
        private: false,
        exact: false,
        title: 'route-title/default',
    },
    {
        path: PATHS.AUTH.REMIND_PASSWORD,
        component: AuthRemindPasswordContainer,
        private: false,
        exact: true,
        title: 'route-title/default',
    },
    {
        path: PATHS.AUTH.RESET_PASSWORD,
        component: AuthResetPasswordContainer,
        private: false,
        exact: true,
        title: 'route-title/default',
    },
    {
        path: PATHS.AUTH.SET_PASSWORD,
        component: AuthSetInitialPasswordContainer,
        private: true,
        exact: true,
        title: 'route-title/default',
        exitPopup: false,
    },
    {
        path: PATHS.AUTH.ACTIVATE,
        component: AccountActivate,
        private: false,
        exact: true,
        title: 'route-title/default',
        exitPopup: false,
    },
    {
        path: PATHS.AUTH.FACEBOOK_LOGIN,
        render: () => {
            const token = new URL(document.location.href).searchParams.get(
                'code'
            );

            const state = new URL(document.location.href).searchParams.get(
                'state'
            );

            if (state === 'stripe_login') {
                return (
                    <ByFacebookTokenContainer
                        token={token}
                        returnPath={PATHS.BUY}
                    />
                );
            }

            return <ByFacebookTokenContainer token={token} />;
        },
        exact: true,
        private: false,
        title: 'route-title/default',
        exitPopup: false,
    },
    {
        path: PATHS.AUTH.GOOGLE_LOGIN,
        render: () => {
            const token = new URL(document.location.href).searchParams.get(
                'code'
            );

            const state = new URL(document.location.href).searchParams.get(
                'state'
            );

            if (state === 'stripe_login') {
                return (
                    <ByGoogleTokenContainer
                        token={token}
                        returnPath={PATHS.BUY}
                    />
                );
            }

            return <ByGoogleTokenContainer token={token} />;
        },
        exact: true,
        private: false,
        title: 'route-title/default',
        exitPopup: false,
    },
    {
        path: PATHS.AUTH.APPLE_LOGIN,
        render: () => {
            const idToken = new URL(document.location.href).searchParams.get(
                'id_token'
            );

            const state = new URL(document.location.href).searchParams.get(
                'state'
            );

            if (idToken) {
                if (state === 'stripe_login') {
                    return (
                        <ByGoogleTokenContainer
                            token={idToken}
                            returnPath={PATHS.BUY}
                        />
                    );
                }
                return <ByAppleTokenContainer token={idToken} />;
            }

            reportError(
                new Error(
                    `Tried to render ${PATHS.AUTH.APPLE_LOGIN} endpoint with no token given`
                )
            );
            return <ErrorApplication />;
        },
        exact: true,
        private: false,
        title: 'route-title/default',
        exitPopup: false,
    },
    {
        path: PATHS.AUTH.FACEBOOK_REGISTER,
        render: () => {
            const token = new URL(document.location.href).searchParams.get(
                'code'
            );

            const state = new URL(document.location.href).searchParams.get(
                'state'
            );

            if (state === 'stripe') {
                return (
                    <RegisterByFacebookTokenContainer
                        token={token}
                        returnPath={PATHS.BUY}
                    />
                );
            }

            return <RegisterByFacebookTokenContainer token={token} />;
        },
        exact: true,
        private: false,
        title: 'route-title/default',
        exitPopup: false,
    },
    {
        path: PATHS.AUTH.GOOGLE_REGISTER,
        render: () => {
            const token = new URL(document.location.href).searchParams.get(
                'code'
            );

            const state = new URL(document.location.href).searchParams.get(
                'state'
            );

            if (state === 'stripe') {
                return (
                    <RegisterByGoogleTokenContainer
                        token={token}
                        returnPath={PATHS.BUY}
                    />
                );
            }

            return <RegisterByGoogleTokenContainer token={token} />;
        },
        exact: true,
        private: false,
        title: 'route-title/default',
        exitPopup: false,
    },
    {
        path: PATHS.AUTH.APPLE_REGISTER,
        render: () => {
            const idToken = new URL(document.location.href).searchParams.get(
                'id_token'
            );

            const state = new URL(document.location.href).searchParams.get(
                'state'
            );

            if (idToken) {
                if (state === 'stripe') {
                    return (
                        <RegisterByAppleTokenContainer
                            token={idToken}
                            returnPath={PATHS.BUY}
                        />
                    );
                }
                return <RegisterByAppleTokenContainer token={idToken} />;
            }

            reportError(
                new Error(
                    `Tried to render ${PATHS.AUTH.APPLE_LOGIN} endpoint with no token given`
                )
            );
            return <ErrorApplication />;
        },
        exact: true,
        private: false,
        title: 'route-title/default',
        exitPopup: false,
    },
    {
        path: PATHS.AUTH.GOOGLE_ACTIVATE,
        render: () => {
            const token = new URL(document.location.href).searchParams.get(
                'code'
            );

            const state = new URL(document.location.href).searchParams.get(
                'state'
            );

            if (state === 'register' || state === 'cart_register') {
                return (
                    <RegisterByGoogleTokenContainer
                        token={token}
                        returnPath={PATHS.COUPON.INDEX}
                        returnUrl={RETURN_URL_ACTIVATE}
                    />
                );
            }

            if (state === 'stripe') {
                return (
                    <RegisterByGoogleTokenContainer
                        token={token}
                        returnPath={PATHS.BUY}
                        returnUrl={RETURN_URL_ACTIVATE}
                    />
                );
            }

            return (
                <ByGoogleTokenContainer
                    token={token}
                    returnPath={PATHS.COUPON.INDEX}
                />
            );
        },
        exact: true,
        private: false,
        title: 'route-title/default',
        exitPopup: false,
    },
    {
        path: PATHS.AUTH.FACEBOOK_ACTIVATE,
        render: () => {
            const token = new URL(document.location.href).searchParams.get(
                'code'
            );
            const state = new URL(document.location.href).searchParams.get(
                'state'
            );

            if (state === 'register' || state === 'cart_register') {
                return (
                    <RegisterByFacebookTokenContainer
                        token={token}
                        returnPath={PATHS.COUPON.INDEX}
                    />
                );
            }

            if (state === 'stripe') {
                return (
                    <RegisterByFacebookTokenContainer
                        token={token}
                        returnPath={PATHS.BUY}
                    />
                );
            }

            return (
                <ByFacebookTokenContainer
                    token={token}
                    returnPath={PATHS.COUPON.INDEX}
                />
            );
        },
        exact: true,
        private: false,
        title: 'route-title/default',
        exitPopup: false,
    },
    {
        path: PATHS.AUTH.APPLE_ACTIVATE,
        render: () => {
            const token = new URL(document.location.href).searchParams.get(
                'id_token'
            );

            const state = new URL(document.location.href).searchParams.get(
                'state'
            );

            if (state === 'register' || state === 'cart_register') {
                return (
                    <RegisterByAppleTokenContainer
                        token={token}
                        returnPath={PATHS.COUPON.INDEX}
                    />
                );
            }

            if (state === 'stripe') {
                return (
                    <RegisterByAppleTokenContainer
                        token={token}
                        returnPath={PATHS.BUY}
                    />
                );
            }

            return (
                <ByAppleTokenContainer
                    token={token}
                    returnPath={PATHS.COUPON.INDEX}
                />
            );
        },
        exact: true,
        private: false,
        title: 'route-title/default',
        exitPopup: false,
    },
    {
        path: '/login',
        redirectTo: PATHS.AUTH.LOGIN,
        exact: true,
    },
    {
        path: '/remember-password',
        redirectTo: PATHS.AUTH.REMIND_PASSWORD,
        exact: true,
    },
];
