import React, { Component } from 'react';

class HydrationDayGoalHeader extends Component {
    render() {
        return (
            <p className="goal placeholder">
                <strong>&nbsp;</strong>&nbsp;
            </p>
        );
    }
}

export default HydrationDayGoalHeader;
