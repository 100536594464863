import Price from 'Price/Price';

export default class SaleProduct {
    constructor(
        id,
        name,
        thumbnailUrl,
        productCardTitle,
        isBestseller,
        shortDescription,
        longDescription,
        lengthLabel,
        regularPrice,
        promoPrice,
        diffPrice
    ) {
        this.id = id;
        this.name = name;
        this.thumbnailUrl = thumbnailUrl;
        this.productCardTitle = productCardTitle;
        this.isBestseller = isBestseller;
        this.shortDescription = shortDescription;
        this.longDescription = longDescription;
        this.lengthLabel = lengthLabel;
        this.regularPrice = regularPrice;
        // eslint-disable-next-line no-underscore-dangle
        this._promoPrice = promoPrice || null;
        // eslint-disable-next-line no-underscore-dangle
        this._diffPrice = diffPrice || null;
    }

    get price() {
        // eslint-disable-next-line no-underscore-dangle
        return this._promoPrice || this.regularPrice;
    }

    get diffPrice() {
        return new Price(
            // eslint-disable-next-line no-underscore-dangle
            this.regularPrice.amount - this._promoPrice.amount,
            this.regularPrice.currency
        );
    }

    get hasActivePromo() {
        // eslint-disable-next-line no-underscore-dangle
        return !!this._promoPrice;
    }
}
