const LOGGING_ENABLED =
    process.env.REACT_APP_ENV !== 'prod' && process.env.NODE_ENV !== 'test';

const consoleAction = (action, ...argv) => {
    if (!LOGGING_ENABLED) {
        return;
    }

    let chosenAction = action;

    // eslint-disable-next-line no-console
    if (!console[chosenAction]) {
        chosenAction = 'log';
    }
    // eslint-disable-next-line no-console
    console[chosenAction](...argv);
};

const log = (...argv) => consoleAction('log', ...argv);
const info = (...argv) => consoleAction('info', ...argv);
const warn = (...argv) => consoleAction('warn', ...argv);
const error = (...argv) => consoleAction('error', ...argv);

export { log, info, warn, error };

export default {
    log,
    info,
    warn,
    error,
};
