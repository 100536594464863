export default {
    'meal/exchange-section-title': 'You can change for',
    menu: 'Menu',
    'menu/home': 'Home',
    'menu/diet': 'Diet',
    'menu/achievements': 'Achievements',
    'menu/diet-plan': 'Diet plan',
    'menu/trainings': 'Workouts',
    'menu/mindfulness': 'Balance',
    'menu/shop': 'Shop',
    'menu/knowledge': 'Feed',
    'menu/blog': 'Blog',
    'menu/contact': 'Contact',
    'menu/activities': 'Activities',
    'menu/shopping-list': 'Shopping list',
    'menu/measurements': 'Measurements',
    'menu/diet/settings': 'Diet settings',
    'menu/logout': 'Log out',
    'menu/login': 'Log in',
    'menu/settings': 'Settings',
    'menu/account': 'Account',
    logout: 'Logout',
    save: 'Save',
    cancel: 'Cancel',
    delete: 'Delete',
    next: 'Next',
    back: 'Back',
    print: 'Print',
    ok: 'Ok',
    add: 'Add',
    close: 'close',
    buy: 'Buy',
    edit: 'Edit',
    bestseller: 'bestseller',
    'continue-in-mobile-app': 'Continue in the MAXXnation app.',
    'data-saved': 'Data saved',
    'error/message/generic': 'Something went wrong',
    'error/message/network': 'Network error',
    'error/message/offline': 'You are offline',
    'error/message/no-account': 'Don’t have an account yet?',
    'error/additional-message/generic':
        'Incident has been reported and we will work to solve it.',
    'error/additional-message/not-found': 'Page does not exist.',
    'error/additional-message/user-not-found':
        'We didn’t find your account among registered users.',
    'button/refresh': 'Refresh',
    'button/try-again': 'Try again',
    'button/go-back': 'Back',
    'button/get-access': 'Get access',
    'button/return': 'Return',
    'button/send-email': 'Send email',

    week: 'week',
    week_plural: '{{count}} weeks',
    month: 'month',
    month_plural: '{{count}} months',
    year: 'year',
    year_plural: '{{count}} years',

    'diet-intro/title': 'Hello,\nCongratulations!',
    'diet-intro/p0':
        'You have taken the first step and you are getting closer to reaching your goals.\n \nThis nutrition regiment will be balanced and individually tailored to have you seeing results, and achieve overall health and wellness',

    'diet-intro/start-diet': 'Start diet',

    'diet-intro/p1/title': 'Eat regularly',
    'diet-intro/p1/content':
        'Your day will include 4 balanced meals. I recommend eatting breakfast within one hour of waking up, and eating your next meals every 3-4 hours and dinner no later than 2-3 hours before going to sleep!\n \nAvoid skipping any meals - every meal is important. If you remember these simple tips, you will easily adjust your diet to the rhythm of your day.',

    'diet-intro/p2/title': 'Drink plenty of water',
    'diet-intro/p2/content':
        'In your diet plan you will find beverage suggestions which you can drink throughout the day. You should drink around 1,5-2 liters of fluids a day, mainly as water and herbal teas. Drink little sips of water between meals. Remember about an extra portion of water in case  of higher activity level or in hot weather.',

    'diet-intro/p3/title': 'Personalize your meals',
    'diet-intro/p3/content':
        'If you aren’t a fan of the meals for a particular day, personalize it for another one by choosing from a list of my suggestions. All the meals you change are balanced, therefore all your individual nutrition requirements stay the same.',

    'diet-intro/p4/title': 'Meal preparation for 2 days',
    'diet-intro/p4/content':
        'In the diet the meals will be arranged so that dinner will be the same for two days. You can save time by preparing a meal for two days in one day. To do this, double the amount of ingredient and half the portions once it’s cooked.',

    'diet-intro/p5/title':
        'Match the order of meals to the rhythm of your day!',
    'diet-intro/p5/content':
        'You can plan your meals in advance, prepare them earlier and pack for work. Match the order of meals to the rhythm of your day - for example, you can eat your dinner at the time of your snack.',

    'diet-intro/p6/title': 'Change the ingredients',
    'diet-intro/p6/content':
        'If you have a problem finding some of the ingredients, you can easily change them by choosing from the list of substitutes prepared by our dietitians. All the substitutes have exactly the same weight and nutrient content. You will find a list of substitutes for each product here:',

    'diet-intro/p7/title': 'Use the grocery list',
    'diet-intro/p7/content':
        'You have a feature to create a shopping list for the days you choose in your account’s calendar. All you need to do is select the days that you’re ready to shop for.',

    'diet-intro/p8/title': 'Take the measurements and track your progress',
    'diet-intro/p8/content':
        'Click below to save our measurements. We want to celebrate your progress, and every win with you.',

    'diet-intro/p9/title': 'Prioritize health and wellness over just diet',
    'diet-intro/p9/content':
        'Remember that a healthy nutrition regiment is 70% of your success in achieving a healthy body and better well-being. The remaining 30% your workouts. Your programs will be customised to your personal needs and goals, whether it’s beginning your fitness journey or to further tone your body. You can do the workouts with your app at home, without the need for additional equipment or an active gym membership.',

    'diet-intro/additional-question': 'Do you have any additional questions?',
    'diet-intro/p10':
        'If you have any doubts about the diet, get in touch with my dietician via the contact form.',

    'diet-intro/sign': 'Let’s do this,\nJo ',

    'meal/preparation-time': 'Preparation time',
    'diet/meal/seasoning/header': 'Seasonings',
    'diet/meal/optionalIngredients/header': 'You can also add',
    'auth/confirm/header': 'Thank you',
    'auth/confirm/subheader': 'Your account has been activated.',
    'auth/confirm/content': 'Now you can start using the MAXXnation app.',
    'auth/confirm/mobileApps':
        "Don't have the app on your mobile? Download it now!",
    'meal/min': 'min.',
    'meal/how-to-prepare': 'How to prepare',
    'meal/proteins-short': 'P',
    'meal/fat-short': 'F',
    'meal/carbohydrates-short': 'C',
    'meal/proteins': 'Proteins',
    'meal/fat': 'Fats',
    'meal/carbohydrates': 'Carbs',
    'meal/weekday0': 'Sunday',
    'meal/weekday1': 'Monday',
    'meal/weekday2': 'Tuesday',
    'meal/weekday3': 'Wednesday',
    'meal/weekday4': 'Thursday',
    'meal/weekday5': 'Friday',
    'meal/weekday6': 'Saturday',
    'meal/weekday0-short': 'Sun',
    'meal/weekday1-short': 'Mon',
    'meal/weekday2-short': 'Tue',
    'meal/weekday3-short': 'Wed',
    'meal/weekday4-short': 'Thu',
    'meal/weekday5-short': 'Fri',
    'meal/weekday6-short': 'Sat',
    'meal/exchange-meal-button': 'Change',
    'meal/exchange-dish-button': 'Change',
    'meal/exchange-collapse': 'Cancel',
    'meal/exchange-day-button': 'Change day',
    'meal/exchange-ingredient': 'Change ingredient',
    'meal/exchange-for': 'You can change for',
    'meal/original-product': 'Original product',
    'meal/original-meal': 'Original meal',
    'meal/original-day': 'Original menu',
    'meal/summary-title': 'Calorific distribution',
    'meal/print-button': 'Print',
    'meal/print-period-alert': 'Printing available for less than 2 weeks.',
    'meal/show-menus-contain': 'Show menus containing:',
    'meal/menus-contain': 'contains:',
    'meal/search': 'Search',
    'meal/name-of-dish-or-ingredient': 'the name of the dish or ingredient',
    'meal/there-are-no-replacements': 'There are no suitable replacements.',
    'meal/supports': 'Supports:',
    'meal/copy-day-button': 'Copy for tomorrow',
    'meal/copy-day-button-done': 'Copied',
    'next-day-notification': 'You have to prepare something for tomorrow:',
    'holiday-notification': 'Show <u>a special menu</u> for this day.',

    'dish/portion/you-should-have-this-dish-last':
        'You have prepared this meal recently. If there’s anything left, it will not be included in your diet plan.',
    'dish/portion/you-should-have-this-dish':
        'You have prepared this meal recently.',
    'dish/portion/rest': 'The rest will not be used in you diet plan.',

    'dish/portion/eat-x-of': 'Eat one serving of {{total}}.',
    'dish/portion/eat-x-of_plural':
        'Eat {{fraction}}&nbsp;servings of {{total}}.',

    'dish/portion/eat-x-portion': 'Eat one serving.',
    'dish/portion/eat-x-portion_plural': 'Eat {{fraction}}&nbsp;servings.',

    'dish/portion/x-portion-of': ' - one serving of {{total}}',
    'dish/portion/x-portion-of_plural':
        ' - {{fraction}}&nbsp;servings of {{total}}',

    'recipe-for-x-portion': 'Recipe for one serving',
    'recipe-for-x-portion_plural': 'Recipe for {{count}} serving',

    'dish/portion/dish-used-in-following-days':
        'This dish will be used in the following days. The recipe is for one serving. ',
    'dish/portion/dish-used-in-following-days_plural':
        'This dish will be used in the following days. The recipe is for {{count}} servings.',

    'shopping-list/title': 'Shopping list',
    'shopping-list/export-to-frisco': 'Export to Frisco.pl',
    'shopping-list/date-range': 'Date range',
    'shopping-list/show-list-button': 'Show list',
    'shopping-list/alert/no-full-covered-by-diet-in-period':
        'You do not have a diet for the entire date range. The shopping list contains products only from the days on which the diet was arranged.',
    'shopping-list/alert/no-diet-in-period': 'No diet in the given date range.',
    'shopping-list/bought': 'Bought',
    'shopping-list/no-products': 'No products',

    'measurments/title': 'Measurements',
    'measurments/current-measurments-title': 'Last measurements',
    'measurments/history': 'Measurements history',
    'measurments/edit-measurments-title': 'Edit measurements',
    'measurments/add-measurments-button': 'Add measurements',
    'measurments/delete-measurment-button': 'Delete measurement',
    'measurments/date': 'Date',
    'measurments/alert-no-measurments-in-period':
        'No measurements of a given type in a given period of time',
    'measurments/param/body-weight': 'Body weight',
    'measurments/param/weight': 'Weight',
    'measurments/param/hips': 'Hips',
    'measurments/param/hip': 'Hip',
    'measurments/param/waist': 'Belly',
    'measurments/param/thighs': 'Thigh',
    'measurments/param/thigh': 'Left thigh',
    'measurments/param/rightthigh': 'Right thigh',
    'measurments/param/chest': 'Chest',
    'measurments/param/arms': 'Biceps',
    'measurments/param/arm': 'Left biceps',
    'measurments/param/rightarm': 'Right biceps',
    'measurments/state/no-measurement': 'no measurement',
    'measurments/state/change': 'change',
    'measurments/state/no-change': 'no change',
    'no-data': '-',
    'no-change': 'no change',
    'redirect/diet-settings/title': 'Diet plan',
    'redirect/diet-settings/message': 'Start the diet to see the diet plan.',
    'redirect/shopping-list/message':
        'Start the diet to see the shopping list.',
    'redirect/diet-settings/button': 'Start the diet',

    'diet-settings/title': 'Diet settings',
    'diet-settings/sex': 'Sex',
    'diet-settings/sex/male': 'Male',
    'diet-settings/sex/female': 'Female',
    'diet-settings/name': 'Name',
    'diet-settings/current-body-weight': 'Current body weight',
    'diet-settings/height': 'Height',
    'diet-settings/birth-date': 'Date of birth',
    'diet-settings/your-goal': 'Your goal',
    'diet-settings/goal/slimming': 'Lose weight',
    'diet-settings/goal/stabilization': 'Maintaining body mass',
    'diet-settings/goal/put-on-weight': 'Increase my weight',
    'diet-settings/goal/gain-muscle-mass': 'Build muscle mass',
    'diet-settings/goal-weight': 'Target body weight',
    'diet-settings/triangle/intro':
        'Choose the areas that your diet will support',
    'diet-settings/triangle/body': 'Body',
    'diet-settings/triangle/body/desc':
        'a healthy body is physically fit and resistant to harmful external factors. If you need dietary support in this area, select this option.',
    'diet-settings/triangle/mind': 'Mind',
    'diet-settings/triangle/mind/desc':
        "If your brain must operate at full capacity every day and you can't afford problems with concentration, then this is the right option for you.",
    'diet-settings/triangle/libido': 'Libido',
    'diet-settings/triangle/libido/desc':
        'If you select this area, I will provide your diet with products and spices that have been considered aphrodisiacs for centuries, and are also rich in ingredients that indirectly affect your body, improving its entire blood supply.',
    'diet-settings/diet-type/question': 'Diet mode',

    'diet-settings/diet-type/classic/description':
        'Diet excludes wheat and its derivatives as well as cow’s milk. The protein source is mainly meat, fish, dairy products and eggs.',

    'diet-settings/diet-type/vege/description':
        'Diet excludes meat, fish, seafood, wheat and its derivatives as well as cow’s milk. However, it includes cheese, fermented milk products, eggs and legume seeds.',
    'diet-settings/activity/question':
        'How much do you exercise on average per week?',
    'diet-settings/activity/none':
        'I exercise occasionally, or not at all - an office job',
    'diet-settings/activity/low': 'I exercise 1-3 hours a week',
    'diet-settings/activity/medium':
        'I exercise 3-5 hours a week (strength exercises)',
    'diet-settings/activity/high':
        'I exercise 3-5 hours a week (aerobic exercises, running, spinning)',
    'diet-settings/final/text':
        'What should you consider when starting or changing your diet plan?',
    'diet-settings/final/list/1':
        'arrange the time for shopping for the products you need for the diet',
    'diet-settings/final/list/2':
        'if you are planning a trip that will prevent you from complying with the menu, postpone the start of the diet to a more convenient time.',
    'diet-settings/final/list/3':
        'do not delay the start of the diet - if you have already taken the first step, go ahead',
    'diet-settings/final/list/4': 'you have 15 days to start the diet',
    'diet-settings/final/start-date-question':
        'When do you want to start the diet?',
    'recommended-on-psychotest': '(recommended on the basis of the test)',

    'diet-settings/welcome': 'Welcome',
    'diet-settings/welcome-message':
        'Please answer a few questions to help us prepare a diet plan that is just right for you.',
    'diet-settings/cta': 'Begin',
    'diet-settings/sex/label': 'Gender',
    'diet-settings/goal/label': 'What is your goal?',
    'diet-settings/diet-id/label': 'Choose your diet type',
    'diet-settings/diet-type/classic': 'Classic',
    'diet-settings/diet-type/vege': 'Vege',
    'diet-settings/activity-level/label':
        'How much do you exercise on average per week?',
    'diet-settings/activity-level/none/title': 'Occasionally or not at all',
    'diet-settings/activity-level/none/description': 'An office job',
    'diet-settings/activity-level/low/title': '1-3 hours a week',
    'diet-settings/activity-level/low/description':
        'Moderate physical activity',
    'diet-settings/activity-level/medium/title': '3-5 hours a week',
    'diet-settings/activity-level/medium/description': 'Strength exercises',
    'diet-settings/activity-level/high/title': '3-5 hours a week',
    'diet-settings/activity-level/high/description':
        'Aerobic exercises, running, spinning',
    'diet-settings/advice':
        'Remember to plan your shopping time before starting your diet.',
    'diet-settings/finish': 'We are preparing your diet plan',
    'diet-settings/please-wait': 'Please wait...',

    'settings/title': 'Settings',
    'settings/profile': 'User profile',
    'settings/email': 'E-mail',
    'settings/user-data': 'User data',
    'settings/name': 'Name',
    'settings/gender': 'Gender',
    'settings/date-of-birth': 'Date of birth',
    'settings/height': 'Height',
    'settings/user-goal': 'Goal',
    'settings/weight': 'Current body weight',
    'settings/goal-weight': 'Target body weight',
    'settings/diet-mode': 'Your goal',
    'settings/metric-system': 'Metric system',
    'settings/si': 'SI system',
    'settings/imperial': 'Imperial system',
    'settings/notifications': 'Notifications',
    'settings/agreements/diet-course': 'The course of the diet',
    'settings/agreements/motivation-and-articles': 'Motivation and articles',
    'settings/agreements/articles': 'Motivation and articles',
    'settings/agreements/newsletter': 'Newsletter',
    'settings/agreements/newsletter-info':
        'Unsubscribing may take up to two hours.',
    'settings/change-password': 'Change password',
    'settings/change-password/current-password': 'Current password',
    'settings/change-password/new-password': 'New password',
    'settings/change-password/success': 'Password was changed',
    'settings/products/access/end-date {date}': 'Access paid until {date}.',
    'settings/products/access/past-end-date {date}': 'Access expired {date}.',
    'settings/products/access/from-subscription':
        'Access due to a subscription.',
    'settings/products/access/lifetime': 'Livetime access.',
    'settings/products/access/none': 'None.',
    'settings/products/diet/heading': 'Diet',
    'settings/products/heading': 'Active products',
    'settings/products/video-workouts/heading': 'Trainings',
    'settings/subscriptions/cancellation-instructions':
        'Cancellation instructions',
    'settings/subscriptions/heading': 'Active subscriptions',
    'settings/subscriptions/diet/heading': 'Diet',
    'settings/subscriptions/video-workouts/heading': 'Trainings',
    'settings/subscriptions/diet+video-workouts/heading': 'Diet and trainings',
    'settings/subscriptions/none': 'None.',
    'settings/subscriptions/renewal-date {date}': 'Next renewal: {date}.',
    'settings/subscriptions/renewal-date/in-past':
        'Renewal is being processed.',
    'settings/subscriptions/type/diet+video-workouts':
        'Access to diet and trainings.',
    'settings/subscriptions/type/diets': 'Access to diet.',
    'settings/subscriptions/type/video-workouts': 'Access to trainings.',
    'settings/hydration': 'Hydration',
    'settings/hydration/daily-goal': 'Daily goal',
    'settings/remove-account': 'Account deletion',

    'remove-account/title': 'Account deletion',
    'remove-account/app-store/disclaimer':
        '<b>Warning! Deleting an account does not cancel the subscription.</b> You can manage active subscriptions in your Apple ID account settings on your iPhone.',
    'remove-account/google-play/disclaimer':
        '<b>Warning! Deleting an account does not cancel the subscription.</b> You can manage active subscriptions in the settings of the Google Play app.',
    'remove-account/stripe/disclaimer':
        'Deleting your account will cancel your subscription.',
    'remove-account/email-confirm-info':
        'The account deletion process requires verification of your account. For this purpose, we will send you an e-mail to the following address: {{userEmail}}',
    'remove-account/message-sent': 'Message sent',
    'remove-account/link-expire-info': 'The link will expire after 24 hours.',
    'remove-account/check-your-mailbox':
        'Check your e-mail. Click the link in the message we have sent you. <br /> <b>Your account will not be deleted until you do so.</b>',
    'remove-account/confirmation':
        'I confirm, I want to delete my account from the application.',
    'remove-account/info-title': 'After deleting your account:',
    'remove-account/info_1':
        'you will not log in to your account, thus you will lose access to the application',
    'remove-account/info_2':
        'you will lose all your progress, history, rewards etc. regarding training, diet and other activities in the app',
    'remove-account/info_3': 'your account data will be irretrievably deleted',
    'remove-account/error': 'Error occured',
    'remove-account/try-in-moment': 'Please try again in a moment.',
    'remove-account/link-expired': 'The link has expired.',
    'remove-account/link-expired-info':
        'Redo the account deletion process in the application, to receive a new e-mail.',
    'remove-account/success': 'Your account has been deleted',
    'remove-account/success-info':
        'In a moment you will be automatically logged out of the application.',

    'footer/service-access': 'Service access and payments',
    'footer/tos': 'Terms of service',
    'footer/privacy-policy': 'Privacy policy',
    'footer/contact': 'Contact',
    'footer/secure-payments': 'Secure payments guaranteed by',
    'button/buy-diet': 'Buy diet plan',
    'button/buy-subscribtion': 'Buy a subscription',
    'button/buy': 'Buy',
    'button/remove-account': 'Delete the account',
    'button/retry': 'Retry',

    'error/unauthorized/subscription/expired/title':
        'Subscription expired\n{{accessTo}}',
    'error/unauthorized/subscription/expired/content':
        'To continue using the application, buy a subscription.',
    'error/unauthorized/subscription/never-had/title':
        'Start your transformation today!',
    'error/unauthorized/subscription/never-had/content':
        "You're so close to starting your diet! The decision is yours, but remember - the sooner you start, the faster you see the results!",
    'error/unauthorized/subscription/refresh': 'Try again',
    'error/unauthorized/subscription/restore-purchuases': 'Restore purchases',
    'info-pages/goal-reached/title': 'You made it!\nYou achieved your goal',
    'info-pages/goal-reached/content':
        'Now is the time to decide what you want\nto do next.',
    'info-pages/goal-reached/button': 'Adjust the diet plan',
    'info-pages/goal-lost/title':
        "You were brave, let's get you closer to your goal!",
    'info-pages/goal-lost/content':
        'Do you want to continue and make progress? Proceed to weight loss diet.',
    'info-pages/goal-lost/button': 'Change the diet plan',
    'info-pages/current-weight-needed/title': 'Add current measurements',
    'info-pages/current-weight-needed/content':
        'To prepare a diet plan for the next days, we need your current measurements.',
    'info-pages/current-weight-needed/button': 'Add current measurements',
    'info-pages/diet-available-in-future/title':
        'Your diet will be available in {{count}} days',
    'info-pages/diet-available-in-future/title_plural':
        'Your diet will be available in {{count}} days',
    'info-pages/diet-available-in-future/content':
        '  The next part of your diet plan will be ready in 7 days at the earliest. Remember to enter your measurements in the meantime!',
    'info-pages/diet-outside-access/title':
        'Your subscription ends in one day, at {{date}}',
    'info-pages/diet-outside-access/title_plural':
        'Your subscription ends in {{count}} days, at {{date}}',
    'info-pages/diet-outside-access/web-title':
        'Your subscription ends in one day, at {{date}}',
    'info-pages/diet-outside-access/web-title_plural':
        'Your subscription ends in {{count}} days, at {{date}}',
    'info-pages/diet-outside-access/unknown-number': 'unknown number of',
    'contact/title': 'Contact',
    'contact/faq': 'Frequently Asked Questions',
    'contact/contact-form': 'Contact form',
    'contact/name-surname': 'Name and surname',
    'contact/email-address': 'Your e-mail address',
    'contact/topic': 'Subject',
    'contact/topic/topic1': 'Purchase error',
    'contact/topic/topic2': 'Question to the trainer',
    'contact/topic/topic3': 'Complaints and returns',
    'contact/topic/topic4': 'Cooperation',
    'contact/message': 'Message',
    'contact/send-message': 'Send a message',
    'contact/send-success': 'Message was sent. Thank you.',
    'knowledge/title': 'Feed',
    'knowledge/show': 'Show:',
    'knowledge/all': 'All',
    'knowledge/workouts': 'Workouts',
    'knowledge/motivation': 'Motivation',
    'knowledge/tips': 'Tips',
    'knowledge/back-to-article-list': 'Back to all articles',
    'knowledge/no-results': 'There are no results.',
    'knowledge/load-more': 'Load more',
    'me/account/activate/message': 'Your account has been activated.',
    'make-decision/title': 'Make a decision',
    'make-decision/start': 'Start',
    'make-decision/now': 'Now',
    'make-decision/goal': 'Goal',
    'make-decision/left': 'Left',
    'make-decision/put-on-weight': 'Put on weight',
    'make-decision/gain-muscle-mass': 'Gain muscle',
    'make-decision/weight-loose': 'Weight loss',
    'make-decision/stabilization': 'Stabilization',
    'make-decision/continue-slimming':
        'Do you want to lose a few more kilos? Enter your new target weight.',
    'make-decision/change-diet-mode-from-slimming-to-stabilization':
        "Looks like you've managed to achieve your dream weight. If you want to maintain it, it's time for stabilization phase. When do you want to start it?",
    'make-decision/continue-stabilization':
        'If you want to continue the stabilizing diet despite your weight change, click on the button below. Remember that if you choose this option, your last weight measurement will be set as your current goal.',
    'make-decision/change-diet-mode-from-stabilization-to-slimming':
        'Your weight has increased therefore we recommend going to the weight loss diet, Enter your new goal weight and select the starting date.',
    'make-decision/change-diet-mode-from-stabilization-to-put-on-weight':
        'Your weight has decreased therefore we recommend going to the put on weight diet, Enter your new goal weight and select the starting date.',
    'make-decision/continue-put-on-weight':
        'If you want to keep put on weight, choose a new goal.',
    'make-decision/change-diet-mode-from-put-on-weight-to-stabilization':
        'You’ve achieved your goal weight. Select the day to begin the stabilization diet which will help you maintain your effects.',
    'make-decision/continue-gain-muscle-mass':
        'If you want to keep gain muscle mass, choose a new goal.',
    'make-decision/change-diet-mode-from-gain-muscle-mass-to-stabilization':
        'You’ve achieved your goal weight. Select the day to begin the stabilization diet which will help you maintain your effects.',

    'route-title/default': 'MAXXnation',
    'route-title/day-plan': 'Day plan',
    'route-title/diet-intro': 'Diet intro',
    'route-title/day-plan-with-date': 'Day plan',
    'route-title/diet-will-be-available-in-future':
        'Diet will be available in future',
    'route-title/current-weight-needed': 'Current weight needed',
    'route-title/goal-reached': 'Goal reached',
    'route-title/goal-lost': 'Goal lost',
    'route-title/make-decision': 'Make decision',
    'route-title/shopping-list': 'Shopping list',
    'route-title/measurements': 'Measurements',
    'route-title/measurements-add': 'Add measurements',
    'route-title/measurements-edit': 'Edit measurements',
    'route-title/diet-settings': 'Diet settings',
    'route-title/settings': 'Settings',
    'route-title/login-by-token': 'Login by Token',
    'route-title/logout': 'Logout',
    'route-title/error-404': 'Error 404',
    'route-title/unauthorized-exception': 'Unauthorized exception',
    'route-title/content': '',
    'route-title/contact': 'Contact',
    'route-title/knowledge-category': 'Feed',
    'route-title/knowledge': 'Feed',
    'route-title/knowledge-article': '',
    'route-title/diet-outside-access': 'Cannot access',
    'route-title/block-cookies': '',
    'route-title/activities': 'My activities',
    'route-title/activities-index': 'Activities',
    'route-title/activities-add': 'Add activity',
    'route-title/activities-edit': 'Edit activity',
    'route-title/promo-code': 'Discount coupon',
    'route-title/error-network': 'Network error occured!',
    'route-title/unsubscribe': 'We are sorry that you are leaving',
    'route-title/error-application': 'Application Error',
    'route-title/mindfulness': 'Balance',
    'mobile-title/knowledge': 'Feed',

    'alert/periodic-measurements-1':
        'Periodic measurements of body weight are necessary to monitor progress in achieving the goal weight.',
    'alert/periodic-measurements-2': 'Please remember to weigh today!',

    'auth/login/title': 'Log in',
    'auth/login/with-google': 'Google',
    'auth/login/with-facebook': 'Facebook',
    'auth/login/with-apple': 'Sign up with Apple',
    'auth/login/agreement':
        'By logging in, you agree to comply with the Terms of Service. Last update of  the Terms of Service was made on 18 June 2018.',
    'auth/login/back-link-text': 'Back to login',
    'auth/login/menu-link-text': 'Log in',
    'auth/login/error-message': 'Login failed.',
    'auth/login/user-exists': 'Account already exists. Please log in.',
    'auth/login/user-not-exists': 'Account does not exists. Please register.',
    'auth/login/social-media-login-header': 'Login with social media accounts',
    'auth/login/social-media-set-up':
        'Your account is already set up with social media account.',

    'auth/remind-password/title': 'Retrieve Password',
    'auth/remind-password/description':
        'Enter your e-mail and receive instructions to change your password.',
    'auth/remind-password/link-text': 'I forgot my password',
    'auth/remind-password/error-message':
        'We have no user with this e-mail address.',
    'auth/remind-password/success-message':
        'An email with instructions to change the password has been sent.',

    'auth/reset-password/title': 'Set a new password',
    'auth/reset-password/token-is-not-valid-error-message':
        'Your password reset link has expired, if you still want to reset it, use "I forgot my password" function again.',
    'auth/reset-password/error-message':
        'Password should be at least 4 characters long',
    'auth/reset-password/success-message':
        'New password has been set for your account.',

    'auth/password-is-not-set':
        "You don't have a password set yet. Click the button below to set a password.",
    'auth/set-password': 'Set password',
    'auth/set-password/title': 'Set password',

    email: 'Email',
    password: 'Password',
    'password-to-login': 'Password',
    'new-password': 'New password',
    name: 'First name',

    'form/send': 'Send',

    'validation/required': 'Value is required.',
    'validation/date-format': 'Date format is incorrect.',
    'validation/email-format': 'Email address is incorrect.',

    'activities/my-activities': 'My activities ',
    'activities/edit-activities': 'Edit activities ',
    'activities/disclaimer':
        'Remember that each change (adding or removing activity) prepares a new diet plan, different in food products used from the current plan.',
    'activities/total': 'Total: ',
    'activities/diet-plan': 'Diet plan',
    before_breakfast: 'Before breakfast',
    after_breakfast: 'After breakfast',
    before_dinner: 'Before dinner',
    after_dinner: 'After dinner',
    before_supper: 'Before supper',
    after_supper: 'After supper',
    'activities/start-diet': 'Start',
    'activities/diet-settings': 'Diet settings',
    'activities/add-activity': 'Add activity',
    'activities/add-activity-single': 'Add activity',
    'activities/add-activity-periodic': 'Add a cyclic activity',
    'activities/edit-activity': 'Edit activity',
    'activities/edit-single-activity': 'Edit activity',
    'activities/edit-periodic-activity': 'Edit all activities in cycle',
    'activities/remove-single-activity': 'Cancel activity',
    'activities/remove-periodic-activity': 'Cancel all activities in cycle',
    'activities/time-of-day': 'Time of day',
    'activities/type-of-activity': 'Type of activity',
    'activities/more': 'More',
    'activities/activity-time': 'Duration of activity',
    'activities/burned-calories': 'Energy expenditure',
    'activities/repeat-every': 'Repeat every',
    'activities/activity1': 'Running/fast walking',
    'activities/activity2': 'Workout',
    'activities/activity3': 'Cycling',
    'activities/activity4': 'Swimming',
    'activities/duration': 'Duration',
    'activities/training': 'Training',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    'promo-code/page-title': 'Enter discout coupon',
    'promo-code/input-label': 'Discount coupon',
    'promo-code/missing-value': 'Enter discout coupon',
    'promo-code/invalid-value': 'Invalid coupon',
    'promo-code/submit': 'Verify coupon',
    'promo-code/not-exists': 'Promocode is not valid',
    'promo-code/has-been-activated': 'Promocode has been activated',

    // Critical error messages use english texts as keys, because
    // translator _may_ not be available when displaying them
    'Something went wrong': 'Something went wrong',
    'Incident has been reported and we will work to solve it':
        'Incident has been reported and we will work to solve it.',
    'Go back': 'Go back',
    'Try again': 'Try again',

    'diet-faq/title': 'Questions',

    'account/activate/title': 'Account activation',
    'account/activate/error': 'Account activation failed.',

    'coupon/title': 'Activation',
    'coupon/continue': 'Continue with:',
    'coupon/register-user/title': 'Sign up',
    'coupon/access-code': 'Access code',
    'coupon/login/title': 'Log in',
    'coupon/activate/title': 'Enter your access code',
    'coupon/activated-successfully': 'The coupon has been activated',
    'coupon/activate-card/title': 'Activate the access code',
    'coupon/card-instruction': 'To use the access card:',
    'coupon/card-instruction/1': 'fill in all fields of the form',
    'coupon/card-instruction/2': 'enter the code from your card',
    'coupon/card-instruction/3':
        'if all the data is correct, click the button.',
    'coupon/send': 'Activate access',

    'agreements/terms-and-conditions/text': 'I accept the ',
    'agreements/terms-and-conditions/text2': 'Terms',
    'agreements/terms-and-conditions/text3': ' and ',
    'agreements/terms-and-conditions/text4': 'Privacy Policy',
    'agreements/terms-and-conditions/text5':
        ' and agree to the processing of my personal data',
    'agreements/processing-personal-data/text':
        'Wyrażam zgodę na przetwarzanie moich danych osobowych',
    'agreements/processing-personal-data/text-more':
        'przez DietLabs sp. z. o.o. sp. k. ul. J. Chełmońskiego 8/4 60-754 Poznań, jako administratora danych osobowych, w celu realizacji usług dostępnych w serwisie oraz w celach marketingowych, w tym na otrzymywanie na podany adres e-mail informacji handlowej wysłanej przez DietLabs sp. z. o.o. sp. k. w imieniu własnym lub na zlecenie jej partnerów biznesowych. Masz prawo wglądu do Twoich danych oraz ich poprawienia lub usunięcia.',

    'newsletter/unsubscribe/title': 'We are sorry that you are leaving',
    'newsletter/unsubscribe/success':
        'The email address has been unsubscribed from the newsletter.',
    'newsletter/unsubscribe/send': 'Unsubscribe me',
    'read-more': 'more',

    'cart/title': 'Cart',
    'cart/payment-method': 'Payment method',
    'cart/free-delivery-info':
        'Add products for {{ price }} to have free shipping.',
    'cart/delivery/heading': 'Delivery address',
    'cart/delivery/name': 'First and last name or company name',
    'cart/delivery/street': 'Street, house and flat number',
    'cart/delivery/postal-code': 'Postal code',
    'cart/delivery/city': 'City',
    'cart/delivery/phone': 'Phone number',
    'cart/delivery/country': 'Country',
    'cart/summary': 'Order summary',
    'cart/summary/products': 'Total products',
    'cart/summary/delivery': 'Delivery',
    'cart/summary/total': 'Total',
    'cart/pay': 'Pay',

    'cart/upsell/you-save': 'You save',
    'cart/upsell/add-product': 'Add to cart',
    'cart/upsell/about': 'About product',
    'cart/upsell/added': 'Added',
    'cart/next': 'Next',
    'cart/back-to-cart': 'Back to cart',
    'cart/confirm-and-order': 'Confirm and order',
    'cart/change': 'change',
    'cart/order-summary': 'Order summary',
    'cart/address-of-delivery': 'Address of delivery',
    'cart/payment': 'Payment',
    'cart/payment/error-occured':
        'Sorry, but we could not start your payment. Please try again. If the problem persists, please let us know.',
    'cart/payment-cod/error-occured':
        'Sorry, but we could not process your order. Please try again. If the problem persists, please let us know.',
    'cart/order-is-pending': 'Order has been received.',
    'cart/login-or': 'or',
    'cart/accept-condition/validation/required': 'Acceptance is required',

    'thank-you/title': "Thank you for your order — what's next?",
    'thank-you/how-to-activate-the-service': 'How to activate the service?',
    'thank-you/step1': 'Check your e-mail. You will receive a order summary.',
    'thank-you/step2a': 'We will start preparing your order for shipping.',
    'thank-you/step2b':
        'We will start preparing your order as soon as your payment gets processed.',
    'thank-you/step2c':
        'You will be informed when in separate e-mail, as soon as your order is ready for shipping.',
    'thank-you/step3':
        'In next e-mail you will receive a tracking code and an instruction how to track your shipment. Up to 91% of shipments get delivered within two workind days.',
    'thank-you/activation-code-and-instruction':
        'Activation code and instruction how to use it will be included in your order.',
    'thank-you/flyer-content1':
        'Do not set yourself on slimming as a torment, which you need to survive with pain and pain.',
    'thank-you/flyer-content2':
        'Consider dropping unnecessary kilograms as a challenge that you will joyfully meet.',
    'thank-you/flyer-content3': '- Anna Lewandowska',
    'thank-you/flyer-footer':
        'Go to {{site_url}}/aktywacja, enter the code below and activate the service.',
    'thank-you/go-to-diet-plan': 'Go to diet plan',
    'thank-you/start-configuring-your-diet': 'Start configuring your diet',
    'thank-you/start-configuring-your-diet/button': 'Configure the diet',
    'thank-you/warning':
        'ATTENTION! In order to take full advantage of the website resources, activate your account. We have sent you the activation link to the following address: {{email}}. If you have not received the e-mail please check your spam folder.',

    'loading/message': 'Loading…',
    'et-al/abbrev': 'et al.',
    'et-al/full': 'et alii',

    'payment/pending/title': 'You ordered: {{ products }}',
    'payment/pending/description':
        'You will get access to your service when we process your payment. Waiting time depends on the method of payment you have selected.',
    'payment/pending/make-payment-description':
        'If you do not pay yet, you can do it now.',
    'payment/pending/make-payment': 'Pay',

    'message/showing-cached-data': 'Showing cached data.',
    'message/showing-cached-data/network-error':
        'Failed to fetch data — shown data may be stale.',
    'message/showing-cached-data/network-error-offline':
        'No Internet connection — shown data may be stale.',

    'units/mililiter/full': 'mililiter',
    'units/mililiter/full_plural': 'mililiters',
    'units/mililiter/abbr': 'ml',

    'units/ounce/full': 'ounce',
    'units/ounce/full_plural': 'ounces',
    'units/ounce/abbr': 'oz',

    'route-title/hydration-day': 'Your hydration',
    'menu/hydration': 'Hydration',
    'menu/your-hydration': 'Your hydration',
    'hydration/add-custom': 'Add your own portion of water',
    'hydration/goal-label': 'Daily goal:',
    'hydration/goal-changer-label': 'Daily hydration target',

    'hydration/goal-header/reached':
        'Congratulations! You have reached your daily hydration goal.',
    'hydration/goal-reached-label': 'Congratulations!',
    'hydration/goal-reached1': "You've reached your daily hydration goal.",
    'hydration/goal-reached2':
        'Remember, good hydration contributes to the proper functioning of the body.',

    'hydration/goal-left/past/pre-value': 'Today you should drink',
    'hydration/goal-left/past/post-value': 'more water',
    'hydration/goal-left/present/pre-value': 'Today you should drink',
    'hydration/goal-left/present/post-value': 'more water',
    'hydration/goal-left/future/pre-value': '{{date}} you should drink',
    'hydration/goal-left/future/post-value': 'water',
    'hydration/goal-reached':
        'Congratulations! You have reached your daily hydration goal!',
    'hydration/progress/label': 'drunk',
    'hydration/remove-last-portion': 'Remove',
    'route-title/hydration-add': 'Add portion of water',
    'hydration/add-portion': 'Add portion',
    'hydration/add/heading': 'How much water did you drink?',
    'hydration/add/submit': 'Add',
    'hydration/add/cancel': 'Cancel',
    'hydration/add/custom': 'own',
    'hydration/full-glass': 'Full glass',
    'hydration/full-bottle': 'Full bottle',
    'hydration/add-global-action': 'Add a portion of water',
    'route-title/hydration-history': 'Hydration history',
    'menu/hydration-history': 'History',
    'hydration/go-to-history': 'History',
    'hydration/back-to-hydration': 'Go back',
    'hydration/recommended-value': '{{value}} (recommended)',
    'hydration/glass-value': '{{value}} (glass)',
    'hydration/glass': 'glass',
    'hydration/half-bottle-value': '{{value}} (half\xa0a\xa0bottle)',
    'hydration/bottle-value': '{{value}} (bottle)',
    'hydration/bottle': 'bottle',
    'mindfulness/title': 'Balance',

    'route-title/achievements': 'Achievements',
    'achievements/all-achievements': 'All achievements',
    'achievements/not-yet-achieved': 'Not yet achieved',

    'terms-of-service/title': 'Terms of service',
    'terms-of-service/android': 'Purchase in Google Play',
    'terms-of-service/ios': 'Purchase in App Store',
};
