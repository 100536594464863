import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '../../../../../Apollo/Mutation';
import ActivateMutation from './ActivateMutation';

class ActivateConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    createActivate = mutate => async request => {
        const variables = request;

        const response = await mutate({
            variables,
        });

        return response;
    };

    render() {
        const { children } = this.props;

        return (
            <Mutation mutation={ActivateMutation}>
                {mutate =>
                    children({
                        activateCoupon: this.createActivate(mutate),
                    })
                }
            </Mutation>
        );
    }
}

export default ActivateConnectorApollo;
