import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from './FlashMessageContext';

class StepProvider extends Component {
    state = {
        messages: [],
    };

    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    addMessage = message => {
        this.setState(prevState => ({
            messages: [...prevState.messages, message],
        }));
    };

    removeMessage = messageId => {
        this.setState(prevState => ({
            messages: prevState.messages.filter(
                stateMessage => messageId !== stateMessage.id
            ),
        }));
    };

    render() {
        const providedValue = {
            addMessage: this.addMessage,
            removeMessage: this.removeMessage,
            messages: this.state.messages,
        };
        return <Provider value={providedValue}>{this.props.children}</Provider>;
    }
}

export default StepProvider;
