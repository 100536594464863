import gql from 'graphql-tag';

export default gql`
    mutation RemoveUserRequest {
        removeAccount {
            request {
                __typename
            }
        }
    }
`;
