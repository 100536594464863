import React from 'react';
import { Redirect } from 'react-router';

import { PATHS } from 'config/paths';

import { routes as measurementRoutes } from '@dietlabs/components/src/Maxx/Measurement/routes';
import { routes as userSettingsRoutes } from '@dietlabs/components/src/Maxx/UserSettings/routes';
import { routes as authRoutes } from '@dietlabs/components/src/Auth/routes';
import { routes as newsletterUnsubscribeRoutes } from '@dietlabs/components/src/Maxx/Newsletter/routes';
import { routes as contactRoutes } from '@dietlabs/components/src/Maxx/Contact/routes';
import { routes as contentRoutes } from '@dietlabs/components/src/Maxx/Content/routes';
import { routes as achievementsRoutes } from '@dietlabs/components/src/Maxx/Achievements/routes';
import { routes as knowledgeRoutes } from '@dietlabs/components/src/Maxx/Knowledge/routes';

import { routes as hydrationRoutes } from '@dietlabs/components/src/Maxx/Hydration/routes';
import { routes as couponRoutes } from '@dietlabs/components/src/Coupon/routes';
import { routes as deleteAccountRoutes } from '@dietlabs/components/src/Maxx/UserSettings/RemoveAccount/routes';

import Error404 from 'view/Error/Error404';
import ErrorApplication from 'view/Error/ErrorApplication';
import ErrorNetwork from 'view/Error/ErrorNetwork';
import PromoCodeContainer from 'view/PromoCode/PromoCodeContainer';
import TrackingStopContainer from 'view/Tracking/Stop/TrackingStopContainer';
import AuthResetPasswordResult from 'Auth/ResetPassword/ResetPasswordResultPage';

const routes = [
    {
        path: PATHS.ERROR_404,
        component: Error404,
        private: false,
        exact: false,
        title: 'route-title/error-404',
        exitPopup: false,
    },
    {
        path: PATHS.ERROR_APPLICATION,
        component: ErrorApplication,
        private: false,
        exact: false,
        title: 'route-title/error-application',
        exitPopup: false,
    },
    {
        path: PATHS.ERROR_NETWORK,
        component: ErrorNetwork,
        private: false,
        exact: false,
        title: 'route-title/error-network',
    },
    {
        path: PATHS.PROMO_CODE.EN,
        component: PromoCodeContainer,
        private: false,
        exact: true,
        title: 'route-title/promo-code',
    },
    {
        path: PATHS.PROMO_CODE.PL,
        component: PromoCodeContainer,
        private: false,
        exact: true,
        title: 'route-title/promo-code',
    },
    {
        path: PATHS.BLOCK_COOKIES,
        component: TrackingStopContainer,
        private: false,
        exact: true,
        title: 'route-title/block-cookies',
    },
    {
        path: PATHS.REDIRECT_HELPER,
        title: 'route-title/default',
        // eslint-disable-next-line react/prop-types
        render: props => <Redirect to={props.location.state.redirectTo} />,
    },
    {
        path: PATHS.AUTH.RESET_PASSWORD_RESULT,
        component: AuthResetPasswordResult,
        private: false,
        exact: true,
        title: 'route-title/default',
    },
    ...measurementRoutes,
    ...userSettingsRoutes,
    ...authRoutes,
    ...newsletterUnsubscribeRoutes,
    ...contactRoutes,
    ...contentRoutes,
    ...couponRoutes,
    ...achievementsRoutes,
    ...knowledgeRoutes,
    ...hydrationRoutes,
    ...deleteAccountRoutes,
];

export default routes;
