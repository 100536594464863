import React from 'react';
import PropTypes from 'prop-types';

class AuthRegisterByFacebookTokenWrapperComponent extends React.Component {
    static propTypes = {
        registerViaFacebook: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.registerViaFacebook();
    }

    render() {
        return null;
    }
}

export default AuthRegisterByFacebookTokenWrapperComponent;
