import * as Sentry from '@sentry/browser';

const MOBILE_APP_MODE_LC_KEY = 'mobileAppMode';
const PROMO_CODE_LC_KEY = 'promoCode';
const SALE_SOURCES_NAMESPACE = 'saleSources';
const SALE_SOURCES_NAME_FIRST = 'first';
const SALE_SOURCES_NAME_LAST = 'last';

const SALE_SOURCE_FIRST_LC_KEY = `${SALE_SOURCES_NAMESPACE}:${SALE_SOURCES_NAME_FIRST}`;
const SALE_SOURCE_LAST_LC_KEY = `${SALE_SOURCES_NAMESPACE}:${SALE_SOURCES_NAME_LAST}`;

const REPORT_TO_CONSOLE =
    process.env.REACT_APP_ENV !== 'prod' && process.env.NODE_ENV !== 'test';

const extraGetters = {
    'localStorage.mobileAppMode': () =>
        localStorage.getItem(MOBILE_APP_MODE_LC_KEY),
    'localStorage.promoCode': () => localStorage.getItem(PROMO_CODE_LC_KEY),
    'localStorage.saleSources:first.value': () =>
        JSON.parse(localStorage.getItem(SALE_SOURCE_FIRST_LC_KEY)).value,
    'localStorage.saleSources:last.value': () =>
        JSON.parse(localStorage.getItem(SALE_SOURCE_LAST_LC_KEY)).value,
    'localStorage.exitup': () => localStorage.getItem('exitup'),
    'localStorage.shoppingCart.products': () =>
        JSON.stringify(
            JSON.parse(localStorage.getItem('shoppingCart')).products
        ),
    'localStorage.flags': () => localStorage.getItem('flags'),
};

function setupSentryScope(scope) {
    scope.setTag('referrer', document && document.referrer);

    Object.entries(extraGetters).forEach(([key, getter]) => {
        try {
            scope.setExtra(key, getter());
            // eslint-disable-next-line no-empty
        } catch (e) {}
    });
}

const reportCritical = error => {
    Sentry.withScope(scope => {
        scope.setLevel('critical');
        setupSentryScope(scope);
        Sentry.captureException(error);
    });

    if (REPORT_TO_CONSOLE && global.console) {
        if (global.console.error) {
            global.console.error(error);
        } else if (global.console.log) {
            global.console.log(error);
        }
    }
};

const reportError = error => {
    Sentry.withScope(scope => {
        setupSentryScope(scope);
        Sentry.captureException(error);
    });

    if (REPORT_TO_CONSOLE && global.console) {
        if (global.console.error) {
            global.console.error(error);
        } else if (global.console.log) {
            global.console.log(error);
        }
    }
};

const reportWarning = error => {
    Sentry.withScope(scope => {
        scope.setLevel('warning');
        setupSentryScope(scope);
        Sentry.captureException(error);
    });

    if (REPORT_TO_CONSOLE && global.console) {
        if (global.console.warn) {
            global.console.warn(error);
        } else if (global.console.log) {
            global.console.log(error);
        }
    }
};

const reportInfo = error => {
    Sentry.withScope(scope => {
        scope.setLevel('info');
        setupSentryScope(scope);
        Sentry.captureException(error);
    });

    if (REPORT_TO_CONSOLE && global.console) {
        if (global.console.info) {
            global.console.info(error);
        } else if (global.console.log) {
            global.console.log(error);
        }
    }
};

const reportDebug = error => {
    Sentry.withScope(scope => {
        scope.setLevel('debug');
        setupSentryScope(scope);
        Sentry.captureException(error);
    });

    if (REPORT_TO_CONSOLE && global.console) {
        if (global.console.log) {
            global.console.log(error);
        }
    }
};

export { reportCritical, reportError, reportWarning, reportInfo, reportDebug };
