import React from 'react';
import PropTypes from 'prop-types';
import { Container, Alert, Button } from 'reactstrap';
import Layout from 'Layout/Layout';
import { PATHS } from 'config/paths';
import { Redirect, withRouter } from 'react-router';
import Loader from 'Layout/Loader';
import { withLocale } from '../../TranslatorContext';

export class CouponActivateContainer extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                token: PropTypes.string,
            }).isRequired,
        }).isRequired,
        activateAccount: PropTypes.func.isRequired,
    };

    state = {
        error: false,
        success: false,
        preload: true,
    };

    componentDidMount = async () => {
        const token = this.props.match.params.token;

        try {
            const response = await this.props.activateAccount({ token });
            const { code } = response.data.auth.activateAccount;

            if (code === 200) {
                this.setState({
                    error: false,
                    success: true,
                    preload: false,
                });
            } else {
                this.setState({
                    error: true,
                    success: false,
                    preload: false,
                });
            }
        } catch (e) {
            this.setState({
                success: false,
                preload: false,
            });
            throw new Error(`Failed to activate account, got error: ${e}`);
        }
    };

    render() {
        if (this.state.success) {
            return <Redirect to={PATHS.AUTH.CONFIRMED} />;
        }

        return (
            <Layout page="activate">
                <Container>
                    <header className="py-4">
                        <h1 className="text-center d-none d-md-block">
                            {this.props.t('account/activate/title')}
                        </h1>
                    </header>
                    <section className="pt-0 text-center">
                        {this.state.error && (
                            <>
                                <Alert color="danger">
                                    {this.props.t('account/activate/error')}
                                </Alert>
                                <Button
                                    color="primary"
                                    onClick={() => window.location.reload()}
                                >
                                    {this.props.t('button/try-again')}
                                </Button>
                            </>
                        )}
                    </section>
                </Container>
                {this.state.preload ? <Loader /> : ''}
            </Layout>
        );
    }
}

export default withLocale(withRouter(CouponActivateContainer));
