import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';

export const TABLE_ROW_SIZE_BIG = 'size-big';
export const TABLE_ROW_SIZE_NORMAL = 'size-normale';

class TableRow extends Component {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]).isRequired,
        size: PropTypes.oneOf([TABLE_ROW_SIZE_BIG, TABLE_ROW_SIZE_NORMAL]),
        redirectToOnClick: PropTypes.string,
    };

    static defaultProps = {
        size: TABLE_ROW_SIZE_NORMAL,
        redirectToOnClick: undefined,
    };

    state = {
        redirect: false,
    };

    handleClick = () => {
        if (this.props.redirectToOnClick) {
            this.setState({ redirect: true });
        }
    };

    render() {
        const { children, size, redirectToOnClick } = this.props;
        const { redirect } = this.state;

        if (redirect) {
            return <Redirect to={redirectToOnClick} />;
        }

        return (
            <tr
                className={size === TABLE_ROW_SIZE_BIG ? 'change' : ''}
                onClick={this.handleClick}
            >
                {children}
            </tr>
        );
    }
}

export default TableRow;
