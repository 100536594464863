import PropTypes from 'prop-types';
import deepClone from 'utils/deepClone';
import { useQuery } from '@apollo/client';
import AchievementsQuery from './AchievementsQuery';

const AchievementsConnectorApollo = ({ children }) => {
    const { loading, data } = useQuery(AchievementsQuery);
    const result = {
        loading,
    };
    if (!loading) {
        result.achievements = deepClone(data.me.achievements);
    }
    return children(result);
};

AchievementsConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default AchievementsConnectorApollo;
