import gql from 'graphql-tag';

export default gql`
    query HydrationDay($date: Date!) {
        me {
            id
            hydrationDay(date: $date) {
                key
                goal {
                    value
                    unit
                }
                value {
                    value
                    unit
                }
                portions {
                    id
                    value {
                        value
                        unit
                    }
                }
            }
        }
    }
`;
