import React from 'react';
import PropTypes from 'prop-types';

class AuthRegisterByGoogleTokenWrapperComponent extends React.Component {
    static propTypes = {
        registerViaGoogle: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.registerViaGoogle();
    }

    render() {
        return null;
    }
}

export default AuthRegisterByGoogleTokenWrapperComponent;
