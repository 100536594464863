import React from 'react';
import AchievementsConnectorApollo from './Connector/Apollo/AchievementsConnectorApollo';
// eslint-disable-next-line import/extensions
import AchievementsContainer from './AchievementsContainer';

class AchievementsPage extends React.Component {
    static propTypes = {};

    render() {
        return (
            <AchievementsConnectorApollo>
                {({ ...achievementsData }) => (
                    <AchievementsContainer {...achievementsData} />
                )}
            </AchievementsConnectorApollo>
        );
    }
}

export default AchievementsPage;
