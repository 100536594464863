import gql from 'graphql-tag';

export default gql`
    mutation SendEmail($request: ContactInputType!) {
        contact(request: $request) {
            __typename
            ... on BasicMutationSuccess {
                code
            }
            ... on ValidationException {
                code
                messages
                details {
                    fieldName
                    messages
                }
            }
        }
    }
`;
