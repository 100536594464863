import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import HydrationSettingsMutation from './HydrationSettingsMutation';

const HydrationSettingsConnectorApollo = ({ children, rawQueryResult }) => {
    const [mutate, { data, loading }] = useMutation(HydrationSettingsMutation);

    const createSave = async settings => {
        const variables = { settings };
        const optimisticResponse = {
            me: {
                hydrationSettings: {
                    ...rawQueryResult.me.hydrationSettings,
                    defaultDailyGoal: {
                        ...rawQueryResult.me.hydrationSettings.defaultDailyGoal,
                        value: settings.defaultDailyGoal.value,
                    },
                },
            },
        };

        await mutate({
            variables,
            optimisticResponse,
            update: (cache, { data: response }) => {
                cache.modify({
                    id: cache.identify(rawQueryResult.me),
                    fields: {
                        hydrationSettings(existingHydrationSettings) {
                            return {
                                ...existingHydrationSettings,
                                defaultDailyGoal: {
                                    ...existingHydrationSettings.defaultDailyGoal,
                                    value:
                                        response.me.hydrationSettings
                                            .defaultDailyGoal.value,
                                },
                            };
                        },
                    },
                });
            },
        });
    };

    if (data) {
        const result = data.me.hydrationSettings;
        if (result.__typename === 'ValidationException') {
            throw result;
        }
    }

    return children(createSave, { loading });
};

HydrationSettingsConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    rawQueryResult: PropTypes.object.isRequired,
};

export { HydrationSettingsConnectorApollo };
export default HydrationSettingsConnectorApollo;
