import React, { Component } from 'react';
import { FormGroup, CustomInput } from 'reactstrap';
import PropTypes from 'prop-types';

class InputRadio extends Component {
    render() {
        return (
            <FormGroup className="border-bottom">
                <CustomInput
                    type="radio"
                    id={this.props.id}
                    name={this.props.name}
                    label={this.props.label}
                    value={this.props.value}
                    onChange={this.props.handleChange}
                    checked={this.props.checked}
                    disabled={this.props.disabled}
                />
            </FormGroup>
        );
    }
}

InputRadio.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.node,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    handleChange: PropTypes.func.isRequired,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
};

InputRadio.defaultProps = {
    label: null,
    checked: false,
    disabled: false,
};

export default InputRadio;
