import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { PATHS } from 'config/paths';
import logo from 'assets/icons/logo.svg';

class MenuLayout extends Component {
    // static propTypes = {
    //     t: PropTypes.func.isRequired,
    // };

    render() {
        return (
            <section className="main-menu">
                <Container>
                    <a className="navbar-brand" href={PATHS.EXTERNAL.HOME}>
                        <img src={logo} alt="MAXXnation" />
                    </a>
                </Container>
            </section>
        );
    }
}

export default MenuLayout;
