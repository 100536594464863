import gql from 'graphql-tag';

const query = gql`
    query Achievements {
        me {
            achievements {
                id
                groupIdentifier
                name
                description
                achievedMessage
                thumbnailUrl
                imageUrl
                isAchieved
                userAchievement {
                    createdAt
                }
                rule {
                    id
                    datasource
                }
            }
        }
    }
`;

export default query;
