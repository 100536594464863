import React from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withLoading from 'Layout/withLoading';
import UserGoalPlaceholder from './UserGoalPlaceholder';
// eslint-disable-next-line import/no-cycle
import { PATH_SETTINGS_USER_GOAL } from '../routes';
import { withLocale } from '../../../TranslatorContext';

class UserGoal extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        weight: PropTypes.shape({
            value: PropTypes.number,
            unit: PropTypes.string,
        }).isRequired,
        goalWeight: PropTypes.shape({
            value: PropTypes.number,
            unit: PropTypes.string,
        }).isRequired,
        dietMode: PropTypes.string.isRequired,
    };

    renderDietMode = () => {
        let dietMode;

        switch (this.props.dietMode) {
            case 'slimming':
                dietMode = this.props.t('diet-settings/goal/slimming');
                break;
            case 'stabilization':
                dietMode = this.props.t('diet-settings/goal/stabilization');
                break;
            case 'put_on_weight':
                dietMode = this.props.t('diet-settings/goal/put-on-weight');
                break;
            case 'gain_muscle_mass':
                dietMode = this.props.t('diet-settings/goal/gain-muscle-mass');
                break;
            default:
                dietMode = '-';
        }

        return dietMode;
    };

    render() {
        const { weight, goalWeight, t } = this.props;

        return (
            <Container>
                <Row>
                    <Col xs="12" md={{ size: 8, offset: 2 }}>
                        <h2 className="heading-3">
                            {t('settings/user-goal')}{' '}
                            <Button
                                tag={Link}
                                to={PATH_SETTINGS_USER_GOAL}
                                color="link"
                                className="float-right px-0 py-0"
                            >
                                {t('edit')}
                            </Button>
                        </h2>
                        <p className="d-flex justify-content-between">
                            {t('settings/weight')}:
                            <span>
                                {weight
                                    ? `${weight.value} ${weight.unit}`
                                    : '-'}
                            </span>
                        </p>
                        <p className="d-flex justify-content-between">
                            {t('settings/diet-mode')}:{' '}
                            <span>{this.renderDietMode()}</span>
                        </p>
                        <p className="d-flex justify-content-between">
                            {t('settings/goal-weight')}:{' '}
                            <span>
                                {goalWeight
                                    ? `${goalWeight.value} ${goalWeight.unit}`
                                    : '-'}
                            </span>
                        </p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withLoading(withLocale(UserGoal), UserGoalPlaceholder);
