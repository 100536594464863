import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withLocale } from '@dietlabs/components';

const withLoading = (Child, Placeholder, { addAccessibility = true } = {}) => {
    const childName = Child.displayName || Child.name;
    return withLocale(
        class extends Component {
            static displayName = `${childName} withLoading`;

            static propTypes = {
                t: PropTypes.func.isRequired,
                loading: PropTypes.bool.isRequired,
            };

            render() {
                if (!this.props.loading) {
                    return <Child {...this.props} />;
                }

                const placeholder = <Placeholder {...this.props} />;

                if (!addAccessibility) {
                    return placeholder;
                }

                return (
                    <div
                        aria-label={this.props.t('loading/message')}
                        aria-busy="true"
                    >
                        <div aria-hidden="true">{placeholder}</div>
                    </div>
                );
            }
        }
    );
};

export default withLoading;
