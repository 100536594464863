import React from 'react';
import { scrollToTop } from 'components/Helpers/scrollToTop';
import SettingsConnectorApollo from './Connector/Apollo/CurrentSettings/CurrentSettingsConnectorApollo';
import SaveUserGoalConnectorApollo from './Connector/Apollo/SaveUserGoal/SaveUserGoalConnectorApollo';
// eslint-disable-next-line import/no-cycle
import UserGoalEditContainer from './UserGoalEditContainer';

class UserGoalEditPage extends React.PureComponent {
    componentDidMount() {
        scrollToTop();
    }

    render() {
        return (
            <SettingsConnectorApollo>
                {result => (
                    <SaveUserGoalConnectorApollo>
                        {({ saveUserGoal }) => (
                            <UserGoalEditContainer
                                systemOfMeasures={result.systemOfMeasures}
                                weight={result.weight}
                                goalWeight={result.goalWeight}
                                dietMode={result.dietMode}
                                loading={result.loading}
                                userId={result.userId}
                                {...{ saveUserGoal }}
                            />
                        )}
                    </SaveUserGoalConnectorApollo>
                )}
            </SettingsConnectorApollo>
        );
    }
}

export default UserGoalEditPage;
