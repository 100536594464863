import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
    Alert,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
} from 'reactstrap';
import { PATHS } from 'config/paths';
import { func } from 'prop-types';
import { FlashMessageSuccess } from '@dietlabs/components/src/FlashMessage/FlashMessage';
import { withLocale } from '@dietlabs/components';
import Layout from 'Layout/Layout';
import { FLASH_MESSAGES } from 'consts';
import withFlashMessage from '@dietlabs/components/src/FlashMessage/withFlashMessage';

const propTypes = {
    setPromoCode: func.isRequired,
    getPromoCode: func.isRequired,
    isValid: func.isRequired,
    addMessage: func.isRequired,
};

class PromoCodeComponent extends Component {
    constructor(props) {
        super(props);
        this.id = `00${parseInt(Math.random() * 100, 10)}`.slice(-2);
        this.inputRef = React.createRef();
        this.state = {
            promoCode: this.props.getPromoCode() || '',
            error: null,
        };
    }

    componentDidUpdate() {
        if (this.state.error === true) {
            this.inputRef.current.focus();
        }
    }

    handleChange(event) {
        event.preventDefault();

        this.setState({ promoCode: event.target.value });
    }

    async handleSubmit(event) {
        event.preventDefault();

        const { promoCode } = this.state;

        const input = this.inputRef.current;

        if (!input.checkValidity()) {
            this.setState({ error: true });
            input.focus();
            return;
        }

        const isPromoCodeValid = await this.props.isValid(promoCode);

        if (isPromoCodeValid) {
            this.props.setPromoCode(promoCode);
            this.props.addMessage(
                new FlashMessageSuccess(
                    this.props.t('promo-code/has-been-activated'),
                    FLASH_MESSAGES.PROMO_CODE.SUCCESS
                )
            );
            this.setState({ error: false });
        } else {
            this.setState({ error: true });
        }
    }

    renderHeader() {
        return (
            <header>
                <Container>
                    <Row className="text-center">
                        <Col>
                            <h1 className="d-none d-md-block">
                                {this.props.t('promo-code/page-title')}
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </header>
        );
    }

    renderForm() {
        return (
            <Form onSubmit={event => this.handleSubmit(event)} noValidate>
                <FormGroup>
                    <Label htmlFor={this.id}>
                        {this.props.t('promo-code/input-label')}
                    </Label>
                    <div className="form-control">
                        <Input
                            innerRef={this.inputRef}
                            id={this.id}
                            required
                            onChange={event => this.handleChange(event)}
                            autoComplete="off"
                        />
                    </div>
                </FormGroup>
                <div className="text-center">
                    <Button type="submit" color="primary">
                        {this.props.t('promo-code/submit')}
                    </Button>
                </div>
            </Form>
        );
    }

    render() {
        if (this.state.error === false) {
            window.location = PATHS.EXTERNAL.HOME;
            return null;
        }

        return (
            <Layout page="promo-code">
                {this.renderHeader()}

                <section className="pt-0">
                    <Container>
                        <Row>
                            <Col xs="12" sm={{ size: 6, offset: 3 }}>
                                {this.state.error === true ? (
                                    <Alert color="danger">
                                        {this.props.t('promo-code/not-exists')}
                                    </Alert>
                                ) : (
                                    ''
                                )}
                                {this.renderForm()}
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

PromoCodeComponent.propTypes = propTypes;

export { PromoCodeComponent as unwrapped };

export default withFlashMessage(withLocale(PromoCodeComponent));
