import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProductsComponent from '../Products/SettingsProductsComponent';
import SubscriptionsComponent from '../Subscriptions/SettingsSubscriptionsComponent';

class SettingsProductsAndSubscriptionsComponent extends Component {
    static propTypes = {
        className: PropTypes.string,
        dietAccessTo: PropTypes.instanceOf(Date),
        dietLifetimeAccess: PropTypes.bool.isRequired,
        videoWorkoutAccessTo: PropTypes.instanceOf(Date),
        videoWorkoutLifetimeAccess: PropTypes.bool.isRequired,
        subscriptions: PropTypes.arrayOf(
            PropTypes.shape({
                givesAccessToDiet: PropTypes.bool.isRequired,
                givesAccessToVideoWorkouts: PropTypes.bool.isRequired,
                renewalDate: PropTypes.instanceOf(Date),
                operator: PropTypes.string,
            })
        ).isRequired,
    };

    static defaultProps = {
        className: null,
        dietAccessTo: undefined,
        videoWorkoutAccessTo: undefined,
    };

    render() {
        const {
            dietAccessTo,
            dietLifetimeAccess,
            videoWorkoutAccessTo,
            videoWorkoutLifetimeAccess,
            subscriptions,
        } = this.props;
        const dietAccessFromSubscription = subscriptions.some(
            sub => sub.givesAccessToDiet
        );
        const videoWorkoutAccessFromSubscription = subscriptions.some(
            sub => sub.givesAccessToVideoWorkouts
        );

        const productsProps = {
            dietLifetimeAccess,
            dietAccessFromSubscription,
            videoWorkoutLifetimeAccess,
            videoWorkoutAccessFromSubscription,
        };

        if (dietAccessTo !== undefined) {
            productsProps.dietAccessTo = dietAccessTo;
        }
        if (videoWorkoutAccessTo !== undefined) {
            productsProps.videoWorkoutAccessTo = videoWorkoutAccessTo;
        }

        return (
            <article>
                <ProductsComponent
                    {...productsProps}
                    className={this.props.className}
                />
                <SubscriptionsComponent
                    subscriptions={subscriptions}
                    className={this.props.className}
                />
            </article>
        );
    }
}

export { SettingsProductsAndSubscriptionsComponent };
export default SettingsProductsAndSubscriptionsComponent;
