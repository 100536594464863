import { PROMO_CODE_NAMESPACE as NAMESPACE } from './PromoCodeNamespace';
import ExpiringLocalStorage from '../../ExpiringLocalStorage';

const NAME = 'value';

const PromoCodeLocalStorage = new ExpiringLocalStorage(NAMESPACE);

const setLocalStorageItem = ({ value, expire }) => {
    PromoCodeLocalStorage.setItem(NAME, value, expire);
};

const removeLocalStorageItem = () => {
    PromoCodeLocalStorage.removeItem(NAME);
};

const getLocalStorageItem = () => PromoCodeLocalStorage.getItem(NAME);

export { setLocalStorageItem, removeLocalStorageItem, getLocalStorageItem };
