const ALL = '*';

const defaultRandom = () => Math.round(Math.random() * 100) / 100;

class FlagManager {
    flags;

    storage;

    values = {};

    constructor({
        flags,
        random = defaultRandom,
        storage = localStorage,
        storageKey = 'flags',
    }) {
        this.flags = new Map(
            Object.entries(flags).map(([name, opts]) => [
                name,
                {
                    enableProbability: 0.0,
                    disableAfterError: true,
                    ...opts,
                },
            ])
        );

        this.storage = storage;
        this.storageKey = storageKey;

        this.values = new Map(
            Array.from(this.flags.keys()).map(id => [id, random()])
        );
    }

    isIdValid(id) {
        return id === ALL || this.flags.has(id);
    }

    isEnabled(id) {
        const flag = this.flags.get(id) || {};
        return 1 - this.getValue(id) < flag.enableProbability;
    }

    /** Disable flags after error */
    disableAfterError() {
        Array.from(this.flags.entries())
            .filter(([, opts]) => opts.disableAfterError)
            .forEach(([name]) => {
                this.setValue(name, 0);
            });
    }

    /** Disable all flags */
    disableAll() {
        this.values.clear();
        this.values.set(ALL, 0);
        this.persist();
    }

    enableAll() {
        this.values.clear();
        this.values.set(ALL, 1);
        this.persist();
    }

    getValue(id) {
        if (this.values.has(id)) {
            return this.values.get(id);
        }
        if (this.values.has(ALL)) {
            return this.values.get(ALL);
        }
        return null;
    }

    setValue(id, value) {
        if (!this.isIdValid(id)) {
            return;
        }

        this.values.set(id, value);
        this.persist();
    }

    removeValue(id) {
        this.values.delete(id);
    }

    restore() {
        const storedValues = this.getValuesFromStorage();
        const newValues = new Map([
            ...(storedValues.has(ALL) ? [] : this.values),
            ...storedValues,
        ]);
        Array.from(newValues.keys()).forEach(id => {
            if (!this.isIdValid(id)) {
                newValues.delete(id);
            }
        });
        this.values = newValues;
        this.persist();
    }

    persist() {
        try {
            const objValues = Object.fromEntries(this.values);
            this.storage.setItem(this.storageKey, JSON.stringify(objValues));
        } catch (e) {
            // ignore
        }
    }

    getValuesFromStorage() {
        try {
            return new Map(
                Object.entries(
                    JSON.parse(this.storage.getItem(this.storageKey)) || {}
                )
            );
        } catch (e) {
            return new Map();
        }
    }
}

export default FlagManager;
