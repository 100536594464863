import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CachedDataMessageComponent from './CachedDataMessageComponent';

class CachedDataMessage extends PureComponent {
    static propTypes = {
        cacheHit: PropTypes.bool.isRequired,
        hasNetworkError: PropTypes.bool.isRequired,
    };

    render() {
        const { cacheHit, hasNetworkError } = this.props;
        if (!cacheHit) {
            return null;
        }

        return <CachedDataMessageComponent {...{ hasNetworkError }} />;
    }
}

export default CachedDataMessage;
