import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Label, Row } from 'reactstrap';
import { scrollToTop } from 'components/Helpers/scrollToTop';
import Layout from 'Layout/Layout';
import { withLocale } from '../../TranslatorContext';
import ExternalProviderContainer from '../../Auth/Login/ByExternalProvider/ExternalProviderContainer';
import SettingsConnectorApollo from './Connector/Apollo/CurrentSettings/CurrentSettingsConnectorApollo';
import { RETURN_URL_LOGIN } from '../../Auth/Login/ByExternalProvider/LoginReturnUrls';
import ChangeSettingsConnectorApollo from './Connector/Apollo/SystemOfMeasurements/ChangeSettingsConnectorApollo';
import UserAgreementNotifyConnector from './Connector/Apollo/Notifications/UserAgreementNotifyConnector';
import ChangePasswordConnector from './Connector/Apollo/ChangePassword/ChangePasswordConnector';
import HydrationSettingsConnector from './Connector/Apollo/Hydration/HydrationSettingsConnectorApollo';
import SystemOfMeasurementsForm from './SystemOfMeasurements/SystemOfMeasurementsForm';
import NotificationsSettings from './Notifications/NotificationsSettingsForm';
import ChangePasswordForm from './ChangePassword/ChangePasswordForm';
import Placeholder from './ProductsAndSubscriptions/SettingsProductsAndSubscriptionsPlaceholder';
import SettingsProductsAndSubscriptionsComponent from './ProductsAndSubscriptions/SettingsProductsAndSubscriptionsComponent';
import HydrationSettings from './Hydration/HydrationSettings';
import UserEmailForm from './UserEmail/UserEmailForm';
import RemoveAccountButton from './RemoveAccount/Components/RemoveAccountButton';
// eslint-disable-next-line import/no-cycle
import UserData from './UserData/UserData';
// eslint-disable-next-line import/no-cycle
import UserGoal from './UserGoal/UserGoal';

class UserSettings extends React.PureComponent {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    componentDidMount() {
        scrollToTop();
    }

    renderActiveSubscriptions = result => {
        if (result.loading) {
            return <Placeholder />;
        }
        // Filter out undefined values
        const filteredProps = Object.entries(result)
            .filter(([, v]) => v !== undefined)
            .reduce((c, [k, v]) => ({ ...c, [k]: v }), {});
        return <SettingsProductsAndSubscriptionsComponent {...filteredProps} />;
    };

    renderSocialMediaLogin = (
        isAbleToLoginWithGoogle,
        isAbleToLoginWithFacebook,
        isAbleToLoginWithApple
    ) => (
        <Container>
            <Row>
                <Col xs="12" md={{ size: 8, offset: 2 }}>
                    <h2>
                        <Label className="not-label">
                            {this.props.t(
                                'auth/login/social-media-login-header'
                            )}
                        </Label>
                    </h2>
                    <div className="text-center">
                        {isAbleToLoginWithGoogle &&
                        isAbleToLoginWithFacebook &&
                        isAbleToLoginWithApple ? (
                            this.props.t('auth/login/social-media-set-up')
                        ) : (
                            <ExternalProviderContainer
                                returnUrl={RETURN_URL_LOGIN}
                                displayGoogleButton={!isAbleToLoginWithGoogle}
                                displayFacebookButton={
                                    !isAbleToLoginWithFacebook
                                }
                                displayAppleButton={!isAbleToLoginWithApple}
                            />
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );

    render() {
        return (
            <Layout page="settings">
                <header>
                    <h1 className="text-center d-none d-md-block">
                        {this.props.t('settings/title')}
                    </h1>
                </header>
                <section className="pt-0">
                    <SettingsConnectorApollo>
                        {result => {
                            return (
                                <React.Fragment>
                                    <UserEmailForm
                                        email={result.email}
                                        loading={result.loading}
                                    />

                                    <UserData
                                        name={result.name}
                                        sex={result.sex}
                                        dateOfBirth={result.birthDate}
                                        height={result.height}
                                        loading={result.loading}
                                    />

                                    <UserGoal
                                        weight={result.weight}
                                        goalWeight={result.goalWeight}
                                        dietMode={result.dietMode}
                                        loading={result.loading}
                                    />

                                    {this.renderActiveSubscriptions(result)}

                                    <ChangeSettingsConnectorApollo
                                        rawQueryResult={result.rawData}
                                    >
                                        {systemOfMeasures => (
                                            <SystemOfMeasurementsForm
                                                setSystemOfMeasures={
                                                    systemOfMeasures
                                                }
                                                value={result.systemOfMeasures}
                                                loading={result.loading}
                                            />
                                        )}
                                    </ChangeSettingsConnectorApollo>

                                    <HydrationSettingsConnector
                                        rawQueryResult={result.rawData}
                                    >
                                        {save => (
                                            <HydrationSettings
                                                loading={result.loading}
                                                defaultDailyGoal={
                                                    result.hydrationSettings
                                                        .defaultDailyGoal
                                                }
                                                save={save}
                                            />
                                        )}
                                    </HydrationSettingsConnector>

                                    <UserAgreementNotifyConnector
                                        rawQueryResult={result.rawData}
                                    >
                                        {notify => (
                                            <NotificationsSettings
                                                agreements={result.agreements}
                                                onNotifyChange={
                                                    notify.changeAgreement
                                                }
                                                loading={result.loading}
                                            />
                                        )}
                                    </UserAgreementNotifyConnector>

                                    {result.passwordIsSet ? (
                                        <ChangePasswordConnector>
                                            {changePassword => (
                                                <ChangePasswordForm
                                                    changePassword={
                                                        changePassword.changePassword
                                                    }
                                                    username={result.email}
                                                />
                                            )}
                                        </ChangePasswordConnector>
                                    ) : null}

                                    {this.renderSocialMediaLogin(
                                        result.isAbleToLoginWithGoogle,
                                        result.isAbleToLoginWithFacebook,
                                        result.isAbleToLoginWithApple
                                    )}
                                    <RemoveAccountButton
                                        loading={result.loading}
                                    />
                                </React.Fragment>
                            );
                        }}
                    </SettingsConnectorApollo>
                </section>
            </Layout>
        );
    }
}

export default withLocale(UserSettings);
