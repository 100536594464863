import React from 'react';
import PropTypes from 'prop-types';

class AuthLoginByAppleTokenWrapperComponent extends React.Component {
    static propTypes = {
        loginViaApple: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.loginViaApple();
    }

    render() {
        return null;
    }
}

export default AuthLoginByAppleTokenWrapperComponent;
