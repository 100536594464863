import React from 'react';
import AuthResetPasswordComponent from 'Auth/ResetPassword/AuthResetPasswordComponent';
import ResetPasswordConnector from './Connector/Apollo/ResetPassword/ResetPasswordConnectorApollo';
import ValidateResetPasswordTokenConnector from './Connector/Apollo/ValidateResetPasswordToken/ValidateResetPasswordTokenConnectorApollo';

class AuthResetPasswordPage extends React.Component {
    render() {
        const isLoggedIn = !!localStorage.getItem('token');

        return (
            <ResetPasswordConnector>
                {({ ...resetPassword }) => (
                    <ValidateResetPasswordTokenConnector>
                        {({ ...validateToken }) => (
                            <AuthResetPasswordComponent
                                {...resetPassword}
                                {...validateToken}
                                isLoggedIn={isLoggedIn}
                            />
                        )}
                    </ValidateResetPasswordTokenConnector>
                )}
            </ResetPasswordConnector>
        );
    }
}

export default AuthResetPasswordPage;
