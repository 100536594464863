import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import { PATHS } from 'config/paths';
import RouteChildren from './RouteChildren';
import { APP_TAB_MORE } from './consts';

class PrivateRoute extends Component {
    static propTypes = {
        render: PropTypes.func,
        component: PropTypes.elementType,
        title: PropTypes.string.isRequired,
        mobileTitle: PropTypes.string,
        mobileAppTabIdentifier: PropTypes.string,
    };

    static defaultProps = {
        render: undefined,
        component: undefined,
        mobileTitle: undefined,
        mobileAppTabIdentifier: APP_TAB_MORE,
    };

    render() {
        const {
            component,
            title,
            mobileTitle,
            mobileAppTabIdentifier,
            render,
            ...rest
        } = this.props;

        const childrenProps = {};
        if (render) {
            childrenProps.render = render;
        } else if (component) {
            childrenProps.component = component;
        }

        return (
            <Route
                {...rest}
                render={props =>
                    localStorage.getItem('token') !== null ? (
                        <RouteChildren
                            title={title}
                            mobileTitle={mobileTitle}
                            mobileAppTabIdentifier={mobileAppTabIdentifier}
                            {...childrenProps}
                            {...props}
                        />
                    ) : (
                        <Redirect
                            to={{
                                pathname: PATHS.AUTH.LOGIN,
                                state: { from: props.location },
                            }}
                        />
                    )
                }
            />
        );
    }
}

export default PrivateRoute;
