import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { Helmet } from 'react-helmet';
import { Col, Container, Row } from 'reactstrap';
import Layout from 'Layout/Layout';
import ExternalProviderContainer from '../ByExternalProvider/ExternalProviderContainer';
import { RETURN_URL_LOGIN } from '../ByExternalProvider/LoginReturnUrls';
import { withLocale } from '../../../TranslatorContext';
import AuthLoginByEmailAndPasswordForm from './AuthLoginByEmailAndPasswordForm';

class AuthLoginByEmailAndPasswordComponent extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        login: PropTypes.func.isRequired,
        isLoggedIn: PropTypes.bool.isRequired,
        userDataOnDemand: PropTypes.func.isRequired,
        redirectToPathAfterSuccess: PropTypes.string.isRequired,
    };

    render() {
        if (this.props.isLoggedIn) {
            return <Redirect to={this.props.redirectToPathAfterSuccess} />;
        }
        return (
            <Layout page="login">
                <Helmet>
                    <meta name="robots" content="noindex,nofollow" />
                </Helmet>
                <section className="bg-white">
                    <Container className="text-center">
                        <h1>{this.props.t('auth/login/title')}</h1>
                        <Row>
                            <Col
                                xs="12"
                                sm={{ size: 8, offset: 2 }}
                                md={{ size: 6, offset: 3 }}
                            >
                                <AuthLoginByEmailAndPasswordForm
                                    login={this.props.login}
                                    userDataOnDemand={
                                        this.props.userDataOnDemand
                                    }
                                    redirectToPathAfterSuccess={
                                        this.props.redirectToPathAfterSuccess
                                    }
                                />
                                <ExternalProviderContainer
                                    returnUrl={RETURN_URL_LOGIN}
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default withLocale(AuthLoginByEmailAndPasswordComponent);
