import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withLocale } from '../../../TranslatorContext';

class UserDataPlaceholder extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        const { t } = this.props;

        return (
            <Container>
                <Row>
                    <Col xs="12" md={{ size: 8, offset: 2 }}>
                        <h2 className="heading-3">
                            {t('settings/user-goal')}{' '}
                        </h2>
                        <p>{t('settings/weight')}:</p>
                        <p>{t('settings/diet-mode')}:</p>
                        <p>{t('settings/goal-weight')}:</p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withLocale(UserDataPlaceholder);
