import gql from 'graphql-tag';

export default gql`
    mutation RemoveUserConfirm($token: String!) {
        removeAccount {
            confirm(token: $token) {
                __typename
                ... on ValidationException {
                    code
                    details {
                        fieldName
                        messages
                    }
                }
                ... on UnauthorizedException {
                    code
                }

                ... on BasicMutationSuccess {
                    code
                }
            }
        }
    }
`;
