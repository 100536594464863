/* eslint-disable import/no-cycle */
import UserSettings from './UserSettings';
import UserDataEditPage from './UserDataEditPage';
import UserGoalEditPage from './UserGoalEditPage';

const PATH_SETTINGS = '/settings';
const PATH_SETTINGS_USER_DATA = '/settings/user-data';
const PATH_SETTINGS_USER_GOAL = '/settings/goal';

const routes = [
    {
        path: PATH_SETTINGS,
        component: UserSettings,
        private: true,
        exact: true,
        title: 'settings/title',
        mobileAppTabIdentifier: 'profile',
    },
    {
        path: PATH_SETTINGS_USER_DATA,
        component: UserDataEditPage,
        private: true,
        exact: true,
        title: 'settings/user-data',
        mobileAppTabIdentifier: 'profile',
    },
    {
        path: PATH_SETTINGS_USER_GOAL,
        component: UserGoalEditPage,
        private: true,
        exact: true,
        title: 'settings/user-goal',
        mobileAppTabIdentifier: 'profile',
    },
];

export {
    PATH_SETTINGS,
    PATH_SETTINGS_USER_DATA,
    PATH_SETTINGS_USER_GOAL,
    routes,
};
