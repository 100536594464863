import { connect } from 'react-redux';
import SaleSourcesProviderComponent from './SaleSourcesProviderComponent';
import * as actions from './SaleSourcesAction';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    getSourcesValues: () => dispatch(actions.saleSourcesGet()),
    setSourceValue: newValue => dispatch(actions.saleSourcesSet(newValue)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SaleSourcesProviderComponent);
