import React from 'react';
import AuthActivateContainer from './AuthActivateContainer';
import ActivateConnector from './Connector/Apollo/Activate/ActivateConnectorApollo';

class CouponActivatePage extends React.Component {
    render() {
        return (
            <ActivateConnector>
                {({ ...activateAccount }) => (
                    <AuthActivateContainer {...activateAccount} />
                )}
            </ActivateConnector>
        );
    }
}

export default CouponActivatePage;
