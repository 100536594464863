import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glass from 'assets/icons/hydration/glass.svg';
import bottle from 'assets/icons/hydration/bottle.svg';
import custom from 'assets/icons/hydration/custom.svg';
import Add from './HydrationAddButton';

class HydrationAddContainer extends Component {
    static propTypes = {
        unit: PropTypes.string.isRequired,
        add: PropTypes.func.isRequired,
        messages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    };

    mililiterButtons = [250, 1000];

    ounceButtons = [8, 32];

    render() {
        let buttons;

        if (this.props.unit === 'ml') {
            buttons = this.mililiterButtons;
        } else if (this.props.unit === 'oz') {
            buttons = this.ounceButtons;
        } else {
            throw new Error(`Unsupported unit: ${this.props.unit}`);
        }

        return (
            <div className="text-center">
                <Add
                    value={buttons[0]}
                    unit={this.props.unit}
                    messages={this.props.messages}
                    add={this.props.add}
                    icon={glass}
                />

                <Add
                    value={buttons[1]}
                    unit={this.props.unit}
                    messages={this.props.messages}
                    add={this.props.add}
                    icon={bottle}
                />

                <Add
                    value={null}
                    unit={this.props.unit}
                    messages={this.props.messages}
                    add={this.props.add}
                    icon={custom}
                />
            </div>
        );
    }
}

export default HydrationAddContainer;
