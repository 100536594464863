import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import SaveUserDataMutation from './SaveUserDataMutation';

const SaveUserDataConnectorApollo = ({ children }) => {
    const [mutate] = useMutation(SaveUserDataMutation);

    const createSaveUserData = async (request, userId) => {
        const variables = request;

        const response = await mutate({
            variables,
            update: cache => {
                cache.modify({
                    id: `Me:${userId}`,
                    fields: {
                        name() {
                            return request.name;
                        },
                        sex() {
                            return request.sex;
                        },
                        height() {
                            return request.height.value;
                        },
                        birthDate() {
                            return request.birthDate;
                        },
                    },
                });
            },
        });

        return response;
    };

    return children({
        saveUserData: createSaveUserData,
    });
};

SaveUserDataConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default SaveUserDataConnectorApollo;
