import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import ContentQuery from './ContentQuery';

const ContentOnDemandConnectorApollo = ({ children }) => {
    const [fetchOnDemand] = useLazyQuery(ContentQuery);

    const fetch = async identifier => {
        const result = await fetchOnDemand({
            query: ContentQuery,
            variables: identifier,
            fetchPolicy: 'no-cache',
        });

        return result;
    };

    return children({
        loadContent: identifier => fetch(identifier),
    });
};

ContentOnDemandConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default ContentOnDemandConnectorApollo;
