import gql from 'graphql-tag';

const query = gql`
    query ArticleList(
        $categoryIdentifier: String
        $pageNumber: Int
        $articlesPerPage: Int
    ) {
        article(
            categoryIdentifier: $categoryIdentifier
            pageNumber: $pageNumber
            articlesPerPage: $articlesPerPage
            sortByPinned: true
        ) {
            id
            title
            slug
            teaser {
                markdown
                html
            }
            lead {
                markdown
                html
            }
            thumbUrl
            category {
                name
                identifier
                label {
                    color
                }
            }
            pinned
            revealType
            revealDietPhase {
                name
                colour
            }
        }
        articlePagination(
            categoryIdentifier: $categoryIdentifier
            pageNumber: $pageNumber
            articlesPerPage: $articlesPerPage
        ) {
            pageCount
            articlesCount
        }
    }
`;

export default query;
