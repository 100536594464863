import React from 'react';
import PropTypes from 'prop-types';

class AuthRegisterByAppleTokenWrapperComponent extends React.Component {
    static propTypes = {
        registerViaApple: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.registerViaApple();
    }

    render() {
        return null;
    }
}

export default AuthRegisterByAppleTokenWrapperComponent;
