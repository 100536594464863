import React, { Component } from 'react';

// eslint-disable-next-line arrow-body-style
const withConnectionTypeFactory = (
    ChildComponent,
    { watchingForChanges = false } = {}
) => {
    class withConnectionType extends Component {
        constructor(props) {
            super(props);

            this.hasNetworkInfoSupport = Boolean(
                navigator.connection &&
                    (navigator.connection.effectiveType ||
                        navigator.connection.type)
            );

            this.state = {
                connectionType: undefined,
            };

            if (this.hasNetworkInfoSupport) {
                this.state.connectionType = this.constructor.getConnectionType();
            }

            this.updateConnectionType = this.updateConnectionType.bind(this);

            /* eslint-disable operator-linebreak */
            this.isConnectionSuitableForHdVideo = this.isConnectionSuitableForHdVideo.bind(
                this
            );
            this.isConnectionSuitableForVideo = this.isConnectionSuitableForVideo.bind(
                this
            );
            this.isConnectionSuitableForOptionalVideo = this.isConnectionSuitableForOptionalVideo.bind(
                this
            );
            this.isConnectionSuitableForHdImages = this.isConnectionSuitableForHdImages.bind(
                this
            );
            this.isConnectionSuitableForImages = this.isConnectionSuitableForImages.bind(
                this
            );
            /* eslint-enable operator-linebreak */
        }

        componentDidMount() {
            if (this.hasNetworkInfoSupport && watchingForChanges) {
                navigator.connection.addEventListener(
                    'change',
                    this.updateConnectionType
                );
            }
        }

        componentWillUnmount() {
            if (this.hasNetworkInfoSupport && watchingForChanges) {
                navigator.connection.removeEventListener(
                    'change',
                    this.updateConnectionType
                );
            }
        }

        static getConnectionType() {
            if (!navigator.onLine) {
                return 'offline';
            }

            const { connection } = navigator;

            if (connection.saveData) {
                return 'saveData';
            }

            if (connection.effectiveType) {
                return connection.effectiveType;
            }

            // connection.type is _much_ less helpful than effectiveType,
            // but if that is not available we’ll make do.
            switch (connection.type) {
                case 'cellular':
                    return '3g';
                // Undefined is the same value we use for browsers not
                // supporting connection.type at all
                default:
                    return undefined;
            }
        }

        updateConnectionType() {
            if (this.hasNetworkInfoSupport) {
                const connectionType = this.constructor.getConnectionType();
                this.setState({ connectionType });
            }
        }

        /**
         * See http://wicg.github.io/netinfo/#dfn-effective-connection-type
         */
        isConnectionSuitableForHdVideo() {
            return [undefined, '4g'].includes(this.state.connectionType);
        }

        /**
         * See http://wicg.github.io/netinfo/#dfn-effective-connection-type
         */
        isConnectionSuitableForVideo() {
            return [undefined, 'saveData', '3g', '4g'].includes(
                this.state.connectionType
            );
        }

        isConnectionSuitableForOptionalVideo() {
            return [undefined, '4g'].includes(this.state.connectionType);
        }

        /**
         * See http://wicg.github.io/netinfo/#dfn-effective-connection-type
         */
        isConnectionSuitableForHdImages() {
            return [undefined, '3g', '4g'].includes(this.state.connectionType);
        }

        /**
         * See http://wicg.github.io/netinfo/#dfn-effective-connection-type
         */
        isConnectionSuitableForImages() {
            return [undefined, 'saveData', '2g', '3g', '4g'].includes(
                this.state.connectionType
            );
        }

        render() {
            const connectionType = this.state.connectionType;
            let offLine;
            if (connectionType) {
                offLine = connectionType === 'offline';
            }

            return (
                <ChildComponent
                    connectionType={connectionType}
                    offLine={offLine}
                    isConnectionSuitableForHdVideo={
                        this.isConnectionSuitableForHdVideo
                    }
                    isConnectionSuitableForVideo={
                        this.isConnectionSuitableForVideo
                    }
                    isConnectionSuitableForOptionalVideo={
                        this.isConnectionSuitableForOptionalVideo
                    }
                    isConnectionSuitableForHdImages={
                        this.isConnectionSuitableForHdImages
                    }
                    isConnectionSuitableForImages={
                        this.isConnectionSuitableForImages
                    }
                    {...this.props}
                />
            );
        }
    }

    withConnectionType.displayName = `${ChildComponent.displayName ||
        ChildComponent.name} withConnectionType`;
    return withConnectionType;
};

export default withConnectionTypeFactory;
