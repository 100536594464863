import gql from 'graphql-tag';

export default gql`
    mutation SaveUserGoal(
        $startDate: Date!
        $measurementDate: Date!
        $weight: UserMeasurementWeightInputType
        $dietMode: DietModeType!
        $goalWeight: UserMeasurementWeightInputType!
    ) {
        me {
            measurementGroupSave(
                request: { date: $measurementDate, weight: $weight }
            ) {
                __typename
                ... on ValidationException {
                    code
                    messages
                    details {
                        fieldName
                        messages
                    }
                }
            }

            dietModeChange(
                dietMode: $dietMode
                goalWeight: $goalWeight
                startDate: $startDate
            ) {
                __typename
                ... on ValidationException {
                    code
                    messages
                    details {
                        fieldName
                        messages
                    }
                }
            }
        }
    }
`;
