import React from 'react';
import { withRouter, Redirect } from 'react-router';
import PropTypes from 'prop-types';
// import { PATH_DAY_PLAN_INDEX } from 'DayPlan/DayPlanPaths';
import { PATHS } from 'config/paths';
import setAuthCredentials from '../../setAuthCredentials';

class AuthLoginByRouteComponent extends React.Component {
    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                token: PropTypes.string,
            }).isRequired,
        }).isRequired,
        userDataOnDemand: PropTypes.func.isRequired,
    };

    state = {
        initialized: false,
    };

    async componentDidMount() {
        const { token } = this.props.match.params;
        setAuthCredentials(token);
        const response = await this.props.userDataOnDemand();
        if (response) {
            this.setState({ initialized: true });
        }
    }

    render() {
        if (this.state.initialized) {
            return <Redirect to={PATHS.DEFAULT_PAGE_AFTER_LOGGED_IN} />;
        }

        return '';
    }
}

export { AuthLoginByRouteComponent as unwrapped };

export default withRouter(AuthLoginByRouteComponent);
