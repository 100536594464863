import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import { calculateFraction, reportWarning } from '@dietlabs/utils';

import i18n from './i18n';

export const TranslatorContext = React.createContext();

//
/*
in App.jsx:
    import {TranslationProvider} from '@dietlabs/components';
    render() {
        <TranslationProvider defaultLanguage="pl">
            ...
        </TranslationProvider>
    }
in subcomponents:
    import {withLocale} from '@dietlabs/components';
    ...
        <p>{this.props.T("Today")}</p>
    ...or...
        {this.props.t("multiline-test", null, true)}
    ...
    export default withLocale(ComponentName);

    translation.json could contain string in form
    "Czy jesteś [autorką|autorem]?" for strings that should be different
    depending on reader’s gender.

    You could disable conjugator (ex for complicated path translation)
    with calling it with parameter `conjugation: false`
*/

export function conjugate(message, locale, isFemale = true) {
    let msg = message;
    if (['pl', 'ru', 'de', 'cs'].includes(locale)) {
        while (msg.match(/\[(.*?)\|(.*?)\]/)) {
            msg = msg.replace(/\[(.*?)\|(.*?)\]/, (m, female, male) => {
                if (isFemale) return female;
                return male;
            });
        }
    }
    return msg;
}

export class TranslationProvider extends React.Component {
    static propTypes = {
        defaultLanguage: PropTypes.string.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        defaultLanguageTranslations: PropTypes.object.isRequired,
        // getGender: PropTypes.func.isRequired,
        children: PropTypes.node.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { translationResourceLoaded: true };
        i18n.addResourceBundle(
            this.props.defaultLanguage,
            'translations',
            this.props.defaultLanguageTranslations,
            true,
            true
        );
        i18n.changeLanguage(this.props.defaultLanguage);
    }

    t = (message, options = {}) => {
        const opt = {
            conjugation: true,
            // sex: this.props.getGender(),
            sex: global.localStorage.getItem('sex') || 'female',
            ...options,
        };

        /*
            required params: message (string)
            optional params:
                count
                    - number to placed in message translations
                sex
                    - force sex for conjugator (if undefined, used from store)
                conjugate
                    - default: true, could disable conjugator mechanism
                    for more complicated messages (like links with regexp etc.)
            example:
                this.props.t('xoxo', {sex: 'male'})

        */
        if (message === undefined) {
            const error = new Error(
                'Translation function called with undefined message'
            );
            reportWarning(error);
            return '';
        }
        const isFemale = opt.sex === 'female';
        if (opt.markdown) {
            const translated = i18n
                .t(message, opt.count)
                .split('\n')
                .map(msg => conjugate(msg, i18n.language, isFemale));
            return translated.map((msg, key) => (
                // eslint-disable-next-line react/no-array-index-key
                <Markdown key={key}>{msg}</Markdown>
            ));
        }

        if (!opt.count) {
            if (!i18n.exists(message)) {
                const error = new Error(
                    `Missing translation string for ${message}`
                );
                error.noDefaultFingerprint = true;
                error.fingerprint = ['translate', message];
                reportWarning(error);
            }
        } else {
            const missingKeys = i18n.services.pluralResolver
                .getPluralFormsOfKey(i18n.language, message)
                .filter(key => !i18n.exists(key));

            if (missingKeys.length !== 0) {
                const error = new Error(
                    `Missing translation strings for ${missingKeys.join(', ')}`
                );
                error.noDefaultFingerprint = true;
                error.fingerprint = ['translate', String(missingKeys)];
                reportWarning(error);
            }
        }

        opt.fraction = calculateFraction(opt.count);

        const translatedMessage = i18n.t(message, opt);
        if (opt.conjugation) {
            return conjugate(translatedMessage, i18n.language, isFemale);
        }
        return translatedMessage;
    };

    render() {
        if (this.state.translationResourceLoaded) {
            return (
                <TranslatorContext.Provider value={this.t}>
                    {this.props.children}
                </TranslatorContext.Provider>
            );
        }
        return <Fragment>Loading translations...</Fragment>;
    }
}

export function withLocale(Component) {
    return function LocalizedComponent(props) {
        return (
            <TranslatorContext.Consumer>
                {value => <Component {...props} t={value} />}
            </TranslatorContext.Consumer>
        );
    };
}
