export const validateFields = (rules, state) => {
    const errorArray = [];

    Object.keys(rules).forEach(key => {
        const messagesArray = [];
        rules[key].forEach(rule => {
            if (!rule.isValid(state[key])) {
                messagesArray.push(rule.getErrorMsg());
            }
        });

        if (messagesArray.length > 0) {
            const inputError = {
                fieldName: key,
                messages: messagesArray,
            };
            errorArray.push(inputError);
        }
    });
    return errorArray;
};
