import gql from 'graphql-tag';

const query = gql`
    query SaleIsPromoCodeValid($promoCode: String!) {
        sale {
            isPromoCodeValid(promoCode: $promoCode)
        }
    }
`;

export default query;
