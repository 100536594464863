export default function parseMobileApplication() {
    const appInfo = navigator.userAgent.split('/');
    let mobileApp = {
        active: false,
        os: null,
        version: null,
    };

    if (navigator.userAgent.includes('MobileApplication')) {
        if (navigator.userAgent.includes('iOS')) {
            mobileApp = {
                active: true,
                os: appInfo.slice(-2, -1)[0].toLocaleLowerCase(),
                version: appInfo.slice(-3, -2)[0].toLocaleLowerCase(),
            };
        }
        if (navigator.userAgent.includes('Android')) {
            mobileApp = {
                active: true,
                os: appInfo.slice(-2, -1)[0].toLocaleLowerCase(),
                version: appInfo.slice(-3, -2)[0].toLocaleLowerCase(),
            };
        }
    }

    return mobileApp;
}
