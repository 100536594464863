import React from 'react';
import UserDataOnDemand from '../../../UserData/Connector/Apollo/Fetch/UserDataOnDemandConnectorApollo';
import AuthLoginByRouteComponent from './AuthLoginByRouteComponent';

class AuthLoginByRoutePage extends React.Component {
    render() {
        return (
            <UserDataOnDemand>
                {({ ...userDataOnDemand }) => (
                    <AuthLoginByRouteComponent {...userDataOnDemand} />
                )}
            </UserDataOnDemand>
        );
    }
}

export default AuthLoginByRoutePage;
