import { useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { subDays } from 'utils/date';
import { formatIsoDate } from 'utils/dateFormatter';
import MeasurementHistoryQuery from './MeasurementHistoryQuery';

const MeasurementHistoryConnectorApollo = ({
    children,
    initialMeasurementType,
    periodLength,
    periodEnd,
}) => {
    const [localPeriodLength, setPeriodLength] = useState(periodLength);
    const [localPeriodEnd, setPeriodEnd] = useState(periodEnd);
    const [localMeasurementType, setMeasurementType] = useState(
        initialMeasurementType
    );
    const [lastMeasurementType, setLastMeasurementType] = useState(
        initialMeasurementType
    );

    const { loading, error, data, refetch } = useQuery(
        MeasurementHistoryQuery,
        {
            variables: {
                datePeriod: {
                    start: formatIsoDate(
                        subDays(localPeriodEnd, localPeriodLength - 1)
                    ),
                    end: formatIsoDate(localPeriodEnd),
                },
                [localMeasurementType]: true,
            },
        }
    );

    const load = async (measurementTypeV, periodLengthV, periodEndV) => {
        setPeriodLength(periodLengthV);
        setPeriodEnd(periodEndV);
        setMeasurementType(measurementTypeV);

        await refetch({
            datePeriod: {
                start: formatIsoDate(subDays(periodEndV, periodLengthV - 1)),
                end: formatIsoDate(periodEndV),
            },
            [measurementTypeV]: true,
            [lastMeasurementType]: false,
        });

        setLastMeasurementType(measurementTypeV);
    };

    const result = {
        loading,
        error,
        load,
        measurementType: localMeasurementType,
        periodLength: localPeriodLength,
        periodEnd: localPeriodEnd,
    };

    if (!loading) {
        result.history = data.me.history[localMeasurementType] || [];
        result.goalWeight = data.me.goalWeight;
    }

    return children(result);
};

MeasurementHistoryConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    initialMeasurementType: PropTypes.string.isRequired,
    periodLength: PropTypes.number.isRequired,
    periodEnd: PropTypes.instanceOf(Date).isRequired,
};

export default MeasurementHistoryConnectorApollo;
