import React from 'react';
import PropTypes from 'prop-types';

class AuthLoginByGoogleTokenWrapperComponent extends React.Component {
    static propTypes = {
        loginViaGoogle: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.loginViaGoogle();
    }

    render() {
        return null;
    }
}

export default AuthLoginByGoogleTokenWrapperComponent;
