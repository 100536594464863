export const MEASUREMENT_TYPES = {
    WEIGHT: 'weight',
    WAIST: 'waist',
    THIGH: 'thigh',
    RIGHTTHIGH: 'rightthigh',
    CHEST: 'chest',
    ARM: 'arm',
    RIGHTARM: 'rightarm',
};

export const PERIOD_END = new Date();
export const PERIOD_LENGTH = 31;
