import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'Layout/Loader';
import { Redirect } from 'react-router';
import { PATHS } from 'config/paths';
import { Alert } from 'reactstrap';
import UserDataOnDemand from '../../../UserData/Connector/Apollo/Fetch/UserDataOnDemandConnectorApollo';
import { withLocale } from '../../../TranslatorContext';
import setAuthCredentials from '../../setAuthCredentials';
import RegisterViaAppleConnectorApollo from './Connector/Apollo/RegisterViaAppleConnectorApollo';
import AuthRegisterByAppleTokenWrapperComponent from './AuthRegisterByAppleTokenWrapperComponent';

class AuthRegisterByAppleTokenComponent extends React.Component {
    static propTypes = {
        token: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
        returnPath: PropTypes.string,
    };

    static defaultProps = {
        returnPath: PATHS.CART.INDEX,
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            errors: [],
        };
    }

    registerViaApple = async (registerViaApple, userDataOnDemand) => {
        const response = await registerViaApple;

        if (
            response.data.auth.registerViaApple.__typename === 'OauthException'
        ) {
            if (response.data.auth.registerViaApple.code === 400) {
                this.setState(prevState => ({
                    errors: [
                        ...prevState.errors,
                        this.props.t('auth/login/user-exists'),
                    ],
                }));
            }
        } else {
            const userToken = response.data.auth.registerViaApple.token;
            setAuthCredentials(userToken);
            await userDataOnDemand();
        }

        this.setState({ isLoading: false });
    };

    render() {
        return (
            <React.Fragment>
                {this.state.errors.map(msg => (
                    <Alert color="danger" key={msg}>
                        {msg}
                    </Alert>
                ))}
                <RegisterViaAppleConnectorApollo>
                    {result => (
                        <UserDataOnDemand>
                            {result2 => (
                                <AuthRegisterByAppleTokenWrapperComponent
                                    registerViaApple={() =>
                                        this.registerViaApple(
                                            result.registerViaApple(
                                                this.props.token
                                            ),
                                            result2.userDataOnDemand
                                        )
                                    }
                                />
                            )}
                        </UserDataOnDemand>
                    )}
                </RegisterViaAppleConnectorApollo>
                {this.state.isLoading ? (
                    <div className="full-height">
                        <Loader />
                    </div>
                ) : null}

                {!this.state.isLoading && !this.state.errors.length ? (
                    <Redirect to={this.props.returnPath} />
                ) : null}
            </React.Fragment>
        );
    }
}

export default withLocale(AuthRegisterByAppleTokenComponent);
