export default userToken => {
    global.localStorage.setItem('token', userToken);

    let domain = document.location.host.replace('app.', '');
    domain = domain.replace('dev.', '');
    domain = domain.replace('stage.', '');

    if (!domain.startsWith('.')) {
        domain = `.${domain}`;
    }

    document.cookie = `Authentication=${userToken}; domain=${domain}; path=/; max-age=${60 *
        60 *
        24 *
        365}; secure`;
};
