import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '../../../../../Apollo/Mutation';
import LoginViaFacebookMutation from './LoginViaFacebookMutation';

class LoginViaFacebookConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    callLoginViaFacebook = apiLoginViaFacebook => async token => {
        const response = await apiLoginViaFacebook({
            variables: {
                token,
            },
        });

        return response;
    };

    render() {
        const { children } = this.props;
        return (
            <Mutation mutation={LoginViaFacebookMutation}>
                {(loginViaFacebookMutationResponse, result) => {
                    const loginViaFacebookResult = {
                        loginViaFacebook: this.callLoginViaFacebook(
                            loginViaFacebookMutationResponse
                        ),
                        loading: result.loading,
                    };

                    return children(loginViaFacebookResult);
                }}
            </Mutation>
        );
    }
}

export default LoginViaFacebookConnectorApollo;
