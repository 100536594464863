import React from 'react';
import PropTypes from 'prop-types';
import withLoading from 'Layout/withLoading';
import Placeholder from 'Layout/GenericPlaceholder';
import { formatIsoDate, addDays } from 'utils/dateFormatter';
import { Container, Button, Form } from 'reactstrap';
import InputNumber from 'components/FormElements/InputNumberControlled';
import Select from 'components/FormElements/InputSelectControlled';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import ValidationErrors from 'view/Validation/ValidationErrors';
import { validateFields } from 'view/Validation/validateFields';
import { validateField } from 'view/Validation/validateField';
import { Redirect } from 'react-router';
import Layout from 'Layout/Layout';
import { withLocale } from '../../TranslatorContext';
// eslint-disable-next-line import/no-cycle
import { PATH_SETTINGS } from './routes';

class UserGoalEditContainer extends React.PureComponent {
    static propTypes = {
        t: PropTypes.func.isRequired,
        systemOfMeasures: PropTypes.string.isRequired,
        weight: PropTypes.shape({
            value: PropTypes.number,
            unit: PropTypes.string,
        }).isRequired,
        goalWeight: PropTypes.shape({
            value: PropTypes.number,
            unit: PropTypes.string,
        }).isRequired,
        dietMode: PropTypes.string.isRequired,
        saveUserGoal: PropTypes.func.isRequired,
        userId: PropTypes.number.isRequired,
    };

    weightDefaultUnit =
        this.props.systemOfMeasures === 'imperial' ? 'lb' : 'kg';

    validationRules = {
        weight: [new RequiredRule({ translator: this.props.t })],
        dietMode: [new RequiredRule({ translator: this.props.t })],
        goalWeight: [new RequiredRule({ translator: this.props.t })],
    };

    state = {
        weight: {
            value: this.props.weight ? this.props.weight.value : '',
            unit: this.props.weight
                ? this.props.weight.unit
                : this.weightDefaultUnit,
        },
        goalWeight: {
            value: this.props.goalWeight ? this.props.goalWeight.value : '',
            unit: this.props.goalWeight
                ? this.props.goalWeight.unit
                : this.weightDefaultUnit,
        },
        dietMode: this.props.dietMode,
        errors: new ValidationErrors(),
        // preload: false,
        redirect: false,
    };

    handleInputChange = event => {
        const fieldName = event.target.name.split('.')[0];
        const valFloat = Math.round(parseFloat(event.target.value) * 10) / 10;

        if (fieldName === 'weight' || fieldName === 'goalWeight') {
            this.setState(prevState => ({
                [fieldName]: {
                    ...prevState[fieldName],
                    value: valFloat,
                },
            }));
        } else {
            this.setState({ [fieldName]: event.target.value });
        }

        if (this.validationRules[event.target.name]) {
            const field = event.target.name;
            const value = event.target.value;

            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    details: validateField(
                        field,
                        value,
                        this.validationRules[field],
                        prevState
                    ),
                },
            }));
        }
    };

    handleSave = async event => {
        event.preventDefault();
        const frontEndErrors = validateFields(
            this.validationRules,
            this.state,
            this.props.t
        );

        if (frontEndErrors.length === 0) {
            const request = {
                startDate: formatIsoDate(addDays(new Date(), 1)),
                measurementDate: formatIsoDate(new Date()),
                weight: this.state.weight,
                goalWeight: this.state.goalWeight,
                dietMode: this.state.dietMode,
            };

            try {
                const response = await this.props.saveUserGoal(
                    request,
                    this.props.userId
                );
                const typename1 = response.data.me.dietModeChange.__typename;
                const typename2 =
                    response.data.me.measurementGroupSave.__typename;
                if (
                    typename1 === 'BasicMutationSuccess' &&
                    typename2 === 'UserMeasurementGroupSaveSuccess'
                ) {
                    this.setState({
                        redirect: true,
                        errors: new ValidationErrors(),
                    });
                } else {
                    this.setState({
                        errors: {
                            ...response.data.me.dietModeChange,
                            ...response.data.me.measurementGroupSave,
                        },
                    });
                }
            } catch (e) {
                throw new Error(`Failed to save user goal, got error: ${e}`);
            }

            return null;
        }

        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                details: frontEndErrors,
            },
        }));

        return null;
    };

    render() {
        const optionalWeightProps = {};
        if (this.state.weight) {
            optionalWeightProps.value = Number(this.state.weight.value);
        }

        const optionalGoalWeightProps = {};
        if (this.state.goalWeight) {
            optionalGoalWeightProps.value = Number(this.state.goalWeight.value);
        }

        if (this.state.redirect) {
            return <Redirect to={PATH_SETTINGS} />;
        }

        return (
            <Layout page="settings">
                <header>
                    <h1 className="text-center d-none d-md-block">
                        {this.props.t('settings/title')}
                    </h1>
                </header>
                <section className="pt-0">
                    <Container>
                        <Form
                            data-test="bmr-form"
                            onSubmit={event => this.handleSave(event)}
                            noValidate
                        >
                            <InputNumber
                                label={this.props.t(
                                    'diet-settings/current-body-weight'
                                )}
                                name="weight"
                                unit={this.state.weight.unit}
                                errors={this.state.errors}
                                handleChange={this.handleInputChange}
                                validationRules={this.validationRules.weight}
                                {...optionalWeightProps}
                            />

                            <Select
                                label={this.props.t('diet-settings/your-goal')}
                                name="dietMode"
                                handleChange={this.handleInputChange}
                                value={this.state.dietMode}
                                validationRules={this.validationRules.dietMode}
                                errors={this.state.errors}
                            >
                                <option value="slimming">
                                    {this.props.t(
                                        'diet-settings/goal/slimming'
                                    )}
                                </option>
                                <option value="stabilization">
                                    {this.props.t(
                                        'diet-settings/goal/stabilization'
                                    )}
                                </option>
                                <option value="put_on_weight">
                                    {this.props.t(
                                        'diet-settings/goal/put-on-weight'
                                    )}
                                </option>
                                <option value="gain_muscle_mass">
                                    {this.props.t(
                                        'diet-settings/goal/gain-muscle-mass'
                                    )}
                                </option>
                            </Select>

                            <InputNumber
                                label={this.props.t(
                                    'diet-settings/goal-weight'
                                )}
                                name="goalWeight"
                                unit={this.state.goalWeight.unit}
                                errors={this.state.errors}
                                handleChange={this.handleInputChange}
                                validationRules={
                                    this.validationRules.goalWeight
                                }
                                {...optionalGoalWeightProps}
                                data-test="height-input"
                            />

                            <div className="push" />

                            <footer>
                                <Button
                                    color="primary"
                                    className="w-100"
                                    type="submit"
                                >
                                    {this.props.t('save')}
                                </Button>
                            </footer>
                        </Form>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default withLoading(withLocale(UserGoalEditContainer), Placeholder);
