import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'Layout/Loader';
import { Redirect } from 'react-router';
// import { PATH_DAY_PLAN_INDEX } from 'DayPlan/DayPlanPaths';
import { PATHS } from 'config/paths';
import ErrorNoAccount from 'components/Error/ErrorNoAccount';
import setAuthCredentials from '../../setAuthCredentials';
import UserDataOnDemand from '../../../UserData/Connector/Apollo/Fetch/UserDataOnDemandConnectorApollo';
import { withLocale } from '../../../TranslatorContext';
import LoginViaAppleConnectorApollo from './Connector/Apollo/LoginViaAppleConnectorApollo';
import AuthLoginByAppleTokenWrapperComponent from './AuthLoginByAppleTokenWrapperComponent';

class AuthLoginByAppleTokenComponent extends React.Component {
    static propTypes = {
        token: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
        returnPath: PropTypes.string,
    };

    static defaultProps = {
        returnPath: PATHS.DEFAULT_PAGE_AFTER_LOGGED_IN,
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            errors: [],
        };
    }

    loginViaApple = async (loginViaApple, userDataOnDemand) => {
        const response = await loginViaApple;

        if (response.data.auth.loginViaApple.__typename === 'OauthException') {
            if (response.data.auth.loginViaApple.code === 401) {
                this.setState(prevState => ({
                    errors: [
                        ...prevState.errors,
                        this.props.t('auth/login/user-not-exists'),
                    ],
                }));
            }
        } else {
            const userToken = response.data.auth.loginViaApple.token;
            setAuthCredentials(userToken);
            await userDataOnDemand();
        }

        this.setState({ isLoading: false });
    };

    render() {
        return (
            <React.Fragment>
                <LoginViaAppleConnectorApollo>
                    {result => (
                        <UserDataOnDemand>
                            {result2 => (
                                <AuthLoginByAppleTokenWrapperComponent
                                    loginViaApple={() =>
                                        this.loginViaApple(
                                            result.loginViaApple(
                                                this.props.token
                                            ),
                                            result2.userDataOnDemand
                                        )
                                    }
                                />
                            )}
                        </UserDataOnDemand>
                    )}
                </LoginViaAppleConnectorApollo>
                {this.state.isLoading ? (
                    <div className="full-height">
                        <Loader />
                    </div>
                ) : null}

                {!this.state.isLoading && !this.state.errors.length ? (
                    <Redirect to={this.props.returnPath} />
                ) : null}

                {!this.state.isLoading && this.state.errors.length ? (
                    <ErrorNoAccount />
                ) : null}
            </React.Fragment>
        );
    }
}

export default withLocale(AuthLoginByAppleTokenComponent);
