import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import {
    Container,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Card,
    CardBody,
    CardHeader,
} from 'reactstrap';
import Layout from 'Layout/Layout';
import { PATH_COUPON_ACTIVATE } from 'Coupon/CouponPaths';
import ExternalProviderContainer from '../Auth/Login/ByExternalProvider/ExternalProviderContainer';
import { RETURN_URL_ACTIVATE } from '../Auth/Login/ByExternalProvider/LoginReturnUrls';
import { withLocale } from '../TranslatorContext';
import CouponLoginContainer from './Login/CouponLoginContainer';
import CouponRegisterContainer from './Register/CouponRegisterContainer';

export class CouponIndexComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: '1',
        };
    }

    render() {
        const isLoggedIn = !!localStorage.getItem('token');

        if (isLoggedIn) {
            return <Redirect to={PATH_COUPON_ACTIVATE} />;
        }

        return (
            <Layout page="coupon">
                <header>
                    <h1 className="text-center d-none d-md-block">
                        {this.props.t('coupon/title')}
                    </h1>
                </header>
                <section className="pt-0">
                    <Container>
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <Card>
                                    <CardHeader>
                                        {this.props.t('coupon/continue')}
                                    </CardHeader>
                                    <CardBody className="text-center">
                                        <ExternalProviderContainer
                                            state="register"
                                            returnUrl={RETURN_URL_ACTIVATE}
                                        />
                                    </CardBody>
                                </Card>
                                <br />
                                <hr />
                                <br />
                                <Card>
                                    <CardHeader className="p-0">
                                        <Nav tabs>
                                            <NavItem>
                                                <NavLink
                                                    className={
                                                        this.state.activeTab ===
                                                        '1'
                                                            ? 'active'
                                                            : ''
                                                    }
                                                    onClick={() => {
                                                        this.setState({
                                                            activeTab: '1',
                                                        });
                                                    }}
                                                >
                                                    {this.props.t(
                                                        'coupon/register-user/title'
                                                    )}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={
                                                        this.state.activeTab ===
                                                        '2'
                                                            ? 'active'
                                                            : ''
                                                    }
                                                    onClick={() => {
                                                        this.setState({
                                                            activeTab: '2',
                                                        });
                                                    }}
                                                >
                                                    {this.props.t(
                                                        'coupon/login/title'
                                                    )}
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </CardHeader>
                                    <CardBody>
                                        <TabContent
                                            activeTab={this.state.activeTab}
                                        >
                                            <TabPane tabId="1">
                                                <CouponRegisterContainer />
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <CouponLoginContainer />
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

CouponIndexComponent.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withLocale(CouponIndexComponent);
