import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import { withLocale } from '@dietlabs/components';

const Loader = props => (
    <div
        className={[
            'loader-wrapper',
            props.fixed === 'no' ? 'no-fixed' : null,
        ].join(' ')}
    >
        <Spinner color="primary">{props.t('loading/message')}</Spinner>
    </div>
);

Loader.propTypes = {
    fixed: PropTypes.oneOf(['no', null]),
    t: PropTypes.func.isRequired,
};
Loader.defaultProps = {
    fixed: null,
};

export { Loader };
export default withLocale(Loader);
