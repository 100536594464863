function isPlainObject(a) {
    return Object.prototype.toString.apply(a) === '[object Object]';
}

function deepMerge(a, b) {
    if (!isPlainObject(a) || !isPlainObject(b)) {
        return b;
    }

    const result = { ...a };

    Object.keys(b).forEach(key => {
        result[key] = deepMerge(result[key], b[key]);
    });

    return result;
}

export default deepMerge;
