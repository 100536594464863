import gql from 'graphql-tag';

export default gql`
    mutation ChangeSettingsMutation($request: UserSettingInputType) {
        me {
            setting(request: $request) {
                ... on UnauthorizedException {
                    code
                }
                ... on ValidationException {
                    code
                    messages
                    details {
                        fieldName
                        messages
                    }
                }
                ... on BasicMutationSuccess {
                    code
                }
            }
        }
    }
`;
