import React from 'react';
import { PATH_COUPON_ACTIVATE } from 'Coupon/CouponPaths';
import UserDataOnDemand from '../../UserData/Connector/Apollo/Fetch/UserDataOnDemandConnectorApollo';
import AuthLoginByEmailAndPasswordForm from '../../Auth/Login/ByEmailAndPassword/AuthLoginByEmailAndPasswordForm';
import LoginConnector from '../../Auth/Login/ByEmailAndPassword/Connector/Apollo/Login/LoginConnectorApollo';

class CouponLoginContainer extends React.Component {
    render() {
        return (
            <LoginConnector>
                {({ ...login }) => (
                    <UserDataOnDemand>
                        {({ ...userDataOnDemand }) => (
                            <AuthLoginByEmailAndPasswordForm
                                redirectToPathAfterSuccess={
                                    PATH_COUPON_ACTIVATE
                                }
                                {...login}
                                {...userDataOnDemand}
                                fromCouponLoginContainer
                            />
                        )}
                    </UserDataOnDemand>
                )}
            </LoginConnector>
        );
    }
}

export default CouponLoginContainer;
