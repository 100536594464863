import { PROMO_CODE_NAMESPACE as NAMESPACE } from '../PromoCodeNamespace';
import { isStale } from '../PromoCodeUtils';

// This function returns a function, so that it’s body is executed only
// when action is dispatched and not when it’s created
const promoCodeGetAction = () => (dispatch, getState) => {
    const promoCode = (getState()[NAMESPACE] || {}).value;

    return isStale(promoCode) ? null : promoCode.value;
};

export { promoCodeGetAction };
