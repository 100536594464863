import gql from 'graphql-tag';

export default gql`
    mutation MeCouponActivate($accessCode: String!) {
        me {
            coupon {
                activate(accessCode: $accessCode) {
                    __typename
                    ... on BasicMutationSuccess {
                        code
                    }
                    ... on ValidationException {
                        code
                        messages
                        details {
                            fieldName
                            messages
                        }
                    }
                }
            }
        }
    }
`;
