import React, { Component } from 'react';
import PropTypes from 'prop-types';

export const TABLE_ROW_CELL_ALIGN_LEFT = 'align-left';
export const TABLE_ROW_CELL_ALIGN_CENTER = 'align-center';
export const TABLE_ROW_CELL_ALIGN_RIGHT = 'align-right';

class TableRowCell extends Component {
    static propTypes = {
        children: PropTypes.node,
        th: PropTypes.bool,
        aligment: PropTypes.oneOf([
            TABLE_ROW_CELL_ALIGN_RIGHT,
            TABLE_ROW_CELL_ALIGN_CENTER,
            TABLE_ROW_CELL_ALIGN_LEFT,
        ]),
    };

    static defaultProps = {
        th: false,
        aligment: TABLE_ROW_CELL_ALIGN_LEFT,
        children: undefined,
    };

    render() {
        const { children, th, aligment } = this.props;
        let className = '';
        if (aligment === TABLE_ROW_CELL_ALIGN_RIGHT) {
            className += 'text-right';
        }

        if (th) {
            return (
                <th scope="row" className={className}>
                    {children}
                </th>
            );
        }
        return <td className={className}>{children}</td>;
    }
}

export default TableRowCell;
