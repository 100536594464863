import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import {
    InMemoryCache,
    IntrospectionFragmentMatcher,
    defaultDataIdFromObject,
} from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { CachePersistor } from 'apollo-cache-persist';
import config from 'config/config';
import { PATHS } from 'config/paths';
import callNativeMobileApp from 'components/Helpers/callNativeMobileApp';
import { reportError } from '@dietlabs/utils';
import introspectionQueryResultData from 'Apollo/introspectionQueryResultData';
import { APP_VERSION } from 'consts';
import LocalStorageWithCompression from './LocalStorageWithCompression';
import removeAuthCredentials from '../Auth/removeAuthCredentials';
// import packageJson from '../../package.json';

const dataIdFromObject = entity =>
    defaultDataIdFromObject({
        ...entity,
        id: entity.key || entity.id,
    });

class Apollo extends React.Component {
    state = {
        client: null,
    };

    getClient = async () => {
        const fragmentMatcher = new IntrospectionFragmentMatcher({
            introspectionQueryResultData,
        });

        const cache = new InMemoryCache({
            fragmentMatcher,
            dataIdFromObject,
        });

        const authMiddleware = new ApolloLink((operation, forward) => {
            operation.setContext(({ headers = {} }) => ({
                headers: {
                    ...headers,
                    'X-Authentication': localStorage.getItem('token') || null,
                },
            }));

            return forward(operation);
        });

        if (localStorage) {
            const cachePersistor = new CachePersistor({
                cache,
                storage: new LocalStorageWithCompression({
                    storage: localStorage,
                    onError: () => {
                        cachePersistor.purge();
                        cachePersistor.pause();
                    },
                }),
                maxSize: false,
            });
            await cachePersistor.restore();
        }

        const client = new ApolloClient({
            link: ApolloLink.from([
                onError(({ graphQLErrors, networkError }) => {
                    if (networkError.statusCode === 401) {
                        removeAuthCredentials();
                        callNativeMobileApp({ action: 'forced_logout' });
                        window.location = PATHS.AUTH.LOGIN;
                    } else if (graphQLErrors) {
                        graphQLErrors.forEach(
                            ({ message, locations, path }) => {
                                reportError(
                                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                                );
                            }
                        );
                    }
                }),
                authMiddleware,
                new HttpLink({
                    uri: config.apiGateway.url,
                    credentials: 'same-origin',
                    headers: {
                        'X-AppEnvironment': process.env.REACT_APP_ENV,
                        'X-AppVersion': `ReactWebApp/${APP_VERSION}`,
                    },
                }),
            ]),
            cache,
        });

        return client;
    };

    async componentDidMount() {
        const client = await this.getClient();
        this.setState({ client });
    }

    render() {
        const { client } = this.state;
        // eslint-disable-next-line react/prop-types
        const { children } = this.props;

        return client ? (
            <ApolloProvider client={client}>{children}</ApolloProvider>
        ) : null;
    }
}

export { dataIdFromObject };
export default Apollo;
