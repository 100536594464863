import React from 'react';
import { Container, Row, Col, Form } from 'reactstrap';
import PropTypes from 'prop-types';
import InputSwitch from 'components/FormElements/InputSwitchControlled2';
import withLoading from 'Layout/withLoading';
import UserAgreementToNotifyAboutDietCourseMutation from '../Connector/Apollo/Notifications/UserAgreementToNotifyAboutDietCourseMutation';
import UserAgreementToReceiveNewsletterMutation from '../Connector/Apollo/Notifications/UserAgreementToReceiveNewsletterMutation';
import NotificationsSettingsFormPlaceholder from './NotificationsSettingsFormPlaceholder';
import { withLocale } from '../../../TranslatorContext';

export const NotificationsSettings = props => {
    return (
        <Container className={props.className}>
            <Row>
                <Col xs="12" md={{ size: 8, offset: 2 }}>
                    <Form>
                        <h2 className="heading-3">
                            {props.t('settings/notifications')}
                        </h2>

                        <InputSwitch
                            label={props.t(
                                'settings/agreements/motivation-and-articles'
                            )}
                            id="dietCourse"
                            name="dietCourse"
                            value={props.agreements.dietCourse}
                            handleChange={event =>
                                props.onNotifyChange(
                                    UserAgreementToNotifyAboutDietCourseMutation,
                                    'dietCourse',
                                    event.target.checked
                                )
                            }
                            checked={props.agreements.dietCourse}
                        />
                        <InputSwitch
                            label={props.t('settings/agreements/newsletter')}
                            id="newsletter"
                            name="newsletter"
                            value={props.agreements.newsletter}
                            handleChange={event =>
                                props.onNotifyChange(
                                    UserAgreementToReceiveNewsletterMutation,
                                    'newsletter',
                                    event.target.checked
                                )
                            }
                            checked={props.agreements.newsletter}
                        />
                        <p>
                            <small>
                                {props.t('settings/agreements/newsletter-info')}
                            </small>
                        </p>
                        {props.children}
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

NotificationsSettings.propTypes = {
    className: PropTypes.string,
    agreements: PropTypes.shape({
        dietCourse: PropTypes.bool.isRequired,
        articles: PropTypes.bool.isRequired,
        newsletter: PropTypes.bool.isRequired,
    }).isRequired,
    onNotifyChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    children: PropTypes.node,
};

NotificationsSettings.defaultProps = {
    className: null,
    children: null,
};
export default withLoading(
    withLocale(NotificationsSettings),
    NotificationsSettingsFormPlaceholder
);
