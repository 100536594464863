/* global Android  */
import { reportError } from '@dietlabs/utils';
import isMobileApplication from './isMobileApplication';

let retryCount = 0;

export default function callNativeMobileApp(args) {
    const retryCallNativeMobileApp = () => {
        retryCount += 1;
        if (retryCount <= 5) {
            setTimeout(() => callNativeMobileApp(args), 1000);
        } else {
            reportError(new Error(`try 5x callNativeMobileApp`));
        }
    };

    if (isMobileApplication() === 'ios') {
        if (window.webkit) {
            if (window.webkit.messageHandlers) {
                if (window.webkit.messageHandlers.onReceiveMessage) {
                    if (
                        window.webkit.messageHandlers.onReceiveMessage
                            .postMessage
                    ) {
                        window.webkit.messageHandlers.onReceiveMessage.postMessage(
                            args
                        );
                    } else {
                        reportError(
                            new Error(
                                `window.webkit.messageHandlers.onReceiveMessage.postMessage is not set`
                            )
                        );
                        retryCallNativeMobileApp();
                    }
                } else {
                    reportError(
                        new Error(
                            `window.webkit.messageHandlers.onReceiveMessage is not set`
                        )
                    );
                    retryCallNativeMobileApp();
                }
            } else {
                reportError(
                    new Error(`window.webkit.messageHandlers is not set`)
                );
                retryCallNativeMobileApp();
            }
        } else {
            reportError(new Error(`window.webkit is not set`));
            retryCallNativeMobileApp();
        }
    } else if (isMobileApplication() === 'android') {
        if (Android) {
            Android.onReceiveMessage(JSON.stringify(args));
        } else {
            reportError(new Error(`Android is not set`));
            retryCallNativeMobileApp();
        }
    }
}
