/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { formatIsoDate } from 'utils/dateFormatter';
import { subDays } from 'utils/date';
import DateLineChartPlaceholder from 'Layout/Chart/DateLineChartPlaceholder';
import Table from 'Layout/Table/Table';
import TableRowPlaceholder from 'Layout/Table/TableRowPlaceholder';
import NoResults from 'Layout/Messages/NoResults';
import { FullMeasurementType } from 'types/CommonTypes';
import { Container } from 'reactstrap';
import { MEASUREMENT_TYPES } from '../constants';
import MeasurementHistoryFilters from './MeasurementHistoryFilters';
import MeasurementHistoryChart from './MeasurementHistoryChart';
import MeasurementHistoryTableRow from './MeasurementHistoryTableRow';
import { withLocale } from '../../../TranslatorContext';

export class MeasurementHistoryContainer extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        load: PropTypes.func,
        loading: PropTypes.bool.isRequired,
        history: PropTypes.arrayOf(FullMeasurementType),
        goalWeight: PropTypes.shape({
            value: PropTypes.number.isRequired,
            unit: PropTypes.string.isRequired,
        }),
        measurementType: PropTypes.string.isRequired,
        periodLength: PropTypes.number.isRequired,
        periodEnd: PropTypes.instanceOf(Date).isRequired,
    };

    static defaultProps = {
        load: undefined,
        history: undefined,
        goalWeight: undefined,
    };

    state = {
        measurementType: this.props.measurementType,
        periodLength: this.props.periodLength,
    };

    handleMeasurementTypeChange = measurementType =>
        this.setState(
            {
                measurementType,
            },
            () =>
                this.props.load(
                    this.state.measurementType,
                    this.state.periodLength,
                    this.props.periodEnd
                )
        );

    handlePeriodLengthChange = periodLength =>
        this.setState(
            {
                periodLength,
            },
            () =>
                this.props.load(
                    this.state.measurementType,
                    this.state.periodLength,
                    this.props.periodEnd
                )
        );

    renderContent() {
        const { t, loading, history } = this.props;

        if (loading) {
            return (
                <React.Fragment>
                    <DateLineChartPlaceholder />
                    <Table>
                        {Array.from(Array(5)).map((data, key) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <TableRowPlaceholder key={key} />
                        ))}
                    </Table>
                </React.Fragment>
            );
        }

        if (history.length) {
            const { goalWeight, periodEnd } = this.props;
            const { measurementType, periodLength } = this.state;

            const chartProps = {};

            if (!loading) {
                const period = {
                    start: formatIsoDate(subDays(periodEnd, periodLength - 1)),
                    end: formatIsoDate(periodEnd),
                };

                chartProps.history = history;
                chartProps.period = period;
                if (
                    measurementType === MEASUREMENT_TYPES.WEIGHT &&
                    goalWeight &&
                    goalWeight.value != null
                ) {
                    chartProps.trend = goalWeight.value;
                }
            }

            return (
                <React.Fragment>
                    <Container>
                        <MeasurementHistoryChart {...chartProps} />
                    </Container>
                    <hr />
                    <Table data-test="history-table">
                        {history.map(measurement => (
                            <MeasurementHistoryTableRow
                                key={measurement.date}
                                {...measurement}
                            />
                        ))}
                    </Table>
                </React.Fragment>
            );
        }

        return (
            <Container>
                <NoResults>
                    {t('measurments/alert-no-measurments-in-period')}
                </NoResults>
            </Container>
        );
    }

    render() {
        const { measurementType, periodLength } = this.state;

        return (
            <>
                <Container>
                    <MeasurementHistoryFilters
                        measurementType={measurementType}
                        periodLength={periodLength}
                        handlePeriodLengthChange={this.handlePeriodLengthChange}
                        handleMeasurementTypeChange={
                            this.handleMeasurementTypeChange
                        }
                    />
                </Container>

                {this.renderContent()}
            </>
        );
    }
}

export default withLocale(MeasurementHistoryContainer);
