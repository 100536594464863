import React from 'react';
import withSaleSources from 'view/SaleSources/withSaleSources';
import DataLayer from './DataLayer';

const GlobalDataLayerComponent = ({ getFirstSource }) =>
    [
        getFirstSource() && (
            <DataLayer key="DLsource" DLsource={getFirstSource()} />
        ),
    ].filter(a => a);

export { GlobalDataLayerComponent };
export default withSaleSources(GlobalDataLayerComponent);
