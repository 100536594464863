import { TRACKING_STOP, TRACKING_START } from './TrackingActionTypes';
import { getTrackingFromStore } from './TrackingStore';

const getInitialState = () => ({
    track: getTrackingFromStore(),
});

const SaleSourcesReducer = (state, action) => {
    let result;

    switch (action.type) {
        case TRACKING_STOP:
            result = {
                ...state,
                track: false,
            };
            break;
        case TRACKING_START:
            result = {
                ...state,
                track: true,
            };
            break;
        default:
            result = state || getInitialState();
    }

    return result;
};

export default SaleSourcesReducer;
