import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'Layout/Loader';
import { Redirect } from 'react-router';
import { PATHS } from 'config/paths';
import { Alert } from 'reactstrap';
import UserDataOnDemand from '../../../UserData/Connector/Apollo/Fetch/UserDataOnDemandConnectorApollo';
import setAuthCredentials from '../../setAuthCredentials';
import { withLocale } from '../../../TranslatorContext';
import RegisterViaFacebookConnectorApollo from './Connector/Apollo/RegisterViaFacebookConnectorApollo';
import AuthRegisterByFacebookTokenWrapperComponent from './AuthRegisterByFacebookTokenWrapperComponent';

class AuthRegisterByFacebookTokenComponent extends React.Component {
    static propTypes = {
        token: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
        returnPath: PropTypes.string,
    };

    static defaultProps = {
        returnPath: PATHS.CART.INDEX,
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            errors: [],
        };
    }

    registerViaFacebook = async (registerViaFacebook, userDataOnDemand) => {
        const response = await registerViaFacebook;

        if (
            response.data.auth.registerViaFacebook.__typename ===
            'OauthException'
        ) {
            if (response.data.auth.registerViaFacebook.code === 400) {
                this.setState(prevState => ({
                    errors: [
                        ...prevState.errors,
                        this.props.t('auth/login/user-exists'),
                    ],
                }));
            }
        } else {
            const userToken = response.data.auth.registerViaFacebook.token;
            setAuthCredentials(userToken);
            await userDataOnDemand();
        }

        this.setState({ isLoading: false });
    };

    render() {
        return (
            <React.Fragment>
                {this.state.errors.map(msg => (
                    <Alert color="danger" key={msg}>
                        {msg}
                    </Alert>
                ))}
                <RegisterViaFacebookConnectorApollo>
                    {result => (
                        <UserDataOnDemand>
                            {result2 => (
                                <AuthRegisterByFacebookTokenWrapperComponent
                                    registerViaFacebook={() =>
                                        this.registerViaFacebook(
                                            result.registerViaFacebook(
                                                this.props.token
                                            ),
                                            result2.userDataOnDemand
                                        )
                                    }
                                />
                            )}
                        </UserDataOnDemand>
                    )}
                </RegisterViaFacebookConnectorApollo>
                {this.state.isLoading ? (
                    <div className="full-height">
                        <Loader />
                    </div>
                ) : null}

                {!this.state.isLoading && !this.state.errors.length ? (
                    <Redirect to={this.props.returnPath} />
                ) : null}
            </React.Fragment>
        );
    }
}

export default withLocale(AuthRegisterByFacebookTokenComponent);
