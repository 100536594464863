import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import ContentQuery from './ContentQuery';

const ContentConnectorApollo = ({ identifier, execute, children }) => {
    if (!execute) {
        const result = {
            loading: false,
            content: {},
        };
        return children(result);
    }

    const { loading, error, data } = useQuery(ContentQuery, {
        variables: { identifier },
    });

    const result = {
        loading,
        error,
    };

    if (!loading) {
        result.content = { ...data.content };
    }

    return children(result);
};

ContentConnectorApollo.propTypes = {
    identifier: PropTypes.string.isRequired,
    execute: PropTypes.bool,
    children: PropTypes.func.isRequired,
};

ContentConnectorApollo.defaultProps = {
    execute: true,
};

export default ContentConnectorApollo;
