import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '../../../../../Apollo/Mutation';
import registerViaGoogleMutation from './RegisterViaGoogleMutation';
import { RETURN_URL_REGISTER } from '../../../../Login/ByExternalProvider/LoginReturnUrls';

class RegisterViaGoogleConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    registerViaGoogle = apiRegisterViaGoogle => async (
        token,
        authorizationCode = RETURN_URL_REGISTER
    ) => {
        const response = await apiRegisterViaGoogle({
            variables: {
                token,
                authorizationCode,
            },
        });

        return response;
    };

    render() {
        const { children } = this.props;
        return (
            <Mutation mutation={registerViaGoogleMutation}>
                {(registerViaGoogle, result) => {
                    const registerViaGoogleResult = {
                        registerViaGoogle: this.registerViaGoogle(
                            registerViaGoogle
                        ),
                        loading: result.loading,
                    };

                    return children(registerViaGoogleResult);
                }}
            </Mutation>
        );
    }
}

export default RegisterViaGoogleConnectorApollo;
