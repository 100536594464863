import React from 'react';
import { Container, Row, Col, Form, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { withLocale } from '../../../TranslatorContext';

export class NotificationsSettingsFormPlaceholder extends React.Component {
    render() {
        return (
            <Container
                id="settings-agreements"
                className={this.props.className}
            >
                <Row>
                    <Col md={{ size: 8, offset: 2 }}>
                        <Form>
                            <h2 className="heading-3">
                                {this.props.t('settings/notifications')}
                            </h2>

                            <div className="form-group">
                                <div className="custom-switch custom-control">
                                    <Label className="custom-control-label">
                                        {this.props.t(
                                            'settings/agreements/motivation-and-articles'
                                        )}
                                    </Label>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="custom-switch custom-control">
                                    <Label className="custom-control-label">
                                        {this.props.t(
                                            'settings/agreements/newsletter'
                                        )}
                                    </Label>
                                </div>
                            </div>
                        </Form>
                        <p>
                            <small>
                                {this.props.t(
                                    'settings/agreements/newsletter-info'
                                )}
                            </small>
                        </p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

NotificationsSettingsFormPlaceholder.propTypes = {
    t: PropTypes.func.isRequired,
    className: PropTypes.string,
};

NotificationsSettingsFormPlaceholder.defaultProps = {
    className: null,
};

export default withLocale(NotificationsSettingsFormPlaceholder);
