import React from 'react';

import { Consumer } from './FlashMessageContext';

const withFlashMessage = ChildComponent => props => (
    <Consumer>
        {({ addMessage, removeMessage, messages }) => (
            <ChildComponent
                {...props}
                addMessage={addMessage}
                removeMessage={removeMessage}
                messages={messages}
            />
        )}
    </Consumer>
);

export default withFlashMessage;
