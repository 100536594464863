import gql from 'graphql-tag';

export default gql`
    mutation ActivateAccount {
        me {
            activateAccount {
                ... on BasicMutationSuccess {
                    code
                }
            }
        }
    }
`;
