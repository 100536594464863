import removeAuthCredentials from '@dietlabs/components/src/Auth/removeAuthCredentials';

function logout() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(registrations => {
            registrations.forEach(registration => {
                registration.unregister();
            });
        });
    }

    if ('localStorage' in window) {
        window.localStorage.clear();
    }

    removeAuthCredentials();
}

window.addEventListener(
    'message',
    event => {
        if (event.data.action === 'app_logout') {
            logout();
        }
    },
    false
);

window.logout = logout;
