import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withOnCriticalError from 'view/ErrorBoundary/withOnCriticalError';
import Loader from 'Layout/Loader';
import isMobileApplication from 'components/Helpers/isMobileApplication';
import parseMobileApplication from 'components/Helpers/parseMobileApplication';
import { callNativeAppInfo } from 'components/Helpers/callNativeAppInfo';
import logo from 'assets/icons/logo.svg';

import { APP_STORE_URL, GOOGLE_PLAY_URL } from 'consts';
import { Container } from 'reactstrap';
import setAuthCredentials from '../../setAuthCredentials';

class AuthLoginByTokenComponent extends React.Component {
    static propTypes = {
        token: PropTypes.string,
        children: PropTypes.node.isRequired,
    };

    static defaultProps = {
        token: undefined,
    };

    state = {
        initialized: false,
        appInfo: undefined,
        displayUpdateMessage: false,
    };

    async componentDidMount() {
        const mobileApp = parseMobileApplication();
        global.localStorage.setItem('mobileApp', JSON.stringify(mobileApp));

        if (mobileApp.active) {
            if (this.props.token) {
                // is mobile and new version native application and token is set
                this.setState({ initialized: true });
            } else {
                // is mobile and new version native application and token is not set
                setTimeout(async () => {
                    if (!this.state.appInfo) {
                        this.setState({ initialized: true });
                    }
                }, 5000);

                window.addEventListener('message', this.receiveMessage, false);
                callNativeAppInfo();
            }
        } else {
            this.setState({ initialized: true });
        }
    }

    async componentDidUpdate() {
        if (!this.state.initialized && this.state.appInfo) {
            if (
                this.state.appInfo.payload &&
                this.state.appInfo.payload.authenticationToken
            ) {
                setAuthCredentials(
                    this.state.appInfo.payload.authenticationToken
                );
            }
            const mobileApp = {
                active: true,
                os: this.state.appInfo.payload.os,
                version: this.state.appInfo.payload.appVersion,
            };
            global.localStorage.setItem('mobileApp', JSON.stringify(mobileApp));
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ initialized: true });
        }
    }

    receiveMessage = event => {
        if (
            !this.state.initialized &&
            event.data.action === 'response_app_info'
        ) {
            this.setState({ appInfo: event.data });
        }
    };

    renderUpdateApp = () => {
        let storeUrl = '';

        if (isMobileApplication() === 'ios') {
            storeUrl = APP_STORE_URL;
        } else if (isMobileApplication() === 'android') {
            storeUrl = GOOGLE_PLAY_URL;
        }

        return (
            <div className="page-template-update">
                <div className="full-screen">
                    <section>
                        <header>
                            <Container>
                                <h1 className="text-center">
                                    Zaktualizuj aplikację
                                </h1>
                            </Container>
                        </header>
                        <main>
                            <Container className="text-center">
                                <img src={logo} alt="" />
                                <p>
                                    Kliknij w przycisk poniżej aby zaktualizować
                                    aplikację.
                                </p>
                                <a
                                    href={storeUrl}
                                    className="btn btn-secondary"
                                >
                                    Zaktualizuj aplikację
                                </a>
                            </Container>
                        </main>
                    </section>
                </div>
            </div>
        );
    };

    render() {
        if (this.state.displayUpdateMessage) {
            return this.renderUpdateApp();
        }

        return (
            <Fragment>
                {this.state.initialized ? (
                    this.props.children
                ) : (
                    <div className="full-height">
                        <Loader />
                    </div>
                )}
            </Fragment>
        );
    }
}

export { AuthLoginByTokenComponent };
export default withOnCriticalError(AuthLoginByTokenComponent);
