import React from 'react';

import ArticleFetchOnDemandConnectorApollo from '../Connector/FetchOnDemand/ArticleFetchOnDemandConnectorApollo';
import ArticleListFetchConnectorApollo from '../Connector/FetchList/ArticleListFetchConnectorApollo';
import ArticleFetchListOnDemandConnectorApollo from '../Connector/FetchListOnDemand/ArticleListFetchOnDemandConnectorApollo';
import ArticleListContainer from './ArticleListContainer';
import { ARTICLES_PER_PAGE } from '../consts';

class ArticleListPage extends React.Component {
    static propTypes = {};

    render() {
        return (
            <ArticleFetchListOnDemandConnectorApollo>
                {loadArticleList => (
                    <ArticleListFetchConnectorApollo
                        category={null}
                        articlesPerPage={ARTICLES_PER_PAGE}
                    >
                        {({ ...articleList }) => (
                            <ArticleFetchOnDemandConnectorApollo>
                                {loadArticle => (
                                    <ArticleListContainer
                                        {...articleList}
                                        category="all"
                                        loadArticle={loadArticle}
                                        loadArticleList={loadArticleList}
                                        articlesPerPage={ARTICLES_PER_PAGE}
                                    />
                                )}
                            </ArticleFetchOnDemandConnectorApollo>
                        )}
                    </ArticleListFetchConnectorApollo>
                )}
            </ArticleFetchListOnDemandConnectorApollo>
        );
    }
}

export default ArticleListPage;
