import React from 'react';

import PropTypes from 'prop-types';
import InputCheckbox from 'components/FormElements/InputCheckboxControlled2';
import ValidationErrors from 'view/Validation/ValidationErrors';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import { validateField } from 'view/Validation/validateField';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import withLoading from 'Layout/withLoading';
import { withLocale } from '../../../TranslatorContext';
import ExternalProviderPlaceholder from './ExternalProviderPlaceholder';

class ExternalProvider extends React.Component {
    static propTypes = {
        facebookUrl: PropTypes.string,
        googleUrl: PropTypes.string,
        appleUrl: PropTypes.string,
        displayAppleButton: PropTypes.bool,
        displayFacebookButton: PropTypes.bool,
        displayGoogleButton: PropTypes.bool,
        state: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
        checkAcceptContitions: PropTypes.func,
        roundedBtn: PropTypes.bool,
        fullWidth: PropTypes.bool,
        trackFirebaseEvent: PropTypes.func.isRequired,
        id: PropTypes.string,
    };

    static defaultProps = {
        displayAppleButton: true,
        displayFacebookButton: true,
        displayGoogleButton: true,
        facebookUrl: null,
        googleUrl: null,
        appleUrl: null,
        checkAcceptContitions: undefined,
        roundedBtn: false,
        fullWidth: false,
        id: undefined,
    };

    state = {
        acceptConditions: false,
        errors: new ValidationErrors(),
    };

    validationRules = {
        acceptConditions: [
            new RequiredRule({
                translator: this.props.t,
                messages: {
                    empty: this.props.t(
                        'cart/accept-condition/validation/required'
                    ),
                },
            }),
        ],
    };

    validateInput = (field, value) => {
        if (this.validationRules[field]) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    details: validateField(
                        field,
                        value,
                        this.validationRules[field],
                        prevState
                    ),
                },
            }));
        }
    };

    handleAcceptConditions = event => {
        this.setState({ [event.target.name]: event.target.checked });
        this.validateInput(event.target.name, event.target.checked);
    };

    handleClick(event, url, platform) {
        event.preventDefault();
        if (this.props.state === 'register') {
            this.validateInput('acceptConditions', this.state.acceptConditions);

            if (this.state.acceptConditions) {
                window.location = url;
            }
        } else if (this.props.state === 'cart_register') {
            if (this.props.checkAcceptContitions()) {
                window.location = url;
            }
        } else if (this.props.state === 'stripe') {
            this.props.trackFirebaseEvent('user_action', {
                action: 'tap-stripe_signup',
                location: 'stripe_signup',
                stripe_gdpr: this.props.checkAcceptContitions(),
                used_account: platform,
            });

            if (this.props.checkAcceptContitions()) {
                window.location = url;
            }
        } else if (this.props.state === 'stripe_login') {
            this.props.trackFirebaseEvent('user_action', {
                action: 'tap-stripe_login',
                location: 'stripe_login ',
                used_account: platform,
            });
            window.location = url;
        } else {
            window.location = url;
        }
    }

    render() {
        const btnClassName = [
            'btn',
            'btn-secondary',
            'btn-social',
            this.props.roundedBtn ? 'rounded' : '',
            this.props.fullWidth ? 'w-100' : '',
        ].join(' ');
        return (
            <React.Fragment>
                {this.props.displayFacebookButton &&
                this.props.facebookUrl !== null ? (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                        className={`${btnClassName} facebook`}
                        href="#"
                        onClick={event =>
                            this.handleClick(
                                event,
                                this.props.facebookUrl,
                                'facebook'
                            )
                        }
                    >
                        {this.props.t('auth/login/with-facebook')}
                    </a>
                ) : null}
                {this.props.displayGoogleButton &&
                this.props.googleUrl !== null ? (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                        className={`${btnClassName} google`}
                        href="#"
                        onClick={event =>
                            this.handleClick(
                                event,
                                this.props.googleUrl,
                                'google'
                            )
                        }
                    >
                        {this.props.t('auth/login/with-google')}
                    </a>
                ) : null}
                {this.props.displayAppleButton &&
                this.props.appleUrl !== null ? (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                        className={`${btnClassName} apple`}
                        href="#"
                        onClick={event =>
                            this.handleClick(
                                event,
                                this.props.appleUrl,
                                'apple'
                            )
                        }
                    >
                        {this.props.t('auth/login/with-apple')}
                    </a>
                ) : null}

                {this.props.state === 'register' ? (
                    <InputCheckbox
                        label={[
                            <span key="agreement1">
                                {this.props.t(
                                    'agreements/terms-and-conditions/text'
                                )}
                            </span>,
                            <a key="agreement2" href="/terms-of-service">
                                {this.props.t(
                                    'agreements/terms-and-conditions/text2'
                                )}
                            </a>,
                            <span key="agreement3">
                                {this.props.t(
                                    'agreements/terms-and-conditions/text3'
                                )}
                            </span>,
                            <a key="agreement4" href="/privacy-policy">
                                {this.props.t(
                                    'agreements/terms-and-conditions/text4'
                                )}
                            </a>,
                            <span key="agreement5">
                                {this.props.t(
                                    'agreements/terms-and-conditions/text5'
                                )}
                            </span>,
                            <sup key="sup">*</sup>,
                        ]}
                        id={this.props.id ? this.props.id : 'acceptConditions'}
                        name="acceptConditions"
                        value={this.state.acceptConditions}
                        handleChange={this.handleAcceptConditions}
                        checked={this.state.acceptConditions}
                        errors={this.state.errors}
                        validationRules={this.validationRules.acceptConditions}
                        data-test="terms-and-condition-agreements-input"
                        className="left-side"
                    />
                ) : (
                    ''
                )}
            </React.Fragment>
        );
    }
}

export default withLoading(
    withLocale(withFirebase(ExternalProvider)),
    ExternalProviderPlaceholder
);
