const config = {
    apiGateway: {
        url: process.env.REACT_APP_GRAPHQL_URL,
    },
    flags: {
        cart: true,
    },
};

export default config;
export const { flags } = config;
