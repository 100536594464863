import { Component } from 'react';
import { shape, func, element, oneOf, arrayOf } from 'prop-types';
import withGTM from './withGTM';

class DataLayer extends Component {
    static propTypes = {
        GTM: shape({
            api: shape({
                setDataLayer: func.isRequired,
            }).isRequired,
        }).isRequired,
        children: oneOf([element, arrayOf(element)]),
    };

    static defaultProps = {
        children: null,
    };

    componentDidMount() {
        this.updateDataLayer();
    }

    componentDidUpdate() {
        this.updateDataLayer();
    }

    updateDataLayer() {
        const { children, ...data } = this.props.filterNonGtmProps(this.props);

        this.props.GTM.api.setDataLayer(data);
    }

    render() {
        return this.props.children;
    }
}

export { DataLayer };

export default withGTM(DataLayer);
