import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { withLocale } from '../../../../TranslatorContext';

class HydrationAddButton extends Component {
    static propTypes = {
        value: PropTypes.number,
        unit: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
        icon: PropTypes.string.isRequired,
        className: PropTypes.string,
    };

    static defaultProps = {
        value: undefined,
        className: '',
    };

    render() {
        return (
            <Button
                color="placeholder"
                className={`add-btn ${this.props.className}`}
            >
                <img src={this.props.icon} alt="" />

                {this.props.value
                    ? `${this.props.value} ${this.props.unit}`
                    : this.props.t('hydration/add/custom')}
            </Button>
        );
    }
}

export default withLocale(HydrationAddButton);
