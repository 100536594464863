import gql from 'graphql-tag';

export default gql`
    mutation MeasurementGroupSaveMutation(
        $request: UserMeasurementGroupSaveInputType
    ) {
        me {
            measurementGroupSave(request: $request) {
                ... on UnauthorizedException {
                    code
                }
                ... on ValidationException {
                    code
                    messages
                    details {
                        fieldName
                        messages
                    }
                }
                ... on UserMeasurementGroupSaveSuccess {
                    code
                    goal {
                        reachedBecauseOfLoseWeight
                        reachedBecauseOfPutOnWeight
                        lostBecauseOfLoseWeight
                        lostBecauseOfPutOnWeight
                    }
                }
            }
        }
    }
`;
