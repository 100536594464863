export default function isMobileApplication() {
    if (navigator.userAgent.includes('MobileApplication')) {
        if (navigator.userAgent.includes('iOS')) {
            return 'ios';
        }
        if (navigator.userAgent.includes('Android')) {
            return 'android';
        }
    }

    return false;
}
