export default class RequiredRule {
    constructor(options = {}) {
        const t = options.translator;
        if (!t) {
            throw new Error('translator option is required in RequiredRule');
        }
        this.messages = {
            empty: t('validation/required'),
            ...options.messages,
        };
    }

    isValid = value => {
        if (value === '' || value === false || value == null) {
            return false;
        }
        return true;
    };

    getErrorMsg() {
        return this.messages.empty;
    }
}
