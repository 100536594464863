import React from 'react';
import PropTypes from 'prop-types';
import { FullMeasurementType } from 'types/CommonTypes';
import { Container, Row, Col } from 'reactstrap';
import humanBody from 'assets/images/measurements/human_body.jpg';
// eslint-disable-next-line import/no-cycle
import CurrentMeasurementsBox from './CurrentMeasurementsBox';
import CachedDataMessage from '../../../CachedDataMessage/CachedDataMessage';
import { withLocale } from '../../../TranslatorContext';

export class CurrentMeasurementsContainer extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        // loading: PropTypes.bool.isRequired,
        weight: FullMeasurementType,
        waist: FullMeasurementType,
        thigh: FullMeasurementType,
        rightthigh: FullMeasurementType,
        chest: FullMeasurementType,
        arm: FullMeasurementType,
        rightarm: FullMeasurementType,
        cacheHit: PropTypes.bool.isRequired,
        hasNetworkError: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        weight: undefined,
        waist: undefined,
        thigh: undefined,
        rightthigh: undefined,
        chest: undefined,
        arm: undefined,
        rightarm: undefined,
    };

    render() {
        const {
            t,
            weight,
            arm,
            rightarm,
            waist,
            chest,
            thigh,
            rightthigh,
        } = this.props;

        return (
            <Container>
                <CachedDataMessage
                    cacheHit={this.props.cacheHit}
                    hasNetworkError={this.props.hasNetworkError}
                />

                <Row>
                    <Col xs="12" sm={{ size: 6, offset: 3 }}>
                        <div className="human-body text-center">
                            <img
                                src={humanBody}
                                style={{ width: '212px', height: 'auto' }}
                                alt=""
                            />

                            <CurrentMeasurementsBox
                                measures={[{ ...chest }]}
                                label={t('measurments/param/chest')}
                            />

                            <CurrentMeasurementsBox
                                measures={[
                                    { ...arm, prefix: 'L' },
                                    { ...rightarm, prefix: 'P' },
                                ]}
                                label={t('measurments/param/arms')}
                            />

                            <CurrentMeasurementsBox
                                measures={[
                                    { ...thigh, prefix: 'L' },
                                    { ...rightthigh, prefix: 'P' },
                                ]}
                                label={t('measurments/param/thighs')}
                            />

                            <CurrentMeasurementsBox
                                measures={[{ ...waist }]}
                                label={t('measurments/param/waist')}
                            />

                            <CurrentMeasurementsBox
                                measures={[{ ...weight }]}
                                label={t('measurments/param/weight')}
                            />
                        </div>
                        <hr />
                    </Col>
                </Row>
            </Container>
        );
    }
}
export default withLocale(CurrentMeasurementsContainer);
