import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { PATHS } from 'config/paths';
import UserDataOnDemand from '../../../UserData/Connector/Apollo/Fetch/UserDataOnDemandConnectorApollo';
import AuthLoginByEmailAndPassword from './AuthLoginByEmailAndPassword';
import LoginConnector from './Connector/Apollo/Login/LoginConnectorApollo';

class AuthLoginByEmailAndPasswordPage extends React.Component {
    static propTypes = {
        location: PropTypes.shape({
            state: PropTypes.shape({
                from: PropTypes.shape({
                    pathname: PropTypes.string,
                }),
            }),
        }).isRequired,
    };

    render() {
        const isLoggedIn = !!localStorage.getItem('token');

        let redirectToPathAfterSuccess;
        if (
            this.props.location.state &&
            this.props.location.state.from.pathname !== '/auth/logout' &&
            this.props.location.state.from.pathname !== '/auth/login'
        ) {
            redirectToPathAfterSuccess = this.props.location.state.from
                .pathname;
        } else {
            redirectToPathAfterSuccess = PATHS.DEFAULT_PAGE_AFTER_LOGGED_IN;
        }

        return (
            <LoginConnector>
                {({ ...login }) => (
                    <UserDataOnDemand>
                        {({ ...userDataOnDemand }) => (
                            <AuthLoginByEmailAndPassword
                                {...login}
                                {...userDataOnDemand}
                                isLoggedIn={isLoggedIn}
                                redirectToPathAfterSuccess={
                                    redirectToPathAfterSuccess
                                }
                            />
                        )}
                    </UserDataOnDemand>
                )}
            </LoginConnector>
        );
    }
}

export default withRouter(AuthLoginByEmailAndPasswordPage);
