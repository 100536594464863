import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '../../../../../../Apollo/Mutation';
import LoginMutation from './LoginMutation';

class LoginConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    createLogin = mutate => async request => {
        const variables = request;

        const response = await mutate({
            variables,
        });

        return response;
    };

    render() {
        const { children } = this.props;

        return (
            <Mutation mutation={LoginMutation}>
                {mutate =>
                    children({
                        login: this.createLogin(mutate),
                    })
                }
            </Mutation>
        );
    }
}

export default LoginConnectorApollo;
