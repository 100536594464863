import { SALE_SOURCES_NAMESPACE as NAMESPACE } from '../SaleSourcesNamespace';
import { FIRST, LAST } from '../SaleSourcesNames';
import { nullifyStaleSources } from '../SaleSourcesUtils';

// This function returns a function, so that it’s body is executed only
// when action is dispatched and not when it’s created
const saleSourcesGet = () => (dispatch, getState) => {
    const sources = nullifyStaleSources(getState()[NAMESPACE] || {});
    const { [FIRST]: firstSource, [LAST]: lastSource } = sources;

    return {
        [FIRST]: firstSource == null ? null : firstSource.value,
        [LAST]: lastSource == null ? null : lastSource.value,
    };
};

export { saleSourcesGet };
