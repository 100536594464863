import React from 'react';
import PropTypes from 'prop-types';
import { Query as ApolloQuery } from 'react-apollo';
import { NetworkStatus } from 'apollo-client';
import { reportError } from '@dietlabs/utils';
import { isQueryCached } from './utils';

const queryPropType = (props, propName, componentName) => {
    const prop = props[propName];
    if (prop.kind !== 'Document') {
        return new Error(
            `Prop ${propName} supplied to ${componentName} is not GraphQL document.`
        );
    }
    return null;
};
queryPropType.isRequired = (props, propName, componentName) => {
    if (!Object.prototype.hasOwnProperty.call(props, propName)) {
        return new Error(
            `Required prop ${propName} not supplied to ${componentName}.`
        );
    }
    return queryPropType(props, propName, componentName);
};

class Query extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        // react/require-default-props
        query: queryPropType.isRequired,
        fetchPolicy: PropTypes.oneOf([
            // https://www.apollographql.com/docs/react/api/react-apollo/#optionsfetchpolicy
            'cache-first',
            'cache-and-network',
            'network-only',
            'cache-only',
            'no-cache',
        ]),
    };

    static defaultProps = {
        fetchPolicy: 'cache-and-network',
    };

    renderChildren({
        loading,
        error,
        client,
        variables,
        networkStatus,
        data,
        ...otherArgs
    }) {
        const { children, query } = this.props;

        if (
            ![
                NetworkStatus.loading,
                NetworkStatus.setVariables,
                NetworkStatus.fetchMore,
                NetworkStatus.refetch,
                NetworkStatus.poll,
                NetworkStatus.ready,
                NetworkStatus.error,
            ].includes(networkStatus)
        ) {
            throw new Error(`Unknown network status: '${networkStatus}'.`);
        }

        // don't rely on cached result for non-cache policies
        let isCached = false;
        if (
            this.props.fetchPolicy !== 'no-cache' &&
            this.props.fetchPolicy !== 'network-only'
        ) {
            isCached = isQueryCached(client, query, variables);
        }

        // data contains cached query
        const cacheHit = isCached && networkStatus !== NetworkStatus.ready;

        if (error) {
            if (!isCached) {
                throw error;
            }
            reportError(error);
        }

        return children({
            loading: loading && !cacheHit,
            cacheHit,
            error,
            hasNetworkError: !!error,
            client,
            variables,
            networkStatus,
            data,
            ...otherArgs,
        });
    }

    render() {
        return (
            <ApolloQuery {...this.props}>
                {args => this.renderChildren(args)}
            </ApolloQuery>
        );
    }
}

export default Query;
