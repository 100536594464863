import { Component } from 'react';
import { withRouter } from 'react-router';
import { bool, func, shape, string } from 'prop-types';
import withGTM from '../withGTM';

class RouterPageView extends Component {
    static propTypes = {
        location: shape({
            pathname: string.isRequired,
            search: string,
        }).isRequired,
        registerOnMount: bool,
        registerOnChangedOnly: bool,
        GTM: shape({
            api: shape({
                trigger: func.isRequired,
            }).isRequired,
        }).isRequired,
    };

    static defaultProps = {
        registerOnMount: true,
        registerOnChangedOnly: true,
    };

    componentDidMount() {
        if (this.props.registerOnMount) {
            this.registerPageView();
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.registerOnChangedOnly === false ||
            prevProps.location.pathname !== this.props.location.pathname ||
            prevProps.location.search !== this.props.location.search
        ) {
            this.registerPageView();
        }
    }

    registerPageView() {
        this.props.GTM.api.trigger({
            event: 'pageview',
        });
    }

    render() {
        return null;
    }
}

export { RouterPageView };

export default withRouter(withGTM(RouterPageView));
