import React, { PureComponent } from 'react';
import ReactTagManager from 'react-tag-manager';
import { bool, string, node, shape, arrayOf, oneOfType } from 'prop-types';
import * as console from 'utils/console';

class GoogleTagManagerComponent extends PureComponent {
    static propTypes = {
        track: bool.isRequired,
        config: shape({
            id: string,
        }).isRequired,
        children: oneOfType([node, arrayOf(node)]).isRequired,
    };

    render() {
        if (!this.props.track) {
            return this.props.children;
        }

        if (!this.props.config || !this.props.config.id) {
            console.info('Google Tag Manager configuration missing.');
            return this.props.children;
        }

        return (
            <ReactTagManager gtm={this.props.config}>
                {this.props.children}
            </ReactTagManager>
        );
    }
}

export default GoogleTagManagerComponent;
