/* eslint-disable class-methods-use-this */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import SEO from 'SEO/SEO';
import { withRouter } from 'react-router';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import GetActiveMobileAppTabIdentifier from 'view/GetActiveMobileAppTabIdentifier/GetActiveMobileAppTabIdentifier';
import GetMobileAppPageTitle from 'view/GetMobileAppPageTitle/GetMobileAppPageTitle';
import { callNativeAppRouteChanged } from 'components/Helpers/callNativeAppRouteChanged';
import PromoCodeHandlerContainer from './view/PromoCode/PromoCodeHandlerContainer';
import { APP_TAB_MORE } from './consts';

class RouteChildren extends Component {
    static propTypes = {
        render: PropTypes.func,
        component: PropTypes.elementType,
        title: PropTypes.string.isRequired,
        mobileTitle: PropTypes.string,
        mobileAppTabIdentifier: PropTypes.string,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            search: PropTypes.string,
        }).isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
    };

    static defaultProps = {
        render: undefined,
        component: undefined,
        mobileTitle: undefined,
        mobileAppTabIdentifier: APP_TAB_MORE,
    };

    state = {
        isMounted: false,
    };

    componentDidMount() {
        const { title, mobileAppTabIdentifier, mobileTitle } = this.props;

        callNativeAppRouteChanged(
            window.location.pathname,
            mobileAppTabIdentifier || APP_TAB_MORE,
            mobileTitle || title
        );

        this.setState({ isMounted: true });
        this.registerPageViewInFirebase();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.location.pathname !== this.props.location.pathname ||
            prevProps.location.search !== this.props.location.search
        ) {
            this.registerPageViewInFirebase();
        }
    }

    registerPageViewInFirebase() {
        this.props.trackFirebaseEvent('screen_view ', {
            firebase_screen: this.props.location.pathname,
        });
    }

    render() {
        const {
            component: ChildComponent,
            title,
            mobileAppTabIdentifier,
            mobileTitle,
            render,
            ...rest
        } = this.props;

        let body = '';
        if (ChildComponent) {
            body = (
                <ChildComponent
                    title={title}
                    routeChildrenMounted={this.state.isMounted}
                />
            );
        } else if (render) {
            body = render(rest);
        }

        return (
            <Fragment>
                <GetActiveMobileAppTabIdentifier
                    mobileAppTabIdentifier={
                        mobileAppTabIdentifier || APP_TAB_MORE
                    }
                />
                <GetMobileAppPageTitle title={mobileTitle || title} />
                <SEO title={title} />
                <PromoCodeHandlerContainer />
                {body}
            </Fragment>
        );
    }
}

export default withFirebase(withRouter(RouteChildren));
