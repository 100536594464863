import React, { Component } from 'react';
import { Row, Col, Button, Form, Alert } from 'reactstrap';
import InputText from 'components/FormElements/InputTextControlled';
import InputTextarea from 'components/FormElements/InputTextareaControlled';
import InputEmail from 'components/FormElements/InputEmailControlled';
import Select from 'components/FormElements/InputSelectControlled';
import Loader from 'Layout/Loader';

import ValidationErrors from 'view/Validation/ValidationErrors';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import EmailFormatRule from 'view/Validation/ValidationRules/EmailFormatRule';

import { validateFields } from 'view/Validation/validateFields';
import { validateField } from 'view/Validation/validateField';

import PropTypes from 'prop-types';
import { withLocale } from '../../../TranslatorContext';

class ContactForm extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        contactEmail: PropTypes.shape({
            sendEmail: PropTypes.func,
            response: PropTypes.shape({
                contact: PropTypes.shape({
                    __typename: PropTypes.string,
                    details: PropTypes.string,
                    code: PropTypes.number,
                }),
            }),
            loading: PropTypes.bool,
            error: PropTypes.shape(),
        }).isRequired,
    };

    validationRules = {
        name: [new RequiredRule({ translator: this.props.t })],
        email: [
            new RequiredRule({ translator: this.props.t }),
            new EmailFormatRule({ translator: this.props.t }),
        ],
        topic: [new RequiredRule({ translator: this.props.t })],
        message: [new RequiredRule({ translator: this.props.t })],
    };

    state = {
        name: this.props.name,
        email: this.props.email,
        topic: '',
        message: '',
        preload: false,
        errors: new ValidationErrors(),
        success: false,
    };

    handleInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });

        if (this.validationRules[event.target.name]) {
            const field = event.target.name;
            const value = event.target.value;

            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    details: validateField(
                        field,
                        value,
                        this.validationRules[field],
                        prevState
                    ),
                },
            }));
        }
    };

    handleSubmit = async event => {
        event.preventDefault();

        const frontEndErrors = validateFields(
            this.validationRules,
            this.state,
            this.props.t
        );

        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                details: frontEndErrors,
            },
        }));

        if (frontEndErrors.length === 0) {
            this.setState({ preload: true });

            const request = {
                name: this.state.name,
                email: this.state.email,
                topic: this.state.topic,
                message: this.state.message,
            };

            try {
                await this.props.contactEmail.sendEmail(request);
                const {
                    code,
                    details,
                } = this.props.contactEmail.response.contact;

                if (code === 200) {
                    this.setState({
                        name: '',
                        email: '',
                        topic: '',
                        message: '',
                        errors: new ValidationErrors(),
                        success: true,
                        preload: false,
                    });
                } else {
                    for (let i = 0; i < details.length; i += 1) {
                        const error = details[i];
                        if (error.fieldName in this.validationRules) {
                            this.setState({
                                errors: this.props.contactEmail.response
                                    .contact,
                            });
                            break;
                        } else {
                            this.setState({ errors: new ValidationErrors() });
                        }
                    }

                    this.setState({
                        success: false,
                        preload: false,
                    });
                }
            } catch (e) {
                this.setState({ success: false, preload: false });
                throw new Error(`Failed to send email, got error: ${e}`);
            }
        }
    };

    render() {
        return (
            <section ref={this.form}>
                <Row>
                    <Col xs="12" md={{ size: 6, offset: 3 }}>
                        <h2>{this.props.t('contact/contact-form')}</h2>
                        <Form onSubmit={this.handleSubmit} noValidate>
                            <InputText
                                label={this.props.t('contact/name-surname')}
                                name="name"
                                value={this.state.name}
                                errors={this.state.errors}
                                handleChange={this.handleInputChange}
                                validationRules={this.validationRules.name}
                            />

                            <InputEmail
                                label={this.props.t('contact/email-address')}
                                name="email"
                                value={this.state.email}
                                errors={this.state.errors}
                                handleChange={this.handleInputChange}
                                validationRules={this.validationRules.email}
                            />

                            <Select
                                label={this.props.t('contact/topic')}
                                name="topic"
                                handleChange={this.handleInputChange}
                                value={this.state.topic}
                                validationRules={this.validationRules.topic}
                                errors={this.state.errors}
                            >
                                <option value="" />
                                <option
                                    value={this.props.t('contact/topic/topic1')}
                                >
                                    {this.props.t('contact/topic/topic1')}
                                </option>
                                <option
                                    value={this.props.t('contact/topic/topic2')}
                                >
                                    {this.props.t('contact/topic/topic2')}
                                </option>
                                <option
                                    value={this.props.t('contact/topic/topic3')}
                                >
                                    {this.props.t('contact/topic/topic3')}
                                </option>
                                <option
                                    value={this.props.t('contact/topic/topic4')}
                                >
                                    {this.props.t('contact/topic/topic4')}
                                </option>
                            </Select>

                            <InputTextarea
                                label={this.props.t('contact/message')}
                                name="message"
                                value={this.state.message}
                                errors={this.state.errors}
                                handleChange={this.handleInputChange}
                                validationRules={this.validationRules.message}
                            />

                            <div className="text-center">
                                <Button color="primary">
                                    {this.props.t('contact/send-message')}
                                </Button>
                            </div>

                            {this.state.success ? (
                                <Alert color="success" className="mt-3">
                                    {this.props.t('contact/send-success')}
                                </Alert>
                            ) : (
                                ''
                            )}
                        </Form>
                    </Col>
                </Row>
                {this.state.preload ? <Loader /> : ''}
            </section>
        );
    }
}

export default withLocale(ContactForm);
