import React from 'react';
import NewsletterUnsubscribeConnectorApollo from './Connector/Apollo/NewsletterUnsubscribeConnectorApollo';
import NewsletterUnsubscribeContainer from './NewsletterUnsubscribeContainer';

class NewsletterUnsubscribePage extends React.Component {
    static propTypes = {};

    render() {
        return (
            <NewsletterUnsubscribeConnectorApollo>
                {({ ...unsubscribe }) => (
                    <NewsletterUnsubscribeContainer {...unsubscribe} />
                )}
            </NewsletterUnsubscribeConnectorApollo>
        );
    }
}

export default NewsletterUnsubscribePage;
