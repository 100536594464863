import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import CurrentMeasurementsQuery from './CurrentMeasurementsQuery';

const CurrentMeasurementsConnectorApollo = ({ children }) => {
    const { loading, error, data } = useQuery(CurrentMeasurementsQuery);

    const result = {
        loading,
        error,
    };

    const getFormatedMeasurement = rawMeasurement => ({
        change: rawMeasurement.change,
        date: rawMeasurement.date,
        unit: rawMeasurement.unit,
        value: rawMeasurement.value,
    });

    if (!loading) {
        const measurements = data.me.currentMeasurements;

        result.weight = measurements.weight[0]
            ? getFormatedMeasurement(measurements.weight[0])
            : undefined;
        result.arm = measurements.arm[0]
            ? getFormatedMeasurement(measurements.arm[0])
            : undefined;
        result.chest = measurements.chest[0]
            ? getFormatedMeasurement(measurements.chest[0])
            : undefined;
        result.thigh = measurements.thigh[0]
            ? getFormatedMeasurement(measurements.thigh[0])
            : undefined;
        result.waist = measurements.waist[0]
            ? getFormatedMeasurement(measurements.waist[0])
            : undefined;
        result.rightthigh = measurements.rightthigh[0]
            ? getFormatedMeasurement(measurements.rightthigh[0])
            : undefined;
        result.rightarm = measurements.rightarm[0]
            ? getFormatedMeasurement(measurements.rightarm[0])
            : undefined;
    }

    return children(result);
};

CurrentMeasurementsConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default CurrentMeasurementsConnectorApollo;
