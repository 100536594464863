import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import deepClone from 'utils/deepClone';
import { formatIsoDate } from 'utils/dateFormatter';
import flattenValidationExceptionMessages from '../../../utils/flattenValidationExceptionMessages';

import hydrationChangeGoalMutation from './HydrationDayChangeGoalMutation';

const HydrationDayChangeGoalConnectorApollo = ({
    date,
    queryResult,
    children,
}) => {
    const [mutate, { data }] = useMutation(hydrationChangeGoalMutation);

    let changeGoalMessages = [];
    if (data) {
        changeGoalMessages = flattenValidationExceptionMessages(
            data.me.hydrationDayChangeGoal
        );
    }

    const createChangeGoal = (goal, unit) => {
        const variables = {
            date: formatIsoDate(date),
            goal,
            unit,
        };

        const { hydrationDay: hydrationDayResult, ...meResult } = deepClone(
            queryResult.me
        );
        const optimisticResponse = {
            me: {
                ...meResult,
                hydrationDayChangeGoal: {
                    ...hydrationDayResult,
                    goal: {
                        __typename: 'Volume',
                        value: goal,
                        unit,
                    },
                },
            },
        };

        return mutate({
            variables,
            optimisticResponse,
            update: (cache, { data: response }) => {
                // eslint-disable-next-line no-shadow
                const goal = response.me.hydrationDayChangeGoal.goal;
                cache.modify({
                    id: cache.identify(queryResult.me),
                    fields: {
                        hydrationDay(hydrationDay) {
                            return {
                                ...hydrationDay,
                                goal: {
                                    __typename: 'Volume',
                                    value: goal.value,
                                    unit: goal.unit,
                                },
                            };
                        },
                    },
                    broadcast: true,
                });
            },
        });
    };

    return children(createChangeGoal, changeGoalMessages);
};

HydrationDayChangeGoalConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    queryResult: PropTypes.object.isRequired,
};

export default HydrationDayChangeGoalConnectorApollo;
