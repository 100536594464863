import RemoveAccountRequestPage from './RemoveAccountRequestIndexPage';
import RemoveAccountConfirmPage from './RemoveAccountConfirmIndexPage';
import {
    PATH_REMOVE_ACCOUNT_REQUEST,
    PATH_REMOVE_ACCOUNT_CONFIRM,
} from './paths';

const routes = [
    {
        path: PATH_REMOVE_ACCOUNT_REQUEST,
        component: RemoveAccountRequestPage,
        private: true,
        exact: true,
        title: 'remove-account/title',
        mobileAppTabIdentifier: 'more',
    },
    {
        path: PATH_REMOVE_ACCOUNT_CONFIRM,
        component: RemoveAccountConfirmPage,
        private: false,
        exact: true,
        title: 'remove-account/title',
        mobileAppTabIdentifier: 'more',
    },
];

export { PATH_REMOVE_ACCOUNT_REQUEST, PATH_REMOVE_ACCOUNT_CONFIRM, routes };
