import React from 'react';
import PropTypes from 'prop-types';
import Query from '../../../../../Apollo/Query';
import GetAuthorizationUrlsQuery from './GetAuthorizationUrlsQuery';

export default class GetAuthorizationUrlsApolloConnector extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        returnUrl: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
    };

    render() {
        const { children } = this.props;

        const result = {
            facebookUrl: null,
            googleUrl: null,
            appleUrl: null,
            loading: true,
        };

        return (
            <Query
                query={GetAuthorizationUrlsQuery}
                variables={{
                    returnUrl: this.props.returnUrl,
                    state: this.props.state,
                }}
                fetchPolicy="network-only"
            >
                {({ loading, error, data }) =>
                    !loading && !error
                        ? children({
                              ...result,
                              facebookUrl:
                                  data.auth.authorizationUrl.facebookUrl,
                              googleUrl: data.auth.authorizationUrl.googleUrl,
                              appleUrl: data.auth.authorizationUrl.appleUrl,
                              loading,
                          })
                        : children({
                              ...result,
                              loading,
                          })
                }
            </Query>
        );
    }
}
