import getLanguage from 'utils/getLanguage';

let scope = global;

// For testing purposes
const changeScope = newScope => {
    scope = newScope;
    return scope;
};

const supportsDateFormat = () =>
    scope.Intl &&
    typeof scope.Intl === 'object' &&
    typeof scope.Intl.DateTimeFormat === 'function';

const longDateFormattersByLang = new Map();
const mediumDateFormattersByLang = new Map();
const shortDateFormattersByLang = new Map();

const createDateObject = input => {
    if (input == null) {
        return null;
    }

    if (typeof input === 'string' || input instanceof String) {
        const splitedDate = input.split('-');
        const date = new Date(
            parseInt(splitedDate[0], 10),
            parseInt(splitedDate[1], 10) - 1,
            parseInt(splitedDate[2], 10)
        );

        if (input.length !== 10 || Number.isNaN(new Date(input).getTime())) {
            throw new Error(`Failed to parse string as a date: ${input}`);
        }
        return date;
    }

    if (!(input instanceof Date)) {
        throw new Error('Non-date passed to date formatter.');
    }

    return input;
};

const getLongDateFormatter = lang => {
    let formatter = longDateFormattersByLang.get(lang);
    if (!formatter) {
        formatter = new Intl.DateTimeFormat(lang, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
        longDateFormattersByLang.set(lang, formatter);
    }

    return formatter;
};

const getMediumDateFormatter = lang => {
    let formatter = mediumDateFormattersByLang.get(lang);
    if (!formatter) {
        formatter = new Intl.DateTimeFormat(lang, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        });
        mediumDateFormattersByLang.set(lang, formatter);
    }

    return formatter;
};

const getShortDateFormatter = lang => {
    let formatter = shortDateFormattersByLang.get(lang);
    if (!formatter) {
        formatter = new Intl.DateTimeFormat(lang, {
            month: 'numeric',
            day: 'numeric',
        });
        shortDateFormattersByLang.set(lang, formatter);
    }

    return formatter;
};

const formatLongDate = dateParam => {
    const date = createDateObject(dateParam);

    if (date === null) {
        return null;
    }

    const lang = getLanguage();
    if (lang && supportsDateFormat()) {
        const formatter = getLongDateFormatter(lang);
        return formatter.format(date);
    }

    return [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-');
};

const formatMediumDate = dateParam => {
    const date = createDateObject(dateParam);

    if (date === null) {
        return null;
    }

    const lang = getLanguage();
    if (lang && supportsDateFormat()) {
        const formatter = getMediumDateFormatter(lang);
        return formatter.format(date);
    }

    return [
        String(date.getFullYear()).substr(2),
        date.getMonth() + 1,
        date.getDate(),
    ].join('-');
};

const formatShortDate = dateParam => {
    const date = createDateObject(dateParam);

    if (date === null) {
        return null;
    }

    const lang = getLanguage();
    if (lang && supportsDateFormat()) {
        const formatter = getShortDateFormatter(lang);
        return formatter.format(date);
    }

    return [date.getMonth() + 1, date.getDate()].join('-');
};

const formatIsoDate = date =>
    [
        date.getFullYear(),
        String(date.getMonth() + 101).slice(-2),
        String(date.getDate() + 100).slice(-2),
    ].join('-');

const addDays = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);

    return newDate;
};

export {
    createDateObject,
    formatLongDate,
    formatMediumDate,
    formatShortDate,
    formatIsoDate,
    changeScope as changeScopeOnlyForTesting,
    addDays,
};
