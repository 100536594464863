import React from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { Alert, Form, Button } from 'reactstrap';
import InputCheckbox from 'components/FormElements/InputCheckboxControlled2';
import { PATHS } from 'config/paths';

import InputEmail from 'components/FormElements/InputEmailControlled';
import InputPassword from 'components/FormElements/InputPasswordControlled';
import InputText from 'components/FormElements/InputTextControlled';
import Select from 'components/FormElements/InputSelectControlled';
import Loader from 'Layout/Loader';
import { FLASH_MESSAGES } from 'consts';

import { validateField } from 'view/Validation/validateField';
import { validateFields } from 'view/Validation/validateFields';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import EmailFormatRule from 'view/Validation/ValidationRules/EmailFormatRule';
import ValidationErrors from 'view/Validation/ValidationErrors';
import withFlashMessage from '../../FlashMessage/withFlashMessage';
import { FlashMessageSuccess } from '../../FlashMessage/FlashMessage';
import setAuthCredentials from '../../Auth/setAuthCredentials';
import { withLocale } from '../../TranslatorContext';

export class CouponRegisterComponent extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        registerAndActivateCoupon: PropTypes.func.isRequired,
        addMessage: PropTypes.func.isRequired,
        userDataOnDemand: PropTypes.func.isRequired,
    };

    state = {
        sex: 'female',
        email: '',
        password: '',
        name: '',
        accessCode: '',
        agreementsTermsAndConditions: false,
        agreementsPersonalDataProcessing: false,
        errors: new ValidationErrors(),
        redirectToDietSettings: false,
        preload: false,
        registerError: false,
    };

    validationRules = {
        email: [
            new RequiredRule({ translator: this.props.t }),
            new EmailFormatRule({ translator: this.props.t }),
        ],
        password: [new RequiredRule({ translator: this.props.t })],
        agreementsTermsAndConditions: [
            new RequiredRule({ translator: this.props.t }),
        ],
        accessCode: [new RequiredRule({ translator: this.props.t })],
        name: [new RequiredRule({ translator: this.props.t })],
    };

    handleSubmit = async event => {
        event.preventDefault();

        const frontEndErrors = validateFields(
            this.validationRules,
            this.state,
            this.props.t
        );

        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                details: frontEndErrors,
            },
        }));

        if (frontEndErrors.length === 0) {
            this.setState({ preload: true });

            const request = {
                sex: this.state.sex,
                email: this.state.email,
                password: this.state.password,
                name: this.state.name,
                accessCode: this.state.accessCode,
                agreementsTermsAndConditions: this.state
                    .agreementsTermsAndConditions,
                agreementsPersonalDataProcessing: this.state
                    .agreementsPersonalDataProcessing,
            };

            try {
                const response = await this.props.registerAndActivateCoupon(
                    request
                );
                const { __typename, token } = response.data.auth.register;

                if (__typename === 'AuthRegisterSuccess') {
                    this.props.addMessage(
                        new FlashMessageSuccess(
                            this.props.t('coupon/activated-successfully'),
                            FLASH_MESSAGES.COUPON.SUCCESS
                        )
                    );
                    setAuthCredentials(token);

                    const response2 = await this.props.userDataOnDemand();

                    if (response2) {
                        this.setState({
                            redirectToDietSettings: true,
                            preload: false,
                        });
                    }
                } else {
                    this.setState({
                        preload: false,
                        errors: response.data.auth.register,
                        registerError: true,
                    });
                }
            } catch (e) {
                this.setState({ success: false, preload: false });
                throw new Error(
                    `Failed to register and activate coupon, got error: ${e}`
                );
            }
        }
    };

    handleInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        this.validateInput(event.target.name, event.target.value);
    };

    handleAcceptConditions = event => {
        this.setState({ [event.target.name]: event.target.checked });
        this.validateInput(event.target.name, event.target.checked);
    };

    validateInput = (field, value) => {
        if (this.validationRules[field]) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    details: validateField(
                        field,
                        value,
                        this.validationRules[field],
                        prevState
                    ),
                },
            }));
        }
    };

    render() {
        if (this.state.redirectToDietSettings) {
            return <Redirect to={PATHS.DIET_SETTINGS} />;
        }

        return (
            <div>
                {this.state.registerError ? (
                    <Alert color="danger" data-test="register-error">
                        {this.props.t('error/message/generic')}
                    </Alert>
                ) : (
                    ''
                )}
                <Form
                    onSubmit={this.handleSubmit}
                    data-test="coupon-registration-form"
                    noValidate
                >
                    <Select
                        label={this.props.t('diet-settings/sex')}
                        name="sex"
                        handleChange={this.handleInputChange}
                        value={this.state.sex}
                        errors={this.state.errors}
                    >
                        <option value="female">
                            {this.props.t('diet-settings/sex/female')}
                        </option>
                        <option value="male">
                            {this.props.t('diet-settings/sex/male')}
                        </option>
                    </Select>

                    <InputEmail
                        label={this.props.t('email')}
                        name="email"
                        autoComplete="username"
                        value={this.state.email}
                        errors={this.state.errors}
                        handleChange={this.handleInputChange}
                        validationRules={this.validationRules.email}
                        data-test="email-input"
                    />

                    <InputPassword
                        label={this.props.t('password-to-login')}
                        name="password"
                        autoComplete="new-password"
                        value={this.state.password}
                        errors={this.state.errors}
                        handleChange={this.handleInputChange}
                        validationRules={this.validationRules.password}
                        data-test="password-input"
                    />

                    <InputText
                        label={this.props.t('name')}
                        name="name"
                        value={this.state.name}
                        errors={this.state.errors}
                        handleChange={this.handleInputChange}
                        validationRules={this.validationRules.name}
                        data-test="name-input"
                    />

                    <InputText
                        label={this.props.t('coupon/access-code')}
                        name="accessCode"
                        value={this.state.accessCode}
                        errors={this.state.errors}
                        handleChange={this.handleInputChange}
                        validationRules={this.validationRules.accessCode}
                        data-test="access-code-input"
                    />

                    <InputCheckbox
                        label={[
                            this.props.t(
                                'agreements/terms-and-conditions/text'
                            ),
                            <a href="/terms-of-service">
                                {this.props.t(
                                    'agreements/terms-and-conditions/text2'
                                )}
                            </a>,
                            this.props.t(
                                'agreements/terms-and-conditions/text3'
                            ),
                            <a href="/privacy-policy">
                                {this.props.t(
                                    'agreements/terms-and-conditions/text4'
                                )}
                            </a>,
                            this.props.t(
                                'agreements/terms-and-conditions/text5'
                            ),
                        ]}
                        id="agreementsTermsAndConditions"
                        name="agreementsTermsAndConditions"
                        value={this.state.agreementsTermsAndConditions}
                        handleChange={this.handleAcceptConditions}
                        checked={this.state.agreementsTermsAndConditions}
                        errors={this.state.errors}
                        validationRules={
                            this.validationRules.agreementsTermsAndConditions
                        }
                        data-test="terms-and-condition-agreements-input"
                    />

                    <div className="text-center">
                        <Button color="primary mt-4" data-test="send-button">
                            {this.props.t('form/send')}
                        </Button>
                    </div>
                </Form>
                {this.state.preload ? <Loader /> : ''}
            </div>
        );
    }
}

export default withFlashMessage(withLocale(CouponRegisterComponent));
