import * as PATHS from 'Coupon/CouponPaths';
import CouponIndexPage from './CouponIndexPage';
import CouponActivatePage from './Activate/CouponActivatePage';

const routes = [
    {
        path: PATHS.PATH_COUPON_INDEX,
        component: CouponIndexPage,
        private: false,
        exact: true,
        title: 'route-title/default',
        exitPopup: false,
    },
    {
        path: PATHS.PATH_COUPON_ACTIVATE,
        component: CouponActivatePage,
        private: true,
        exact: true,
        title: 'route-title/default',
        exitPopup: false,
    },
];

export { routes };
