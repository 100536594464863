import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import RemoveUserAccountMutation from './RemoveUserAccountMutation';

const DeleteUserAccountConnectorApollo = ({ children }) => {
    const [removeUserAccount, { loading }] = useMutation(
        RemoveUserAccountMutation
    );
    const result = {
        removeUserAccount,
        loading,
    };

    return children(result);
};

DeleteUserAccountConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default DeleteUserAccountConnectorApollo;
