import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import ChangePasswordMutation from './ChangePasswordMutation';

const ChangePasswordConnector = ({ children }) => {
    const [mutate, { loading }] = useMutation(ChangePasswordMutation);

    const createChangePassword = async (currentPassword, newPassword) => {
        const response = await mutate({
            variables: {
                request: {
                    currentPassword,
                    newPassword,
                },
            },
        });

        return response;
    };

    return children({
        changePassword: createChangePassword,
        loading,
    });
};

ChangePasswordConnector.propTypes = {
    children: PropTypes.func.isRequired,
};

export default ChangePasswordConnector;
