import { PureComponent } from 'react';
import { func } from 'prop-types';

import { PATHS } from 'config/paths';

class TrackingStopComponent extends PureComponent {
    static propTypes = {
        stopTracking: func.isRequired,
    };

    componentDidMount() {
        this.props.stopTracking();
    }

    render() {
        window.location = PATHS.EXTERNAL.HOME;
        return null;
    }
}

export default TrackingStopComponent;
