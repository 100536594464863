import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '../../../../TranslatorContext';

const TokenExpiredError = ({ t }) => {
    return (
        <div className="text-center">
            <p className="black semi-bold">
                {t('remove-account/link-expired')}
            </p>
            <p>{t('remove-account/link-expired-info')}</p>
        </div>
    );
};

TokenExpiredError.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withLocale(TokenExpiredError);
