import { PROMO_CODE_SET } from '../PromoCodeActionType';
import { setLocalStorageItem } from '../PromoCodeLocalStorage';

const TTS_MS = 30 * 24 * 60 * 60 * 1000; // 30 days

// This function returns a function, so that it’s body is executed only
// when action is dispatched and not when it’s created
const promoCodeSetAction = newValue => dispatch => {
    const now = Date.now();
    const promoCode = {
        value: newValue,
        expire: now + TTS_MS,
    };

    setLocalStorageItem(promoCode);

    return dispatch({
        type: PROMO_CODE_SET,
        value: promoCode,
    });
};

export { promoCodeSetAction };
