import React, { Component } from 'react';
import PropTypes from 'prop-types';

import hydrationMask from 'assets/icons/hydration/hydration_mask.png';
import congratsIcon from 'assets/icons/hydration/hydration_success_icon.svg';
import { withLocale } from '../../../../TranslatorContext';

class HydrationDayProgress extends Component {
    static propTypes = {
        value: PropTypes.number.isRequired,
        goal: PropTypes.number.isRequired,
    };

    render() {
        const { value, goal } = this.props;
        let percentage = (value / goal) * 100 + (value / goal) * 5;

        if (percentage > 100) percentage = 110;

        return (
            <div className="text-center">
                <div className="drop">
                    <img src={hydrationMask} className="img-fluid" alt="" />
                    <div className="value">
                        <div
                            className="waves"
                            style={{ height: `${percentage}%` }}
                        />
                        <div
                            className="waves2"
                            style={{ height: `${percentage}%` }}
                        />
                        <div className="text">
                            {value >= goal ? (
                                <img src={congratsIcon} alt="" />
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { HydrationDayProgress };
export default withLocale(HydrationDayProgress);
