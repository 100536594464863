import gql from 'graphql-tag';

export default gql`
    query getAuthorizationUrls($returnUrl: OauthReturnUrlType, $state: String) {
        auth {
            authorizationUrl(returnUrl: $returnUrl, state: $state) {
                facebookUrl
                googleUrl
                appleUrl
            }
        }
    }
`;
