import gql from 'graphql-tag';

export default gql`
    query hydrationHistory($period: DatePeriodInputType!) {
        me {
            id
            systemOfMeasures
            hydrationSettings {
                key
                defaultDailyGoal {
                    value
                    unit
                }
            }
            hydrationHistory(period: $period) {
                key
                date
                goal {
                    value
                    unit
                }
                value {
                    value
                    unit
                }
            }
        }
    }
`;
