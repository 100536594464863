import React from 'react';
import PropTypes from 'prop-types';
import imageUrl from 'assets/images/login_fail_social.jpg';
import { withLocale } from '@dietlabs/components';
import { PATHS } from 'config/paths';
import ErrorGeneric from './ErrorGeneric';

const ErrorNoAccount = props => {
    const { t } = props;

    return (
        <ErrorGeneric
            topLevel
            t={t}
            message={t('error/message/no-account')}
            imageUrl={imageUrl}
            additionalMessage={t('error/additional-message/user-not-found')}
            actionLabel={t('button/get-access')}
            onActionClick={() => {
                window.location = `${PATHS.EXTERNAL.HOME}#buy`;
            }}
        />
    );
};

ErrorNoAccount.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withLocale(ErrorNoAccount);
