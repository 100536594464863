import React from 'react';
import PropTypes from 'prop-types';
import imageUrl from 'assets/images/error_network_img.svg';
import ErrorGeneric from './ErrorGeneric';

function defaultOnActionClick() {
    window.history.back();
}

const ErrorNetwork = props => {
    const { t, actionLabel, onActionClick, ...otherProps } = props;
    let message;
    if (window.navigator.onLine) {
        message = t('error/message/network');
    } else {
        message = t('error/message/offline');
    }

    return (
        <ErrorGeneric
            t={t}
            toplevel
            message={message}
            imageUrl={imageUrl}
            additionalMessage=""
            actionLabel={actionLabel || t('button/go-back')}
            onActionClick={onActionClick || defaultOnActionClick}
            isNetworkError
            {...otherProps}
        />
    );
};

ErrorNetwork.propTypes = {
    t: PropTypes.func.isRequired,
};

export { defaultOnActionClick };
export default ErrorNetwork;
