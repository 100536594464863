import callNativeMobileApp from './callNativeMobileApp';

export function callNativeAppRouteChanged(
    path,
    mobileAppTabIdentifier,
    mobileAppPageTitle
) {
    callNativeMobileApp({
        action: 'route_changed',
        payload: {
            path,
            mobileAppTabIdentifier,
            mobileAppPageTitle,
        },
    });
}
