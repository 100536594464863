import React from 'react';
import AuthSetInitialPasswordComponent from './AuthSetInitialPasswordComponent';
import SetInitialPasswordConnector from './Connector/Apollo/SetInitialPassword/SetInitialPasswordConnectorApollo';

class AuthSetInitialPasswordPage extends React.Component {
    render() {
        return (
            <SetInitialPasswordConnector>
                {({ ...setPassword }) => (
                    <AuthSetInitialPasswordComponent {...setPassword} />
                )}
            </SetInitialPasswordConnector>
        );
    }
}

export default AuthSetInitialPasswordPage;
