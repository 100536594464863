import React from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import SaleProduct from 'SaleProduct/SaleProduct';
import Price from 'Price/Price';
import SaleProductQuery from './SaleProductQuery';

class SaleProductConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        client: PropTypes.shape({
            query: PropTypes.func.isRequired,
        }).isRequired,
    };

    fetch = async tag => {
        const resultxxx = await this.props.client.query({
            query: SaleProductQuery,
            variables: {
                promoCode: '',
                tag,
            },
            fetchPolicy: 'network-only',
        });

        return resultxxx.data.sale.productVariants.reduce(
            (result, productVariant) => {
                result.push(
                    new SaleProduct(
                        productVariant.id,
                        productVariant.name,
                        productVariant.thumbnailUrl,
                        productVariant.saleProduct.productCardTitle,
                        productVariant.saleProduct.isBestseller,
                        productVariant.saleProduct.shortDescription,
                        productVariant.saleProduct.longDescription,
                        productVariant.saleProduct.lengthLabel,
                        new Price(
                            productVariant.saleProduct.price.regular.amount,
                            productVariant.saleProduct.price.regular.currency
                        ),
                        productVariant.saleProduct.price.promo
                            ? new Price(
                                  productVariant.saleProduct.price.promo.amount,
                                  productVariant.saleProduct.price.promo.currency
                              )
                            : null
                    )
                );
                return result;
            },
            []
        );
    };

    render() {
        const { children } = this.props;

        return children({
            loadSaleProduct: this.fetch,
        });
    }
}

export default withApollo(SaleProductConnectorApollo);
