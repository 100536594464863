import NewsletterUnsubscribePage from './NewsletterUnsubscribePage';

const PATH_NEWSLETTER_UNSUBSCRIBE = '/unsubscribe';

const routes = [
    {
        path: PATH_NEWSLETTER_UNSUBSCRIBE,
        component: NewsletterUnsubscribePage,
        private: false,
        exact: true,
        title: 'route-title/unsubscribe',
        exitPopup: false,
    },
];

export { routes };
