export default class EmailFormatRule {
    constructor(options = {}) {
        const t = options.translator;
        if (!t) {
            throw new Error('translator option is required in EmailFormatRule');
        }
        this.messages = {
            emailFormat: t('validation/email-format'),
            ...options.messages,
        };
    }

    isValid = value => {
        const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (value && value.match(mailformat)) {
            return true;
        }
        return false;
    };

    getErrorMsg() {
        return this.messages.emailFormat;
    }
}
