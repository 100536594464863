import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'Layout/Loader';
import { Redirect } from 'react-router';
import { PATHS } from 'config/paths';
import { Alert } from 'reactstrap';
import UserDataOnDemand from '../../../UserData/Connector/Apollo/Fetch/UserDataOnDemandConnectorApollo';
import { withLocale } from '../../../TranslatorContext';
import setAuthCredentials from '../../setAuthCredentials';
import RegisterViaGoogleConnectorApollo from './Connector/Apollo/RegisterViaGoogleConnectorApollo';
import AuthRegisterByGoogleTokenWrapperComponent from './AuthRegisterByGoogleTokenWrapperComponent';
import { RETURN_URL_REGISTER } from '../../Login/ByExternalProvider/LoginReturnUrls';

class AuthRegisterByGoogleTokenComponent extends React.Component {
    static propTypes = {
        token: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
        returnPath: PropTypes.string,
        returnUrl: PropTypes.string,
    };

    static defaultProps = {
        returnPath: PATHS.CART.INDEX,
        returnUrl: RETURN_URL_REGISTER,
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            errors: [],
        };
    }

    registerViaGoogle = async (registerViaGoogle, userDataOnDemand) => {
        const response = await registerViaGoogle;

        if (
            response.data.auth.registerViaGoogle.__typename === 'OauthException'
        ) {
            if (response.data.auth.registerViaGoogle.code === 400) {
                this.setState(prevState => ({
                    errors: [
                        ...prevState.errors,
                        this.props.t('auth/login/user-exists'),
                    ],
                }));
            }
        } else {
            const userToken = response.data.auth.registerViaGoogle.token;
            setAuthCredentials(userToken);
            await userDataOnDemand();
        }

        this.setState({ isLoading: false });
    };

    render() {
        return (
            <React.Fragment>
                {this.state.errors.map(msg => (
                    <Alert color="danger" key={msg}>
                        {msg}
                    </Alert>
                ))}
                <RegisterViaGoogleConnectorApollo>
                    {result => (
                        <UserDataOnDemand>
                            {result2 => (
                                <AuthRegisterByGoogleTokenWrapperComponent
                                    registerViaGoogle={() =>
                                        this.registerViaGoogle(
                                            result.registerViaGoogle(
                                                this.props.token,
                                                this.props.returnUrl
                                            ),
                                            result2.userDataOnDemand
                                        )
                                    }
                                />
                            )}
                        </UserDataOnDemand>
                    )}
                </RegisterViaGoogleConnectorApollo>
                {this.state.isLoading ? (
                    <div className="full-height">
                        <Loader />
                    </div>
                ) : null}

                {!this.state.isLoading && !this.state.errors.length ? (
                    <Redirect to={this.props.returnPath} />
                ) : null}
            </React.Fragment>
        );
    }
}

export default withLocale(AuthRegisterByGoogleTokenComponent);
