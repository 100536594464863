import { connect } from 'react-redux';
import PromoCodeComponent from 'components/PromoCode/PromoCodeComponent';
import { promoCodeIsValidAction } from './IsValid/PromoCodeIsValidAction';
import { promoCodeGetAction } from './Get/PromoCodeGetAction';
import { promoCodeSetAction } from './Set/PromoCodeSetAction';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    getPromoCode: () => dispatch(promoCodeGetAction()),
    setPromoCode: newValue => dispatch(promoCodeSetAction(newValue)),
    isValid: promoCode => promoCodeIsValidAction(promoCode),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodeComponent);
