import gql from 'graphql-tag';

const query = gql`
    query MeasurementsByDate($date: Date!) {
        me {
            id
            systemOfMeasures
            measurementByType: measurement {
                weight(request: { datePeriod: { start: $date, end: $date } }) {
                    value
                }
                waist(request: { datePeriod: { start: $date, end: $date } }) {
                    value
                }
                thigh(request: { datePeriod: { start: $date, end: $date } }) {
                    value
                }
                rightthigh(
                    request: { datePeriod: { start: $date, end: $date } }
                ) {
                    value
                }
                chest(request: { datePeriod: { start: $date, end: $date } }) {
                    value
                }
                arm(request: { datePeriod: { start: $date, end: $date } }) {
                    value
                }
                rightarm(
                    request: { datePeriod: { start: $date, end: $date } }
                ) {
                    value
                }
            }
        }
    }
`;

export default query;
