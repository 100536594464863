import gql from 'graphql-tag';

export default gql`
    mutation hydrationhydrationSettings(
        $settings: UserHydrationSettingsInputType!
    ) {
        me {
            hydrationSettings(settings: $settings) {
                __typename
                ... on UserHydrationSettings {
                    key
                    defaultDailyGoal {
                        value
                        unit
                    }
                    notifications {
                        key
                        enabled
                        hourInterval
                        fromHour
                        toHour
                        useSound
                    }
                }
                ... on ValidationException {
                    code
                    messages
                    details {
                        fieldName
                        messages
                    }
                }
            }
        }
    }
`;
