import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import up from 'assets/images/measurements/up.svg';
import down from 'assets/images/measurements/down.svg';
import noChange from 'assets/images/measurements/no-change.svg';
import { withLocale } from '../../../TranslatorContext';
// eslint-disable-next-line import/no-cycle
import { PATH_MEASUREMENT_ADD } from '../routes';

export class CurrentMeasurementsBox extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        measures: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.number,
                unit: PropTypes.string,
                change: PropTypes.number,
                date: PropTypes.string,
            })
        ).isRequired,
        label: PropTypes.string.isRequired,
    };

    renderChange = change => {
        if (change > 0) {
            return <img src={up} alt="" />;
        }

        if (change < 0) {
            return <img src={down} alt="" />;
        }

        return <img src={noChange} alt="" />;
    };

    render() {
        const { t, label, measures } = this.props;

        return (
            <Link className="box" to={PATH_MEASUREMENT_ADD}>
                <h4
                    dangerouslySetInnerHTML={{
                        __html: label,
                    }}
                />

                {measures.map(measure => {
                    if (measure && measure.value && measure.unit) {
                        return (
                            <span className="measure">
                                <span className="change-before mr-1">
                                    {this.renderChange(measure.change)}
                                </span>
                                <span className="prefix">
                                    {measure.prefix}{' '}
                                </span>
                                {`${measure.value} ${measure.unit}`}
                                <span className="change-after ml-1">
                                    {this.renderChange(measure.change)}
                                </span>
                            </span>
                        );
                    }

                    return (
                        <span className="measure">
                            <span className="prefix">{measure.prefix} </span>
                            {t('no-data')}
                        </span>
                    );
                })}
            </Link>
        );
    }
}
export default withLocale(CurrentMeasurementsBox);
