import React from 'react';
import AuthRemindPasswordComponent from './AuthRemindPasswordComponent';
import RemindPasswordConnector from './Connector/Apollo/RemindPassword/RemindPasswordConnectorApollo';

class AuthRemindPasswordPage extends React.Component {
    render() {
        const isLoggedIn = !!localStorage.getItem('token');

        return (
            <RemindPasswordConnector>
                {({ ...remindPassword }) => (
                    <AuthRemindPasswordComponent
                        {...remindPassword}
                        isLoggedIn={isLoggedIn}
                    />
                )}
            </RemindPasswordConnector>
        );
    }
}

export default AuthRemindPasswordPage;
