import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import MeasurementFetchByDateQuery from './MeasurementFetchByDateQuery';

const MeasurementFetchByDateConnectorApollo = ({ children, date }) => {
    const { loading, error, data } = useQuery(MeasurementFetchByDateQuery, {
        variables: { date },
    });

    const result = {
        loading,
        error,
        date,
    };

    const getMeasurementsFromApiResponse = measurementData => {
        const weight = measurementData.weight[0]
            ? measurementData.weight[0].value
            : null;
        const waist = measurementData.waist[0]
            ? measurementData.waist[0].value
            : null;
        const thigh = measurementData.thigh[0]
            ? measurementData.thigh[0].value
            : null;
        const chest = measurementData.chest[0]
            ? measurementData.chest[0].value
            : null;
        const arm = measurementData.arm[0]
            ? measurementData.arm[0].value
            : null;
        const rightarm = measurementData.rightarm[0]
            ? measurementData.rightarm[0].value
            : null;
        const rightthigh = measurementData.rightthigh[0]
            ? measurementData.rightthigh[0].value
            : null;

        return {
            weight,
            waist,
            thigh,
            chest,
            arm,
            rightarm,
            rightthigh,
        };
    };

    if (!loading) {
        result.measurements = getMeasurementsFromApiResponse(
            data.me.measurementByType
        );
        result.systemOfMeasures = data.me.systemOfMeasures;
    }

    return children(result);
};

MeasurementFetchByDateConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    date: PropTypes.string,
};

MeasurementFetchByDateConnectorApollo.defaultProps = {
    date: undefined,
};

export default MeasurementFetchByDateConnectorApollo;
