import gql from 'graphql-tag';

const query = gql`
    query UserNameEmail {
        me {
            name
            email
        }
    }
`;

export default query;
