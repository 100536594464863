import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router';
import Layout from 'Layout/Layout';
import Header from 'Layout/Header';
import { PATHS } from 'config/paths';
import Loader from 'Layout/Loader';
import { ApiDateType, SystemOfMeasuresType } from 'types/CommonTypes';
import PageContainerNarrow from 'Layout/Container/PageContainerNarrow';
import { callNativeAppRemoveLastRoute } from 'components/Helpers/callNativeAppRemoveLastRoute';
// eslint-disable-next-line import/no-cycle
import { PATH_MEASUREMENT_INDEX, PATH_MEASUREMENT_EDIT } from '../routes';
import MeasurementByDateFormPlaceholder from '../MeasurementByDate/MeasurementByDateFormPlaceholder';
import MeasurementByDateForm from '../MeasurementByDate/MeasurementByDateForm';
import { withLocale } from '../../../TranslatorContext';

export class MeasurementEditContainer extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        date: ApiDateType,
        loading: PropTypes.bool.isRequired,
        saving: PropTypes.bool.isRequired,
        deleting: PropTypes.bool.isRequired,
        saveMeasurements: PropTypes.func.isRequired,
        deleteMeasurements: PropTypes.func.isRequired,
        measurements: PropTypes.shape({
            weight: PropTypes.number,
            waist: PropTypes.number,
            thigh: PropTypes.number,
            chest: PropTypes.number,
            arm: PropTypes.number,
            rightthigh: PropTypes.number,
            rightarm: PropTypes.number,
        }),
        systemOfMeasures: SystemOfMeasuresType,
        history: PropTypes.shape({
            location: PropTypes.shape({
                state: PropTypes.shape({
                    from: PropTypes.shape({
                        pathname: PropTypes.string,
                    }),
                }),
            }).isRequired,
        }).isRequired,
    };

    static defaultProps = {
        date: undefined,
        measurements: undefined,
        systemOfMeasures: undefined,
    };

    state = {
        redirectTo: null,
    };

    handleSave = async formData => {
        const { goalLost, goalReached } = await this.props.saveMeasurements({
            ...formData,
            systemOfMeasures: this.props.systemOfMeasures,
        });

        if (goalLost) {
            this.setState({ redirectTo: PATHS.GOAL_LOST });
        } else if (goalReached) {
            this.setState({ redirectTo: PATHS.GOAL_REACHED });
        } else if (
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.from
        ) {
            this.setState({
                redirectTo: {
                    pathname: this.props.history.location.state.from.pathname,
                },
            });
        } else {
            this.setState({ redirectTo: PATH_MEASUREMENT_INDEX });
        }
    };

    handleDateChange = date =>
        this.setState({
            // hack!!
            // unmount existed component and mount it again with new date
            // do not have to handle it in component
            redirectTo: {
                pathname: PATHS.REDIRECT_HELPER,
                state: {
                    redirectTo: PATH_MEASUREMENT_EDIT.split(':')[0] + date,
                },
            },
        });

    handleDelete = async date => {
        await this.props.deleteMeasurements(date);
        callNativeAppRemoveLastRoute('measure');
        callNativeAppRemoveLastRoute('measure');
        this.setState({ redirectTo: PATH_MEASUREMENT_INDEX });
    };

    render() {
        const {
            t,
            loading,
            saving,
            deleting,
            systemOfMeasures,
            date,
            measurements,
        } = this.props;

        const { redirectTo } = this.state;

        if (redirectTo) {
            return <Redirect to={redirectTo} />;
        }

        return (
            <Layout page="measurement">
                <div className="bg-white">
                    <Header>{t('measurments/add-measurments-button')}</Header>
                    <PageContainerNarrow>
                        {loading ? (
                            <MeasurementByDateFormPlaceholder />
                        ) : (
                            <MeasurementByDateForm
                                systemOfMeasures={systemOfMeasures}
                                date={date}
                                weight={measurements.weight}
                                waist={measurements.waist}
                                thigh={measurements.thigh}
                                chest={measurements.chest}
                                arm={measurements.arm}
                                rightarm={measurements.rightarm}
                                rightthigh={measurements.rightthigh}
                                onDateChange={this.handleDateChange}
                                onSave={this.handleSave}
                                onDelete={this.handleDelete}
                            />
                        )}
                        {saving || deleting ? <Loader /> : ''}
                    </PageContainerNarrow>
                </div>
            </Layout>
        );
    }
}

export default withRouter(withLocale(MeasurementEditContainer));
