import React from 'react';
import PropTypes from 'prop-types';
import DateLineChart from 'Layout/Chart/DateLineChart';
import { FullMeasurementType } from 'types/CommonTypes';

class MeasurementHistoryChart extends React.Component {
    static propTypes = {
        history: PropTypes.arrayOf(FullMeasurementType.isRequired),
        trend: PropTypes.number,
        period: PropTypes.shape({
            start: PropTypes.string.isRequired,
            end: PropTypes.string.isRequired,
        }).isRequired,
    };

    static defaultProps = {
        history: undefined,
        trend: undefined,
    };

    render() {
        const { history, trend, period } = this.props;

        const reversedMeasurements = [...history].reverse();
        if (reversedMeasurements.length) {
            const theFirstMeasurements = reversedMeasurements[0];
            if (theFirstMeasurements.date !== period.start) {
                reversedMeasurements.unshift({
                    value: null,
                    date: period.start,
                });
            }

            const theLastMeasurement =
                reversedMeasurements[reversedMeasurements.length - 1];
            if (theLastMeasurement.date !== period.end) {
                reversedMeasurements.push({
                    ...theLastMeasurement,
                    date: period.end,
                });
            }
        }

        const series = [
            {
                name: 'values',
                data: reversedMeasurements.map(measurement => ({
                    x: new Date(measurement.date),
                    y: measurement.value,
                })),
                meta: {
                    showPoint: true,
                    showArea: true,
                },
            },
        ];

        if (trend != null) {
            series.push({
                name: 'goal',
                data: [
                    {
                        x: new Date(period.start),
                        y: trend,
                    },
                    {
                        x: new Date(period.end),
                        y: trend,
                    },
                ],
                meta: { showPoint: false },
            });
        }

        return <DateLineChart series={series} />;
    }
}

export default MeasurementHistoryChart;
