import React from 'react';
import PropTypes from 'prop-types';
import { ApiDateType } from 'types/CommonTypes';
import MeasurementDeleteByDateConnectorApollo from '../Connector/Apollo/MeasurementByDate/MeasurementDeleteByDateConnectorApollo';
import MeasurementSaveByDateConnectorApollo from '../Connector/Apollo/MeasurementByDate/MeasurementSaveByDateConnectorApollo';
import MeasurementFetchByDateConnectorApollo from '../Connector/Apollo/MeasurementByDate/MeasurementFetchByDateConnectorApollo';
// eslint-disable-next-line import/no-cycle
import MeasurementEditContainer from './MeasurementEditContainer';

const MeasurementEditPage = props => (
    <MeasurementFetchByDateConnectorApollo date={props.match.params.date}>
        {fetchData => (
            <MeasurementSaveByDateConnectorApollo
                date={props.match.params.date}
                {...fetchData}
            >
                {saveData => (
                    <MeasurementDeleteByDateConnectorApollo {...saveData}>
                        {deleteData => (
                            <MeasurementEditContainer
                                {...fetchData}
                                {...saveData}
                                {...deleteData}
                            />
                        )}
                    </MeasurementDeleteByDateConnectorApollo>
                )}
            </MeasurementSaveByDateConnectorApollo>
        )}
    </MeasurementFetchByDateConnectorApollo>
);

MeasurementEditPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            date: ApiDateType,
        }).isRequired,
    }).isRequired,
};

export default MeasurementEditPage;
