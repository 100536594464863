import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '../../../../../Apollo/Mutation';
import LoginViaGoogleMutation from './LoginViaGoogleMutation';

class LoginViaGoogleConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    loginViaGoogle = apiLoginViaGoogle => async token => {
        const response = await apiLoginViaGoogle({
            variables: {
                token,
            },
        });

        return response;
    };

    render() {
        const { children } = this.props;
        return (
            <Mutation mutation={LoginViaGoogleMutation}>
                {(loginViaGoogle, result) => {
                    const loginViaGoogleResult = {
                        loginViaGoogle: this.loginViaGoogle(loginViaGoogle),
                        loading: result.loading,
                    };

                    return children(loginViaGoogleResult);
                }}
            </Mutation>
        );
    }
}

export default LoginViaGoogleConnectorApollo;
