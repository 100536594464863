import React, { Component } from 'react';
import { Card, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactComponent as Basket } from 'assets/icons/basket.svg';
import { withLocale } from '@dietlabs/components';
import SaleProduct from './SaleProduct';
import { addToCartLink } from './CreateAddToCartLink';

class SaleProducts extends Component {
    static propTypes = {
        saleProduct: PropTypes.instanceOf(SaleProduct).isRequired,
        source: PropTypes.string.isRequired,
        showShortDescription: PropTypes.bool.isRequired,
        showThumbnail: PropTypes.bool.isRequired,
        promoCode: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
    };

    render() {
        const { saleProduct, source, promoCode } = this.props;

        return (
            <Card className="product">
                {saleProduct.isBestseller ? (
                    <div className="product-bestseller">bestseller</div>
                ) : (
                    ''
                )}
                <div className="card-header">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: this.props.showShortDescription
                                ? saleProduct.shortDescription
                                : saleProduct.name,
                        }}
                        className="product-name"
                    />
                </div>
                <div className="card-body">
                    {this.props.showThumbnail ? (
                        <img
                            src={saleProduct.thumbnailUrl}
                            className="img-fluid mb-3"
                            alt={saleProduct.name}
                        />
                    ) : (
                        ''
                    )}

                    {saleProduct.hasActivePromo ? (
                        <span className="product-save">
                            <React.Fragment>
                                {this.props.t('product/you-save')}{' '}
                                <strong>
                                    {saleProduct.diffPrice.toString()}
                                </strong>
                            </React.Fragment>
                        </span>
                    ) : (
                        ''
                    )}

                    <span className="product-price">
                        {`${saleProduct.price.toString()}`}
                    </span>

                    {saleProduct.hasActivePromo ? (
                        <span className="product-old-price">
                            {`${saleProduct.regularPrice.toString()}`}
                        </span>
                    ) : (
                        ''
                    )}

                    <span
                        data-test="product-description"
                        className="product-description"
                    >
                        {saleProduct.lengthLabel}
                    </span>
                </div>
                <div className="card-footer">
                    <Button
                        color="primary"
                        tag={Link}
                        to={addToCartLink(saleProduct.id, promoCode, source)}
                    >
                        <span className="SVGInline pr-1">
                            <Basket />
                        </span>{' '}
                        {this.props.t('product/buy-now')}
                    </Button>
                </div>
            </Card>
        );
    }
}

export default withLocale(SaleProducts);
