import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '../../../TranslatorContext';
import GetAuthorizationUrlsApolloConnector from './Connector/Apollo/GetAuthorizationUrlsApolloConnector';
import ExternalProvider from './ExternalProvider';

class ExternalProviderContainer extends React.Component {
    static propTypes = {
        returnUrl: PropTypes.string.isRequired,
        state: PropTypes.string,
        displayFacebookButton: PropTypes.bool,
        displayGoogleButton: PropTypes.bool,
        displayAppleButton: PropTypes.bool,
        checkAcceptContitions: PropTypes.func,
        roundedBtn: PropTypes.bool,
        fullWidth: PropTypes.bool,
        id: PropTypes.string,
    };

    static defaultProps = {
        displayFacebookButton: true,
        displayGoogleButton: true,
        displayAppleButton: true,
        state: 'login',
        checkAcceptContitions: undefined,
        roundedBtn: false,
        fullWidth: false,
        id: undefined,
    };

    render() {
        return (
            <GetAuthorizationUrlsApolloConnector
                returnUrl={this.props.returnUrl}
                state={this.props.state}
            >
                {data => (
                    <ExternalProvider
                        loading={data.loading}
                        googleUrl={data.googleUrl}
                        facebookUrl={data.facebookUrl}
                        appleUrl={data.appleUrl}
                        displayFacebookButton={this.props.displayFacebookButton}
                        displayGoogleButton={this.props.displayGoogleButton}
                        displayAppleButton={this.props.displayAppleButton}
                        state={this.props.state}
                        checkAcceptContitions={this.props.checkAcceptContitions}
                        roundedBtn={this.props.roundedBtn}
                        fullWidth={this.props.fullWidth}
                        id={this.props.id}
                    />
                )}
            </GetAuthorizationUrlsApolloConnector>
        );
    }
}

export default withLocale(ExternalProviderContainer);
