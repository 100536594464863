import { PromoCodeQSIdentifier } from './PromoCodeIdentifiers';

export const addToCartLink = (productId, promoCode, source) => {
    let link = `/cart/add/${productId}`;

    if (source) {
        link += `/${source}`;
    }

    if (promoCode) {
        link += `?${PromoCodeQSIdentifier}=${promoCode}`;
    }

    return link;
};
