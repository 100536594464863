import { SALE_SOURCES_NAMESPACE as NAMESPACE } from '../SaleSourcesNamespace';
import {
    SALE_SOURCES_SET,
    SALE_SOURCES_REMOVE_STALE,
} from '../SaleSourcesActionType';
import { FIRST, LAST } from '../SaleSourcesNames';
import { setLocalStorageItem } from '../SaleSourcesLocalStorage';

// Copied to https://github.com/DietLabs/byAnn-landings/blob/master/src/SaleProduct/SaleProductContext.js
const TTS_MS = 24 * 60 * 60 * 1000; // 24h

const saleSourcesSetAction = (first, last) => ({
    type: SALE_SOURCES_SET,
    [FIRST]: first,
    [LAST]: last,
});

// This function returns a function, so that it’s body is executed only
// when action is dispatched and not when it’s created
const saleSourcesSet = source => (dispatch, getState) => {
    dispatch({ type: SALE_SOURCES_REMOVE_STALE });

    const now = Date.now();

    let { [FIRST]: firstSource = null, [LAST]: lastSource = null } =
        getState()[NAMESPACE] || {};

    if (firstSource === null) {
        firstSource = {
            value: source,
            expire: now + TTS_MS,
        };
    }

    lastSource = {
        value: source,
        expire: now + TTS_MS,
    };

    setLocalStorageItem(FIRST, firstSource);
    setLocalStorageItem(LAST, lastSource);

    dispatch(saleSourcesSetAction(firstSource, lastSource));
};

export { saleSourcesSet };
