import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button, Form, Alert } from 'reactstrap';
import Loader from 'Layout/Loader';
import { Redirect } from 'react-router';
import { PATHS } from 'config/paths';
import withMobileAppMode from 'Layout/withMobileAppMode';
import { Link } from 'react-router-dom';
import InputEmail from 'components/FormElements/InputEmailControlled';
import Layout from 'Layout/Layout';

import { validateField } from 'view/Validation/validateField';
import { validateFields } from 'view/Validation/validateFields';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import EmailFormatRule from 'view/Validation/ValidationRules/EmailFormatRule';
import ValidationErrors from 'view/Validation/ValidationErrors';
import { withLocale } from '../../TranslatorContext';

export class AuthRemindPasswordComponent extends React.Component {
    static propTypes = {
        remindPassword: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
        isLoggedIn: PropTypes.bool.isRequired,
    };

    state = {
        email: '',
        preload: false,
        success: false,
        errors: new ValidationErrors(),
    };

    validationRules = {
        email: [
            new RequiredRule({ translator: this.props.t }),
            new EmailFormatRule({ translator: this.props.t }),
        ],
    };

    handleSubmit = async event => {
        event.preventDefault();

        const frontEndErrors = validateFields(
            this.validationRules,
            this.state,
            this.props.t
        );

        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                details: frontEndErrors,
            },
        }));

        if (frontEndErrors.length === 0) {
            this.setState({ preload: true });

            try {
                const response = await this.props.remindPassword({
                    email: this.state.email,
                });
                const { code, details } = response.data.auth.remindPassword;

                if (code === 200) {
                    this.setState({
                        email: '',
                        errors: new ValidationErrors(),
                        success: true,
                        preload: false,
                    });
                } else {
                    for (let i = 0; i < details.length; i += 1) {
                        const error = details[i];
                        if (error.fieldName in this.validationRules) {
                            this.setState({
                                errors: response.data.auth.remindPassword,
                            });
                            break;
                        }
                    }
                    this.setState({
                        success: false,
                        preload: false,
                    });
                }
            } catch (e) {
                this.setState({
                    success: false,
                    preload: false,
                });
                throw new Error(`Failed to remind password, got error: ${e}`);
            }
        }
    };

    handleInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        this.validateInput(event.target.name, event.target.value);
    };

    validateInput = (field, value) => {
        if (this.validationRules[field]) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    details: validateField(
                        field,
                        value,
                        this.validationRules[field],
                        prevState
                    ),
                },
            }));
        }
    };

    render() {
        if (this.props.isLoggedIn) {
            return <Redirect to={PATHS.DEFAULT_PAGE_AFTER_LOGGED_IN} />;
        }
        if (this.state.sendSuccess) {
            return <Redirect to={PATHS.AUTH.LOGIN} />;
        }

        return (
            <Layout page="login">
                <section className="bg-white">
                    <Container className="text-center">
                        <h1>{this.props.t('auth/remind-password/title')}</h1>
                        <p className="small">
                            {this.props.t('auth/remind-password/description')}
                        </p>
                        <Row>
                            <Col xs="12" sm={{ size: 6, offset: 3 }}>
                                {this.state.success ? (
                                    <Alert color="success">
                                        {this.props.t(
                                            'auth/remind-password/success-message'
                                        )}
                                    </Alert>
                                ) : (
                                    ''
                                )}

                                <Form
                                    onSubmit={this.handleSubmit}
                                    data-test="remind-password-form"
                                >
                                    <InputEmail
                                        label={this.props.t('email')}
                                        name="email"
                                        value={this.state.email}
                                        errors={this.state.errors}
                                        handleChange={this.handleInputChange}
                                        validationRules={
                                            this.validationRules.email
                                        }
                                        data-test="email-input"
                                    />
                                    <Button
                                        color="primary"
                                        data-test="remind-password-button"
                                    >
                                        {this.props.t('form/send')}
                                    </Button>
                                </Form>
                                <br />
                                <p className="small">
                                    <Link
                                        to={PATHS.AUTH.LOGIN}
                                        data-test="login-link"
                                    >
                                        {this.props.t(
                                            'auth/login/back-link-text'
                                        )}
                                    </Link>
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {this.state.preload ? <Loader /> : ''}
            </Layout>
        );
    }
}

export default withMobileAppMode(withLocale(AuthRemindPasswordComponent));
