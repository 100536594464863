import React from 'react';
import CouponRegisterComponent from './CouponRegisterComponent';
import RegisterConnector from './Connector/Apollo/Register/RegisterConnectorApollo';
import UserDataOnDemand from '../../UserData/Connector/Apollo/Fetch/UserDataOnDemandConnectorApollo';

class CouponRegisterContainer extends React.Component {
    render() {
        return (
            <RegisterConnector>
                {({ ...registerAndActivateCoupon }) => (
                    <UserDataOnDemand>
                        {({ ...userDataOnDemand }) => (
                            <CouponRegisterComponent
                                {...registerAndActivateCoupon}
                                {...userDataOnDemand}
                            />
                        )}
                    </UserDataOnDemand>
                )}
            </RegisterConnector>
        );
    }
}

export default CouponRegisterContainer;
