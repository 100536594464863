import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import SendEmailMutation from './SendEmailMutation';

const SendEmailConnectorApollo = ({ children }) => {
    const [sendEmail, { data, loading, error }] = useMutation(
        SendEmailMutation
    );

    const createSendEmail = async request => {
        const variables = {
            request,
        };

        await sendEmail({ variables });
    };

    return children({
        contactEmail: {
            sendEmail: createSendEmail,
            response: data,
            loading,
            error,
        },
    });
};

SendEmailConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default SendEmailConnectorApollo;
