import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '../../../../../Apollo/Mutation';
import registerViaAppleMutation from './RegisterViaAppleMutation';

class RegisterViaAppleConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    registerViaApple = apiRegisterViaApple => async token => {
        const response = await apiRegisterViaApple({
            variables: {
                token,
            },
        });

        return response;
    };

    render() {
        const { children } = this.props;
        return (
            <Mutation mutation={registerViaAppleMutation}>
                {(registerViaApple, result) => {
                    const registerViaAppleResult = {
                        registerViaApple: this.registerViaApple(
                            registerViaApple
                        ),
                        loading: result.loading,
                    };

                    return children(registerViaAppleResult);
                }}
            </Mutation>
        );
    }
}

export default RegisterViaAppleConnectorApollo;
