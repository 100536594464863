import React from 'react';

import { Consumer as ErrorBoundaryConsumer } from './ErrorBoundaryContext';

const withOnCriticalError = ChildComponent => props => (
    <ErrorBoundaryConsumer>
        {handleError => (
            <ChildComponent {...props} onCriticalError={handleError} />
        )}
    </ErrorBoundaryConsumer>
);

export default withOnCriticalError;
