import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { withLocale } from '../../../TranslatorContext';

class SettingsProductsPlaceholder extends PureComponent {
    static propTypes = {
        t: PropTypes.func.isRequired,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: null,
    };

    render() {
        const { t, className } = this.props;
        return (
            <Container className={className}>
                <Row>
                    <Col md={{ size: 8, offset: 2 }}>
                        <h2 className="heading-3">
                            {t('settings/products/heading')}
                        </h2>
                        <h3 className="heading-4">
                            {t('settings/products/video-workouts/heading')}
                        </h3>
                        <p className="placeholder">&nbsp;</p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export { SettingsProductsPlaceholder };
export default withLocale(SettingsProductsPlaceholder);
