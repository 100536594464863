import AchievementsPage from './AchievemetsPage';

const PATH_ACHIEVEMENTS = '/achievements';
const routes = [
    {
        path: PATH_ACHIEVEMENTS,
        component: AchievementsPage,
        private: true,
        exact: true,
        title: 'route-title/achievements',
    },
];

export { PATH_ACHIEVEMENTS, routes };
