import gql from 'graphql-tag';

const query = gql`
    query Content($identifier: String!) {
        content(identifier: $identifier) {
            identifier
            title
            content {
                markdown
                html
            }
        }
    }
`;

export default query;
