import { formatIsoDate, createDateObject } from 'utils/dateFormatter';
import { useQuery } from '@apollo/client';
import { UNIT_TYPES } from 'consts';
import { getUnitForType } from 'utils/unit';
import HistoryQuery from './HydrationHistoryQuery';

const HydrationHistoryConnectorApollo = ({ dateFrom, dateTo, children }) => {
    const variables = {
        period: {
            start: formatIsoDate(dateFrom),
            end: formatIsoDate(dateTo),
        },
    };
    const { loading, error, data } = useQuery(HistoryQuery, {
        variables,
    });

    let unit;
    let defaultDailyGoal;
    let days = [];
    if (!loading) {
        unit = getUnitForType(UNIT_TYPES.VOLUME, data.me.systemOfMeasures);

        defaultDailyGoal = data.me.hydrationSettings.defaultDailyGoal.value;

        const rawDays = data.me.hydrationHistory;
        if (rawDays.length) {
            days = rawDays.map(day => ({
                date: createDateObject(day.date),
                goalValue: day.goal.value,
                valueValue: day.value.value,
            }));
        }
    }
    return children({
        loading2: loading,
        unit,
        error,
        defaultDailyGoal,
        days,
    });
};

export default HydrationHistoryConnectorApollo;
