import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Odometer from 'react-odometerjs';
import { withLocale } from '../../../../TranslatorContext';

class HydrationDayGoalHeader extends Component {
    static propTypes = {
        value: PropTypes.number.isRequired,
        unit: PropTypes.string.isRequired,
        goal: PropTypes.number.isRequired,
    };

    state = {
        odometer: 0,
    };

    componentDidMount() {
        this.setState({ odometer: this.props.value });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                odometer: this.props.value,
            });
        }
    }

    render() {
        const { goal, unit } = this.props;

        return (
            <p className="goal text-center">
                <strong>
                    <Odometer value={this.state.odometer} format="d" /> {unit}
                </strong>{' '}
                / {`${goal} ${unit}`}
            </p>
        );
    }
}

export default withLocale(HydrationDayGoalHeader);
