import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import up from '../assets/images/measurements/up.svg';
import down from '../assets/images/measurements/down.svg';
import noChange from '../assets/images/measurements/no-change.svg';

export class Change extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        label: PropTypes.string,
        difference: PropTypes.number,
    };

    static defaultProps = {
        label: undefined,
        difference: undefined,
    };

    renderNoData() {
        const { t } = this.props;
        return <span data-test="no-data">{t('no-data')}</span>;
    }

    renderDescent() {
        return (
            <span className="change-down" data-test="desc">
                {`${this.getLabel()} `}
                <img src={down} alt="" />
            </span>
        );
    }

    renderAscent() {
        return (
            <span className="change-up" data-test="asc">
                +{`${this.getLabel()} `}
                <img src={up} alt="" />
            </span>
        );
    }

    renderNoChange() {
        const { t } = this.props;
        return (
            <span className="no-change" data-test="no-change">
                {t('')}
                <img src={noChange} alt="" />
            </span>
        );
    }

    getLabel() {
        const { label, difference } = this.props;

        return typeof label === 'undefined' ? difference : label;
    }

    render() {
        const { difference } = this.props;
        if (difference == null) {
            return this.renderNoData();
        }
        if (difference < 0) {
            return this.renderDescent();
        }
        if (difference > 0) {
            return this.renderAscent();
        }
        return this.renderNoChange();
    }
}

export default withLocale(Change);
