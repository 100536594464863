const COOKIE_NAME = 'DNT';
const COOKIE_DEFAULT_VALUE = '0';

function setTrackingInStore(track) {
    if (!track) {
        document.cookie = `${COOKIE_NAME}=1; path=/; max-age=${60 *
            60 *
            24 *
            365 *
            100}`;
    } else {
        document.cookie = `${COOKIE_NAME}=0; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
}

function getTrackingFromStore() {
    const match = document.cookie.match(
        new RegExp(`(?:^|;)\\s*${COOKIE_NAME}\\s*=\\s*(1|0)\\s*(?:;|$)`)
    );
    const DNT = match ? match[1] : COOKIE_DEFAULT_VALUE;

    return DNT !== '1';
}

export { setTrackingInStore, getTrackingFromStore };
