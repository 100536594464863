import React from 'react';
import { func } from 'prop-types';

const propTypes = {
    setPromoCode: func.isRequired,
};

const getFromQueryString = () => {
    try {
        const search = window.location.search;
        const searchParam = search.match(/(?:^|\?|&)pc=([^&]+)/)[1];
        return decodeURIComponent(searchParam.replace(/\+/g, ' '));
    } catch (error) {
        return null;
    }
};

class PromoCodeHandlerComponent extends React.Component {
    componentDidMount() {
        const promoCode = getFromQueryString();
        if (promoCode != null) {
            this.props.setPromoCode(promoCode);
        }
    }

    render() {
        return '';
    }
}

PromoCodeHandlerComponent.propTypes = propTypes;

export default PromoCodeHandlerComponent;
