import React, { Component } from 'react';
import Chartist from 'chartist';
import 'chartist/dist/chartist.min.css';
import PropTypes from 'prop-types';

import deepMerge from 'utils/deepMerge';
import deepClone from 'utils/deepClone';
import getLanguage from 'utils/getLanguage';
import { DateType } from 'types/CommonTypes';

let formatterCache;

function getFormatter() {
    if (!global.Intl || !global.Intl.DateTimeFormat) {
        return false;
    }

    if (formatterCache === undefined) {
        const lang = getLanguage();
        formatterCache = new global.Intl.DateTimeFormat(lang, {
            month: 'numeric',
            day: 'numeric',
        });
    }

    return formatterCache;
}

class DateLineChart extends Component {
    chartistDefaultOptions = {
        axisX: {
            type: Chartist.FixedScaleAxis,
            divisor: 5,
            labelInterpolationFnc: label => this.formatXLabel(label),
        },
        axisY: {
            onlyInteger: true,
            divisor: 4,
            offset: 20,
            labelInterpolationFnc: label => this.formatYLabel(label),
        },
    };

    shouldComponentUpdate(nextProps) {
        return JSON.stringify(nextProps) !== JSON.stringify(this.props);
    }

    componentDidMount() {
        this.updateChart();
    }

    componentDidUpdate() {
        this.updateChart();
    }

    updateChart() {
        const seriesData = this.props.series.map(serie => ({
            name: serie.name,
            data: serie.data.sort((a, b) => a.x - b.x),
        }));
        const metaBySeries = this.props.series.reduce(
            (prev, serie) => ({
                ...prev,
                [serie.name]: deepClone(serie.meta || {}),
            }),
            {}
        );

        const barSeries = [];
        Object.entries(metaBySeries).forEach(([name, serie]) => {
            if (serie.showBar) {
                barSeries.push(name);
                // eslint-disable-next-line no-param-reassign
                serie.showPoint = true;
            }
        });

        const chart = new Chartist.Line(
            '.ct-chart',
            {
                series: seriesData,
            },
            deepMerge(
                deepMerge(this.chartistDefaultOptions, this.props.options),

                { series: metaBySeries }
            )
        );

        chart.on('draw', data => {
            if (data.type === 'point' && barSeries.includes(data.series.name)) {
                data.group.append(
                    new Chartist.Svg(
                        'rect',
                        {
                            x: data.x,
                            y: data.y,
                            height: Math.max(
                                data.axisY.chartRect.y1 - data.y,
                                1
                            ),
                        },
                        'ct-bar'
                    )
                );

                const series = this.props.series.find(
                    s => s.name === data.series.name
                );
                if (!series.meta.showPoint) {
                    // eslint-disable-next-line no-underscore-dangle
                    const pointElement = data.element._node;
                    pointElement.style.opacity = 0;
                }
            }
        });

        return chart;
    }

    formatXLabel = timestamp => {
        const date = new Date(timestamp);
        const formatter = getFormatter();
        if (formatter) {
            return formatter.format(date);
        }

        // Fall back to day+month.
        // Normally that would be confusing, but we are using these values
        // on a graph axis, where it’s easier to figure out which one is
        // month and which is the day by looking at neighbouring values.
        // And browser support is quite good:
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat#Browser_compatibility
        return [date.getDate(), date.getMonth() + 1].join('-');
    };

    formatYLabel = value => {
        const { unit } = this.props.options.axisY || {};
        if (!unit) {
            return value;
        }
        return `${value}${unit}`;
    };

    render() {
        return (
            <React.Fragment>
                <svg
                    aria-hidden="true"
                    focusable="false"
                    style={{ width: 0, height: 0, position: 'absolute' }}
                >
                    <linearGradient id="chart-gradient" x2="0" y2="1">
                        <stop offset="0%" stopColor="var(--color-stop-1)" />
                        <stop offset="100%" stopColor="var(--color-stop-2)" />
                    </linearGradient>
                </svg>
                <div className="ct-chart ct-octave chart" />
            </React.Fragment>
        );
    }
}

DateLineChart.propTypes = {
    series: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            data: PropTypes.arrayOf(
                PropTypes.shape({
                    x: DateType,
                    y: PropTypes.number,
                })
            ).isRequired,
        })
    ).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    options: PropTypes.object,
};

DateLineChart.defaultProps = {
    options: {},
};

export default DateLineChart;
