import { useQuery, useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import deepClone from 'utils/deepClone';
import { CATEGORIES } from '../../consts';

import ArticleListFetchQuery from './ArticleListFetchQuery';

const ArticleFetchListConnectorApollo = ({
    children,
    category,
    articlesPerPage,
}) => {
    const client = useApolloClient();
    const { loading, error, data } = useQuery(ArticleListFetchQuery, {
        variables: {
            categoryIdentifier: category,
            pageNumber: 1,
            articlesPerPage,
        },
        fetchPolicy: 'cache-first',
        onCompleted: () => {
            CATEGORIES.forEach(CATEGORY => {
                client.query({
                    query: ArticleListFetchQuery,
                    variables: {
                        categoryIdentifier: CATEGORY,
                        pageNumber: 1,
                        articlesPerPage,
                    },
                });
            });
        },
    });

    const result = {
        loading,
        error,
    };

    if (!loading) {
        result.articleList = deepClone(data.article);
        result.articlePagination = deepClone(data.articlePagination);
    }

    return children(result);
};

ArticleFetchListConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    category: PropTypes.string,
};

ArticleFetchListConnectorApollo.defaultProps = {
    category: null,
};

export default ArticleFetchListConnectorApollo;
