import React from 'react';
import PropTypes from 'prop-types';
import { Container, Alert } from 'reactstrap';
import withFlashMessage from './withFlashMessage';

class FlashMessageComponent extends React.Component {
    static getColorBaseOnMessage(message) {
        let result = null;

        switch (message.type) {
            case 'error':
                result = 'danger';
                break;
            default:
                result = message.type;
        }

        return result;
    }

    render() {
        if (this.props.messages.length > 0) {
            return (
                <div className="flashMessageContainer">
                    <Container>
                        {this.props.messages.map(message => (
                            <Alert
                                key={message}
                                color={this.constructor.getColorBaseOnMessage(
                                    message
                                )}
                                toggle={() => {
                                    this.props.removeMessage(message.id);
                                }}
                            >
                                {message.content}
                            </Alert>
                        ))}
                    </Container>
                </div>
            );
        }
        return <div />;
    }
}

FlashMessageComponent.propTypes = {
    messages: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf(['info', 'success', 'warning', 'error'])
                .isRequired,
            content: PropTypes.oneOfType([
                PropTypes.string.isRequired,
                PropTypes.object.isRequired,
            ]),
            id: PropTypes.string,
        })
    ).isRequired,
    removeMessage: PropTypes.func.isRequired,
};

export default withFlashMessage(FlashMessageComponent);
