import GraphQLFetch from '@dietlabs/components/src/GraphQL/GraphQLFetch';
import Query from './PromoCodeIsValidQuery';

export const promoCodeIsValidAction = promoCode =>
    GraphQLFetch.runQuery(Query, { promoCode }).then(res => {
        let result = null;
        try {
            result = res.data.sale.isPromoCodeValid;
        } catch (error) {
            result = false;
        }
        return result;
    });
