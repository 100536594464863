import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { formatIsoDate } from 'utils/dateFormatter';
import query from './HydrationDayQuery';

const HydrationDayConnectorApollo = ({ children, date }) => {
    const variables = { date: formatIsoDate(date) };
    const { data, loading, error } = useQuery(query, {
        variables,
    });

    const args = {
        loading,
        error,
        queryResult: data,
    };

    if (!loading) {
        const goal = data.me.hydrationDay.goal;
        const hydrationSum = data.me.hydrationDay.value.value;
        const portions = data.me.hydrationDay.portions;

        args.data = {
            goal,
            portions,
            hydrationSum,
        };
        return children(args);
    }

    return null;
};

HydrationDayConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
};

export default HydrationDayConnectorApollo;
