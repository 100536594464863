import { useLazyQuery } from '@apollo/client';
import ArticleFetchQuery from '../Fetch/ArticleFetchQuery';

const ArticleFetchOnDemandConnectorApollo = ({ children }) => {
    const [loadArticle] = useLazyQuery(ArticleFetchQuery);

    return children(loadArticle);
};

export default ArticleFetchOnDemandConnectorApollo;
